<template>
  <div>
    <transition name="fade-list">
      <div class="modal_user_info_background" v-if="modal_user_info && this.userInfoForModal"
           @click="modal_user_info = false; this.setUserInfoForModal(null)">
        <div class="modal_user_info">
          <div class="modal_user_info_header">
            <svg @click="this.$emit('changeStatusModalEntry', false)" viewBox="0 0 32 32"
                 xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                 focusable="false"
                 style="display: block; fill: none; height: 16px; width: 16px; stroke: currentcolor; stroke-width: 3; overflow: visible;">
              <path d="m6 6 20 20"></path>
              <path d="m26 6-20 20"></path>
            </svg>
          </div>
          <div class="modal_user_info_content">
            <div class="modal_user_info_content_main">
              <div>
                <div class="modal_user_info_content_main_img">
                  <picture>
                    <source
                      :srcset="`${this.url_backend_img}${userInfoForModal.id}/${userInfoForModal.user_photo[0].fname}-preview.webp`"
                      type="image/webp">
                    <img
                      :src="`${this.url_backend_img}${userInfoForModal.id}/${userInfoForModal.user_photo[0].fname}-preview.png`"
                      alt="">
                  </picture>
                </div>
                <span class="modal_user_info_content_main_title">{{ userInfoForModal.firstname }}</span>
              </div>
              <div class="modal_user_info_content_features">
                <div class="modal_user_info_content_features_elem" v-if="userInfoForModal.reviews">
                  <span>{{ userInfoForModal.reviews }}</span>
                  <span>Отзывы</span>
                </div>
                <div class="modal_user_info_content_features_elem" v-if="userInfoForModal.rating">
                  <span>{{ userInfoForModal.rating }}</span>
                  <span>Рейтинг</span>
                </div>
                <div class="modal_user_info_content_features_elem" v-if="userInfoForModal.exp">
                  <span>{{ userInfoForModal.exp }}</span>
                  <span>Лет опыта</span>
                </div>
              </div>
            </div>
            <div class="modal_user_info_content_additional">
                  <span class="modal_user_info_content_additional_elem"
                        v-if="userInfoForModal.birth_year">
                    <svg width="14" height="14">
                      <use xlink:href='../sprite.svg#ring'/>
                    </svg>
                    {{ userInfoForModal.birth_year }} год рождения
                  </span>

              <span class="modal_user_info_content_additional_elem" v-if="userInfoForModal.university">
                    <svg width="14" height="14">
                      <use xlink:href='../sprite.svg#ring'/>
                    </svg>
                    Где прошли мои школьные годы: {{ userInfoForModal.university }}
                  </span>

              <span class="modal_user_info_content_additional_elem" v-if="userInfoForModal.work">
                    <svg width="14" height="14">
                      <use xlink:href='../sprite.svg#ring'/>
                    </svg>
                    Моя работа: {{ userInfoForModal.work }}
                  </span>

              <span class="modal_user_info_content_additional_elem" v-if="userInfoForModal.hobby">
                    <svg width="14" height="14">
                      <use xlink:href='../sprite.svg#ring'/>
                    </svg>
                    Что я делаю часами: {{ userInfoForModal.hobby }}
                  </span>


              <span class="modal_user_info_content_additional_elem" v-if="userInfoForModal.fact">
                    <svg width="14" height="14">
                      <use xlink:href='../sprite.svg#ring'/>
                    </svg>
                    Интересный факт обо мне: {{ userInfoForModal.fact }}
                  </span>


              <span class="modal_user_info_content_additional_elem" v-if="userInfoForModal.language">
                    <svg width="14" height="14">
                      <use xlink:href='../sprite.svg#ring'/>
                    </svg>
                    На каких языках я говорю: {{ userInfoForModal.language }}
                  </span>


              <span class="modal_user_info_content_additional_elem" v-if="userInfoForModal.favorites">
                    <svg width="14" height="14">
                      <use xlink:href='../sprite.svg#ring'/>
                    </svg>
                    Что я безумно люблю: {{ userInfoForModal.favorites }}
                  </span>

              <span class="modal_user_info_content_additional_elem" v-if="userInfoForModal.living">
                    <svg width="14" height="14">
                      <use xlink:href='../sprite.svg#ring'/>
                    </svg>
                    Где я живу: {{ userInfoForModal.living }}
                  </span>

            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade-list">
      <div v-if="!loading" style="position: relative">

        <div class="catalog_objects"
             v-show="handlingObjects && !this.status_map_catalog && this.status_count_objects">
          <TransitionGroup name="list">
            <div v-for="(elem, index) in handlingObjects" :key="elem" class="catalog_objects_elem"
                 @click="$router.push(`object/${elem.objectInfo.id}`)">
              <div class="catalog_objects_elem_slider_favorite"
                   :class="{active: checkFavorite(elem.objectInfo.id)}"
                   @click.stop="addFavorite(elem.objectInfo.id)">
                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                  <use xlink:href='../sprite.svg#favorite'/>
                </svg>
              </div>

              <div class="catalog_objects_elem_slider_comparison"
                   :class="{active: checkComparison(elem.objectInfo.id)}"
                   @click.stop="addComparison(elem.objectInfo.id, checkComparison(elem.objectInfo.id))">
                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                  <use xlink:href='../sprite.svg#compare'/>
                </svg>
              </div>

              <div class="catalog_objects_elem_type_deal" v-if="elem.objectInfo.sell_type != 1"
                   :class="{deal_buy: elem.objectInfo.sell_type == 1, deal_rent: elem.objectInfo.sell_type == 2, deal_sell: elem.objectInfo.sell_type == 3}">
                <span v-if="elem.objectInfo.sell_type == 3">Продажа</span>
                <span v-if="elem.objectInfo.sell_type == 2">Аренда</span>
              </div>

              <div class="catalog_objects_elem_type_deal credit" v-if="elem.objectInfo.can_credit == 1">
                <span>Ипотека</span>
              </div>

              <div class="catalog_objects_elem_type_deal business"
                   v-if="elem.objectInfo.type_property == 1 && elem.objectInfo.commercial_use == 1">
                <span>Бизнес</span>
              </div>


              <div class="catalog_objects_elem_user_info" @click.stop="openUserInfo(elem.userInfo)"
                   v-if="elem.userInfo.show_my_card == 1">
                <div class="catalog_objects_elem_user_info_img">
                  <picture>
                    <source
                      :srcset="`${this.url_backend_img}${elem.userInfo.id}/${elem.userInfo.user_photo[0].fname}-preview.webp`"
                      type="image/webp">
                    <img
                      :src="`${this.url_backend_img}${elem.userInfo.id}/${elem.userInfo.user_photo[0].fname}-preview.png`"
                      alt="">
                  </picture>
                </div>
              </div>

              <div class="catalog_objects_elem_slider">
                <swiper-container @click.stop="" class="" init="false" navigation="true"
                                  slides-per-view="1" speed="800" pagination="true">
                  <swiper-slide class="catalog_objects_elem_slider_img"
                                @click="$router.push(`object/${elem.objectInfo.id}`)"
                                v-for="(photo, index) in elem.objectInfo.photos">
                    <div class="catalog_objects_elem_slider_img_content">
                      <picture>
                        <source
                          :srcset="`${this.url_backend_img}${photo.user_id}/${photo.fname}-preview.webp`"
                          type="image/webp">
                        <img :src="`${this.url_backend_img}${photo.user_id}/${photo.fname}-preview.png`"
                             alt="">
                      </picture>
                    </div>
                  </swiper-slide>
                </swiper-container>
                <div class="catalog_objects_elem_slider_description">
                  <div class="catalog_objects_elem_slider_description_header">
                    <div class="catalog_objects_elem_slider_description_header_left">
                      <h4>{{ elem.objectInfo.country }}, {{ elem.objectInfo.city }}</h4>
                      <div class="catalog_objects_elem_adress">
                        {{ elem.objectInfo.street }}, {{ elem.objectInfo.home_num }}
                      </div>
                      <!--<div class="catalog_objects_elem_date">
                        {{getDateCreated(elem.objectInfo.published)}}
                      </div>-->
                    </div>
                    <!--<div class="catalog_objects_elem_slider_description_header_rating">
                      <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"
                           style="display: block; height: 12px; width: 12px;">
                        <path
                            d="M15.094 1.579l-4.124 8.885-9.86 1.27a1 1 0 0 0-.542 1.736l7.293 6.565-1.965 9.852a1 1 0 0 0 1.483 1.061L16 25.951l8.625 4.997a1 1 0 0 0 1.482-1.06l-1.965-9.853 7.293-6.565a1 1 0 0 0-.541-1.735l-9.86-1.271-4.127-8.885a1 1 0 0 0-1.814 0z"></path>
                      </svg>
                      <p>{{ elem.objectInfo.rating }}</p>
                    </div>-->
                    <div class="catalog_objects_elem_slider_description_header_right">
                      <div class="catalog_objects_elem_slider_description_price">
                        <p>
                          {{
                            getCurrencyCourse(elem.objectInfo.price, elem.objectInfo.currency, this.settings.currency_from_usd)
                          }}
                          {{ this.settings.currency_symbol }}
                        </p>
                      </div>
                      <!--<div class="catalog_objects_elem_slider_description_header_eyes">
                        <span> {{ elem.objectInfo.eyes }}</span>
                        <svg fill="#000000" viewBox="0 0 32 32" version="1.1"
                             xmlns="http://www.w3.org/2000/svg"
                             style="display: block; fill: none; height: 16px; width: 16px; stroke: currentcolor; stroke-width: 2; overflow: visible;">
                          &gt;
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                          <g id="SVGRepo_iconCarrier"><title>eye</title>
                            <path
                              d="M0 16q0.064 0.128 0.16 0.352t0.48 0.928 0.832 1.344 1.248 1.536 1.664 1.696 2.144 1.568 2.624 1.344 3.136 0.896 3.712 0.352 3.712-0.352 3.168-0.928 2.592-1.312 2.144-1.6 1.664-1.632 1.248-1.6 0.832-1.312 0.48-0.928l0.16-0.352q-0.032-0.128-0.16-0.352t-0.48-0.896-0.832-1.344-1.248-1.568-1.664-1.664-2.144-1.568-2.624-1.344-3.136-0.896-3.712-0.352-3.712 0.352-3.168 0.896-2.592 1.344-2.144 1.568-1.664 1.664-1.248 1.568-0.832 1.344-0.48 0.928zM10.016 16q0-2.464 1.728-4.224t4.256-1.76 4.256 1.76 1.76 4.224-1.76 4.256-4.256 1.76-4.256-1.76-1.728-4.256zM12 16q0 1.664 1.184 2.848t2.816 1.152 2.816-1.152 1.184-2.848-1.184-2.816-2.816-1.184-2.816 1.184l2.816 2.816h-4z"></path>
                          </g>
                        </svg>
                      </div>-->
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </TransitionGroup>
          <div ref="observer" class="observer"></div>
        </div>
        <div v-show="this.objects_if_not_enough && !this.status_count_objects" style="max-width: 1600px; margin: 0 auto">
          <TransitionGroup name="list">

            <div v-for="(array, index) in this.objects_if_not_enough" :key="array"
                 class="catalog_objects_list_type">

              <h2 @click="$router.push(`${array[0].url}`)">Все {{ array[0].nameRU }}</h2>

              <div class="catalog_objects">
                <div v-for="(elem, index) in array" :key="elem" class="catalog_objects_elem"
                     @click="$router.push(`${elem.url}/${elem.id}`)">
                  <div class="catalog_objects_elem_slider">
                    <swiper-container @click.stop="" class="" init="false" navigation="true"
                                      slides-per-view="1" speed="800" pagination="true">
                      <swiper-slide class="catalog_objects_elem_slider_img"
                                    v-for="(photo, index) in elem.galery">
                        <div class="catalog_objects_elem_slider_img_content">
                          <picture>
                            <source
                              :srcset="`${this.url_backend_img}${photo.user_id}/${photo.fname}-preview.webp`"
                              type="image/webp">
                            <img
                              :src="`${this.url_backend_img}${photo.user_id}/${photo.fname}-preview.png`"
                              alt="">
                          </picture>
                        </div>
                      </swiper-slide>
                    </swiper-container>
                    <div class="catalog_objects_elem_slider_description">
                      <div class="catalog_objects_elem_slider_description_header">
                        <div class="catalog_objects_elem_slider_description_header_left">
                          <h4>{{ elem.country }}, {{ elem.city }}</h4>
                          <div class="catalog_objects_elem_adress">
                            {{ elem.street }}, {{ elem.home_num }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </TransitionGroup>
        </div>

        <div v-show="handlingObjects && this.status_map_catalog && this.status_count_objects">
          <YandexMap :objects="handlingObjects"></YandexMap>
        </div>

        <div v-if="!handlingObjects || (this.status_map_catalog && !this.objects_if_not_enough) && !this.status_count_objects">
          <h2 class="not_found">Объявлений не найдено</h2>
        </div>

        <div class="change_mode_catalog" v-if="this.status_count_objects">
          <button @click="showOnMap()" v-if="this.status_map_catalog">Показать список</button>
          <button @click="showOnMap()" v-if="!this.status_map_catalog">Показать карту</button>
        </div>
      </div>
    </transition>
    <transition name="fade-list">
      <Loading v-if="loading" class="favorites_loading"></Loading>
    </transition>
  </div>
</template>

<script>

import YandexMap from "@/components/YandexMap.vue";

import {register} from 'swiper/element/bundle';
import {mapActions, mapMutations, mapState} from "vuex";


import Loading from "@/components/UI/Loading.vue";

register();


export default {
  props: ['favorites'],
  components: {
    Loading,
    YandexMap
  },
  data() {
    return {
      first_load: true,

      statusLoadObjects: true,
      objectsLength: 0,
      active_mode: 'catalog',

      objectsFavotites: {},
      loading: true,

      modal_user_info: false,
    }
  },
  methods: {
    getCurrencyCourse(object_price, currencyID, selected_currency_from_USD) {
      if (currencyID == this.settings.currency_id) {
        return Math.ceil(object_price);
      } else {
        let object_currency_USD;
        for (let i in this.list_of_currency) {
          if (this.list_of_currency[i].id == currencyID) {
            object_currency_USD = Number(this.list_of_currency[i].from_USD) * Number(object_price);
          }
        }
        return Math.ceil((object_currency_USD * Number(selected_currency_from_USD)));
      }
    },
    getDateCreated(timestamp) {
      let date_new = new Date(Number(timestamp) * 1000);
      let month = date_new.getUTCMonth() + 1; //months from 1-12
      let day = date_new.getUTCDate();
      let year = date_new.getUTCFullYear();

      return day + "." + month + "." + year;
    },
    async showOnMap() {
      if (this.status_map_catalog) {
        await this.set_status_map_catalog(false);
      } else {
        await this.set_status_map_catalog(true);
      }
    },
    openUserInfo(userInfo) {
      this.setUserInfoForModal(userInfo);
      this.modal_user_info = true;
    },
    ...mapMutations({
      setUserFavorites: 'setUserFavorites',
      setUserComparison: 'setUserComparison',
      setUserInfoForModal: 'setUserInfoForModal',
      set_status_map_catalog: 'set_status_map_catalog',
    }),
    ...mapActions({
      getLoadObjects: 'getLoadObjects',
      saveFavorites: 'saveFavorites',
      saveComparison: 'saveComparison',
      getLoadObjectsFavorites: 'getLoadObjectsFavorites',
    }),
    parseObjPhotos(obj, obj_webp) {
      if (obj === '{}') {
        return {'one': 'no_photo.jpg'};
      } else {
        let parceObj = JSON.parse(obj);
        let parceObj_webp = JSON.parse(obj_webp);
        let res = {}
        for (let i in parceObj) {
          res[i] = {
            'png': parceObj[i],
            'webp': parceObj_webp[i],
          }
        }
        return res;
      }
    },
    async getObjects(loadMoreObjects = false, click_logo = false) {
      if(click_logo){
        this.first_load = true;
      }
      return this.getLoadObjects({'loadMore': loadMoreObjects, 'first_load': this.first_load})
        .then(res => {
          this.loading = false;
          this.first_load = false;
          return true;
        })
        .then(res => {
          this.setSwiper();
          if (!loadMoreObjects) {
            this.startObserve();
          }
        })
    },
    async getObjectsFavorites() {
      return this.getLoadObjectsFavorites()
        .then(res => {
          this.objectsFavotites = res;
          this.loading = false;
          return true;
        })
        .then(res => {
          this.setSwiper();
        })
    },
    setSwiper() {
      const swiperEl = document.querySelectorAll('.catalog_objects_elem_slider>swiper-container');
      const swiperParams = {
        slidesPerView: 1,
        breakpoints: {},
        on: {
          init() {
          },
        },
        injectStyles: [
          `
            .swiper-button-next:after, .swiper-button-prev:after {
              color: #3b9ed4;
              font-size: 12px;
              padding: 8px 11px 8px 11px;
              border-radius: 80px;
              font-weight: bold;
              background-color: #dddddd;
              box-shadow: 0px 0px 12px 0px black;
              transition: all .3s
            }

            .swiper-button-disabled {
              display: none;
            }

            .swiper-button-next, .swiper-button-prev {
               opacity: .4;
               transition: all .3s
            }

            .swiper-button-next:hover, .swiper-button-prev:hover {
              opacity: 1;
            }

            .swiper-pagination-bullet {
              background:#dddddd;
              width: 14px;
              height: 14px;
              opacity: 1;
            }
            .swiper-pagination-bullet.swiper-pagination-bullet-active {
              background:#3B9ED4;
            }
          `,
        ],
      };

      for (let i in swiperEl) {
        if (typeof swiperEl[i] != 'function' && typeof swiperEl[i] != 'number') {
          Object.assign(swiperEl[i], swiperParams);
          swiperEl[i].initialize();
        }

      }

    },

    startObserve() {
      const options = {
        rootMargin: '0px',
        threshold: 1.0
      }
      const callback = (entries, observer) => {
        if (entries[0].isIntersecting) {
          this.getObjects(true)
            .then(res => {
              if (!res) return false;
            })
            .catch(err => {
              console.log(err);
            })
        }

      };
      const observer = new IntersectionObserver(callback, options)
      observer.observe(this.$refs.observer)
    },
    addFavorite(id) {
      let result = []

      if (this.checkFavorite(id)) {
        result = this.userFavorites;

        for (let i in result) {
          if (result[i] == id) {
            result.splice(i, 1);
          }
        }

      } else {
        if (!this.userFavorites.length) {
          result.push(id);
        } else {
          result = this.userFavorites;
          result.push(id);
        }
      }

      this.setUserFavorites(result);
      if (this.authorization) {
        this.saveFavorites(JSON.stringify(result))
      } else {
        localStorage.setItem("favorites", JSON.stringify(result));
      }
    },
    checkFavorite(id) {
      for (let i in this.userFavorites) {
        if (this.userFavorites[i] == id) {
          return true;
        }
      }

      return false;
    },

    addComparison(id, status = false) {
      let result = []

      if (status) {
        result = this.userComparison;

        for (let i in result) {
          if (result[i] == id) {
            result.splice(i, 1);
          }
        }

      } else {
        if (!this.userComparison.length) {
          result.push(id);
        } else {
          result = this.userComparison;
          result.push(id);
        }
      }

      if (this.authorization) {
        this.saveComparison(JSON.stringify(result))
      } else {
        localStorage.setItem("comparison", JSON.stringify(result));
      }
      this.setUserComparison(result);
    },
    checkComparison(id) {
      for (let i in this.userComparison) {
        if (this.userComparison[i] == id) {
          return true;
          break;
        }
      }
    },

  },
  computed: {
    handlingObjects() {
      if (!this.favorites) {
        return this.objects;
      } else {
        return this.objectsFavotites;
      }
    },
    ...mapState({
      objects: state => state.objects,
      userFavorites: state => state.userFavorites,
      authorization: state => state.authorization,
      userInfo: state => state.userInfo,
      userComparison: state => state.userComparison,
      url_backend_img: state => state.url_backend_img,
      selected_city: state => state.selected_city,
      userInfoForModal: state => state.userInfoForModal,
      filter_sell_type: state => state.filter_sell_type,
      filter_time_limit: state => state.filter_time_limit,
      filter_object_type: state => state.filter_object_type,
      filter_sell_selling_type: state => state.filter_sell_selling_type,
      settings: state => state.settings,
      status_map_catalog: state => state.status_map_catalog,
      list_of_currency: state => state.list_of_currency,
      status_count_objects: state => state.status_count_objects,
      objects_if_not_enough: state => state.objects_if_not_enough,
    }),
  },
  mounted() {
    if (this.favorites) {
      this.getObjectsFavorites()
        .then(res => {
          if (!res) throw 'Ошибка при получении объектов';
          this.statusLoadObjects = false;
        })
        .catch(err => {
          //console.log(err);
        })
    } else {
      this.getObjects()
        .then(res => {
          if (!res) throw 'Ошибка при получении объектов';
          this.statusLoadObjects = false;
        })
        .catch(err => {
          //console.log(err);
        })
    }
  },
  watch: {
    selected_city(newVal) {
      this.getObjects(false, true)
        .then(res => {
          if (!res) throw 'Ошибка при получении объектов';
        })
        .catch(err => {
          //console.log(err);
        })
    },
    userFavorites: {
      handler: function () {
        this.statusLoadObjects = true;
        this.getObjectsFavorites()
          .then(res => {
            if (!res) throw 'Ошибка при получении объектов';
            this.statusLoadObjects = false;
          })
          .catch(err => {
            //console.log(err);
          })
      },
      deep: true,
      immediate: true
    },
    authorization(newVal) {
      this.statusLoadObjects = true;
      this.getObjectsFavorites()
        .then(res => {
          if (!res) throw 'Ошибка при получении объектов';
          this.statusLoadObjects = false;
        })
        .catch(err => {
          //console.log(err);
        })
    }
  }
}
</script>

<style>
.catalog_objects {
  padding: 0 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 20px;
}

.catalog_objects_elem {
  width: 20vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  height: auto;
  max-width: 330px;
  cursor: pointer;
  position: relative;
}

.catalog_objects_elem_slider {
  display: grid;
  align-items: flex-start;
  justify-content: center;
  border-radius: 10px;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.catalog_objects_elem_slider img {
  min-height: 100%;
  width: 200%;
}

.catalog_objects_elem_slider_img {
  display: flex;
}

.catalog_objects_elem_slider .swiper-initialized.swiper-horizontal.swiper-backface-hidden {
  border-radius: 10px;
}


.catalog_objects_elem_slider_img.swiper-slide-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.catalog_objects_elem_slider_description {
  margin: 10px auto;
  width: 90%;
}

.catalog_objects .swiper-initialized.swiper-horizontal {
  width: 18vw;
  height: 17vw;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 300px;
}

.catalog_objects_elem_slider_description_header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #4A556D;
  font-size: 16px;
  line-height: 19px;
}

.catalog_objects_elem_adress,
.catalog_objects_elem_date {
  color: #20273E;
  opacity: 0.5;
}

.catalog_objects_elem_slider_description_header_eyes {
  justify-content: flex-end;
}

.catalog_objects_elem_slider_description_header_rating {
  display: flex;
  width: 30px;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
}

.catalog_objects_elem_slider_description_header_rating svg, .filters_btn_modal svg {
  margin-right: 0;
}

.catalog_objects_elem_slider_description_price {
  font-weight: bold;
}


@media (min-width: 1230px) {
  .catalog_objects_elem_slider {
    max-width: 300px;
    min-height: 370px;
  }
}

@media (max-width: 1200px) {
  .catalog_objects .swiper-initialized.swiper-horizontal {
    width: 23vw;
    height: 21vw;
    max-width: none;
  }

  .catalog_objects_elem {
    width: 27vw;
  }
}

@media (max-width: 935px) {
  .catalog_objects .swiper-initialized.swiper-horizontal {
    width: 34vw;
    height: 30vw;
  }

  .catalog_objects_elem {
    width: 39vw;
  }
}

.catalog_objects_list_type .catalog_objects_elem {
  width: 35vw;
  min-width: 300px;
}

@media (max-width: 805px) {
  .catalog_objects .swiper-initialized.swiper-horizontal {
    width: 90%;
  }

  .catalog_objects_list_type .catalog_objects .swiper-initialized.swiper-horizontal {
    width: 110%;
  }

  .catalog_objects_elem {
    width: 50%;
  }

  .catalog_objects_elem_slider_description {
    width: 85%;
  }

  .catalog_objects {
    padding: 0;
  }
}

@media (max-width: 550px) {
  .catalog_objects_elem {
    width: 100%;
    max-width: none;
  }

  .catalog_objects_elem_slider img {

  }

  .catalog_objects_elem_slider .swiper-initialized.swiper-horizontal.swiper-backface-hidden {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.observer {
  height: 30px;
  width: 100%;
}

.not_found {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}


.fade-list-move,
.fade-list-enter-active,
.fade-list-leave-active {
  transition: all 0.5s ease;
}

.fade-list-enter-from,
.fade-list-leave-to {
  opacity: 0;
}

.fade-list-leave-active {
  position: absolute;
}

.catalog_objects_elem_slider_comparison {
  position: absolute;
  right: 60px;
  z-index: 3;
  top: 10px;
}

.catalog_objects_elem_slider_favorite {
  position: absolute;
  right: 30px;
  z-index: 3;
  top: 10px;
}

.catalog_objects_elem_slider_favorite svg {
  transition: all .3s;
  fill: #ddd;
}

.catalog_objects_elem_slider_comparison svg {
  transition: all .3s;
  fill: #ddd;
}

.catalog_objects_elem_slider_favorite:hover svg,
.catalog_objects_elem_slider_comparison:hover svg,
.catalog_objects_elem_slider_favorite.active svg,
.catalog_objects_elem_slider_comparison.active svg {
  fill: #4bbfff;
}

.catalog_objects_elem_slider_img_content {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.favorites_loading {
  position: fixed;
  top: 0;
  z-index: 1;
}

.catalog_objects_elem_type_deal {
  position: absolute;
  left: 20px;
  top: 10px;
  z-index: 2;
  padding: 5px;
  font-weight: bold;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0px 0px 5px -3px black;
}

.catalog_objects_elem_type_deal.credit {
  top: 40px;
  background-color: #4bbfff;
}

.catalog_objects_elem_type_deal.business {
  top: 70px;
  background-color: #4b4eff;
  color: #fff;
}


.catalog_objects_elem_type_deal.deal_rent {
  background-color: orange;
}

.catalog_objects_elem_type_deal.deal_buy {
  background-color: blue;
}


.catalog_objects_elem_type_deal.deal_sell {
  background-color: yellow;
}

.catalog_objects_elem_user_info {
  position: absolute;
  bottom: 120px;
  left: 20px;
  z-index: 3;
  border: 3px solid #ddd;
  border-radius: 50%;
}

.catalog_objects_elem_user_info_img {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px -2px black;
}

.catalog_objects_elem_user_info_img img {
  width: 100%;
}

.modal_user_info_background {
  position: fixed;
  height: 100%;
  top: 0;
  width: 100%;
  z-index: 99;
  background-color: #00000040;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_user_info {
  background-color: #FFFFFF;
  overflow: auto;
  width: 100%;
  height: 70vh;
  max-width: 580px;
  border-radius: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
}

.modal_user_info_header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  padding: 0px 10px;
  margin-bottom: 20px;
}

.modal_user_info_content_main_img {
  width: 80px;
  height: 80px;
  border-radius: 60px;
  overflow: hidden;
  display: flex;
}

.modal_user_info_content_main_img img {
  width: 100%;
}

.modal_user_info_content_additional_elem svg {
  width: 20px;
  height: 20px;
}

.modal_user_info_content_additional_elem {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.modal_user_info_content {
  width: 80%;
}

.modal_user_info_content_main {
  box-shadow: 0px 162px 65px rgba(0, 0, 0, 0.01), 0px 91px 55px rgba(0, 0, 0, 0.03), 0px 40px 40px rgba(0, 0, 0, 0.04), 0px 10px 22px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0;
  background-color: white;
}

.modal_user_info_content_features {

}

.modal_user_info_content_features_elem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0;
}

.modal_user_info_content_features_elem span:nth-of-type(1) {
  font-weight: bold;
  font-size: 18px;
}

.modal_user_info_content_features_elem span:nth-of-type(2) {
  font-size: 14px;
}

.modal_user_info_content_main_title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin-top: 10px;
  width: 100%;
}

.modal_user_info_content_additional {
  margin-top: 30px;
}

.change_mode_catalog {
  position: fixed;
  bottom: 90px;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 4;
  justify-content: center;
}

.change_mode_catalog button {
  background: #3B9ED4;
  color: white;
  box-shadow: 0px 4px 15px rgb(59 158 212);
  border-color: transparent;
  display: inline-block;
  cursor: pointer;
  padding: 0px 15px;
  user-select: none;
  transition: all .3s;
  height: 40px;
  max-width: 300px;
  width: 170px;
  font-weight: bold;
  border-radius: 60px;
}

.change_mode_catalog button:hover {
  opacity: 0.8;
}

.catalog_objects_elem_slider_description_header_eyes {
  display: flex;
  align-items: center;
}

.catalog_objects_elem_slider_description_header_eyes span {
  margin-right: 5px;
}

.catalog_objects_list_type {
  padding: 0 30px;
  display: flex;
  margin-top: 20px;
  padding-bottom: 20px;
  flex-direction: column;
}

</style>
