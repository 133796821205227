<template>
  <input class="input-number" type="number" @keydown="checkKeyDown($event)" :value="modelValue" :placeholder="placeholder" @input="updateInput">
</template>

<script>
export default {
  name: 'MainInputNumber',
  props: {
    modelValue: [Number, String],
    placeholder: String,
    max_length: {
      default: 10,
      type: Number
    },
  },
  methods: {
    checkKeyDown(e) {
      if (e.key === 'e' || e.key === ',' || e.key === '.' || e.key === '-') {
        e.preventDefault();
      }
    },
    updateInput(event) {
      if (Number(event.target.value) < 0) {
        this.$emit('update:modelValue', 0);
      } else {
        this.$emit('update:modelValue', event.target.value);
      }
    },
  },
  watch: {
    modelValue(newVal, oldVal) {
      if (String(newVal).length > this.max_length) {
        console.log("Будет превышено, надо отменить")
        this.$emit('update:modelValue', oldVal);
      }
    }
  },
}
</script>

<style>
.input-number {
  padding: 0.5em;
  border: 1px solid #ddd;
  border-radius: 10px;
}
</style>
