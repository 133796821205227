import {createStore} from 'vuex'
import axios from 'axios'

export default createStore({
  state: {


    url_backend: "https://binolama.com/api/property.php", // Ссылка на бек - меняем под свою структуру
    url_backend_img: "https://binolama.com/files/", // Ссылка для фоток
    url_backend_loader: "https://binolama.com/", // Ссылка для загрузчика

    objects: [],

    arrayConveniences: // Удобства
      ['Консьерж', 'Кондиционер', 'Холодильник', 'Домофон', 'Открытый бассейн', 'Закрытый бассейн',
        'Химчистка', 'Свой двор', 'Гараж', 'Телевизор', 'Кухонная мебель', 'Мусоропровод', 'Интернет', 'Видеонаблюдение',
        'Круглосуточная охрана', 'Спутниковое ТВ', 'Сад', 'Сауна', 'Мебель', 'Стиральная машина', 'Посудомойка', 'Терраса',
        'Лифт', 'Скоростной лифт', 'Подвал', 'Хранение одежды', 'Спальные места', 'Тёплый пол',
        'Плита газовая', 'Плита электрическая', 'Духовка газовая', 'Духовка электрическая',
        'Балкон', 'Лоджия', 'Обслуживаемая крыша'],

    arrayConveniencesOffice: // Удобства для Офиса
      ['Кондиционер', 'Холодильник', 'Открытый бассейн', 'Закрытый бассейн',
        'Лофт', 'Мебель', 'Лифт', 'Скоростной лифт', 'Интернет', 'Видеонаблюдение', 'Круглосуточная охрана', 'Ресепшн'],
    arrayConveniencesKoworking: // Удобства для Коворкинга
      ['Кондиционер', 'Холодильник', 'Открытый бассейн', 'Закрытый бассейн',
        'Лофт', 'Мебель', 'Лифт', 'Скоростной лифт', 'Интернет', 'Видеонаблюдение', 'Круглосуточная охрана', 'Ресепшн'],
    arrayConveniencesBuilding: // Удобства для Здания
      ['Кондиционер', 'Холодильник', 'Открытый бассейн', 'Закрытый бассейн',
        'Лофт', 'Мебель', 'Лифт', 'Скоростной лифт', 'Подвал', 'Интернет', 'Видеонаблюдение', 'Круглосуточная охрана',
        'Ресепшн', 'Химчистка', 'Свой двор', 'Терраса', 'Сад'],
    arrayConveniencesMarketPlace: // Удобства для Торговой площади
      ['Кондиционер', 'Интернет', 'Мебель', 'Видеонаблюдение', 'Круглосуточная охрана',
        'Лифт', 'Скоростной лифт', 'Ресепшн'],
    arrayConveniencesFreePlace: // Удобства для Свободного размещения
      ['Кондиционер', 'Интернет', 'Мебель', 'Видеонаблюдение', 'Круглосуточная охрана',
        'Лифт', 'Скоростной лифт', 'Ресепшн'],
    arrayConveniencesWorking: // Удобства для Производства
      ['Кондиционер', 'Интернет', 'Мебель', 'Видеонаблюдение', 'Круглосуточная охрана',
        'Лифт', 'Скоростной лифт', 'Пандус', 'Свой двор', 'Гараж'],
    arrayConveniencesStorage: // Удобства для Склад
      ['Кондиционер', 'Интернет', 'Мебель', 'Видеонаблюдение', 'Круглосуточная охрана',
        'Лифт', 'Скоростной лифт', 'Пандус', 'Свой двор', 'Гараж'],
    arrayConveniencesGarage: // Удобства для Гаража
      ['Видеонаблюдение', 'Круглосуточная охрана'],
    arrayConveniencesBusiness: // Удобства для Бизнеса
      ['Видеонаблюдение', 'Круглосуточная охрана'],

    arrayEnvironment: // Окружение
      ['Сквер', 'Парк', 'Лес', 'Озеро', 'Река', 'Море', 'Горы', 'Школа', 'Детский сад', 'Торговый центр', 'Фитнес', 'Минимаркет', 'Супермаркет',
        'Детская площадка', 'Workout площадка', 'Спортивный клуб', 'Кафе и рестораны', 'Аптека', 'Бассейн', 'Кинотеатр',
        'Музей', 'Выставочный комплекс', 'Кладбище', 'Церковь', 'Мечеть', 'Больница', 'Поликлиника'],


    search: null,

    menu_list: null,

    breadcrumbList: null,

    draft_id: null,

    was_changed: false,
    modal_accept: false,
    next_redirect: null,

    globalAlerts: [],

    list_of_currency: null,

    selected_city: null,
    selected_city_id: null,
    selected_country: null,
    selected_district: null,
    selected_metro: null,
    selected_country_iso: null,

    modal_list_metro: false,
    modal_list_city: false,
    modal_list_district: false,

    filter_sorted_price: null,
    filter_date: null,
    filter_sell_type: null,
    filter_time_limit: null,
    filter_object_type: null,
    filter_sell_selling_type: null,
    filter_selected_district: [],
    filter_selected_metro: [],
    status_modal_filters_region: false,

    filters: {
      sell_type: 0,
      time_limit: 0,
      price_from: null,
      price_to: null,
      true_seller: 0,
      repare_quality: 0,
      build_year_from: '',
      build_year_to: '',
      rooms_from: '',
      rooms_to: '',
      object_type: 0,
      rating_from: 0,
      checked: 0,
      from_owner: 0,
      sell_selling_type: 0,
      commercial_use: 0,
      metro: 0,
      metro_limit: 0,
      type_property: 0,
      area: {
        general_from: '',
        general_to: '',
        living_from: '',
        living_to: '',
        kitchen_from: '',
        kitchen_to: '',
      },
      planning: [],
      has_planning: 0,
      height_from: 0,
      risers_from: '',
      risers_to: '',
      bathrooms_from: '',
      bathrooms_to: '',
      balcony: 0,
      oven_type: 0,
      renovation: 0,
      air_condition: 0,
      floor_from: '',
      floor_to: '',
      floor_only: {
        not_first: 0,
        not_last: 0,
        last: 0,
      },
      floors_from: '',
      floors_to: '',
      penthouse: 0,
      views: 0,
      home_type: 0,
      demolition: 0,
      lift: 0,
      parking: 0,
      selling_type: 0,
      can_credit: 0,
      infrastructure: [],
      conveniences: [],
      environment: [],
      published: 0,
      can_installment: 0,
      photo: 0,
      video: 0,
      global_filters: 0,
      bathrooms_types: [],
    },

    filters_main_block: [false],
    filter_count_rooms: [],

    status_count_objects: true,
    offset: 0,
    status_map_catalog: false,
    objects_if_not_enough: null,

    authorization: false,
    userInfo: null,
    userFavorites: [],
    userComparison: [],

    userInfoForModal: null,

    loading_authorization: true,

    settings: {
      language: 'RU',
      language_img: 'rus-border-rad.svg',
      language_url: 'ru_RU',
      currency: null,
      currency_symbol: null,
      currency_id: null,
      currency_from_usd: null,
      unit: 'Метры',
    },

    list_of_metro: null,
    list_of_district: null,
  },
  getters: {
    objects_id(state) {
      console.log("Объекты")
      console.log(state.objects)

      if(state.objects.length){
        return state.objects.map(item => item.objectInfo.id);
      }else{
        return [];
      }

    },

    metro_only_city(state) {
      return state.list_of_metro.filter(
        (elem) => elem.region_id == state.selected_city_id
      )
    },

    district_only_city(state) {
      return state.list_of_district.filter(
        (elem) => elem.region_id == state.selected_city_id
      )
    },

  },
  mutations: {
    set_selected_city(state, selected_city) {
      state.selected_city = selected_city;
    },
    set_list_of_metro(state, list_of_metro) {
      state.list_of_metro = list_of_metro;
    },
    set_list_of_district(state, list_of_district) {
      state.list_of_district = list_of_district;
    },

    set_status_count_objects(state, status_count_objects) {
      state.status_count_objects = status_count_objects;
    },
    set_objects_if_not_enough(state, objects_if_not_enough) {
      state.objects_if_not_enough = objects_if_not_enough;
    },


    setGlobalAlerts(state, globalAlerts) {
      for (let i in state.globalAlerts) {
        if (state.globalAlerts[i].id == globalAlerts.id) {
          state.globalAlerts.splice(i, 1);
          break;
        }
      }
      state.globalAlerts.push(globalAlerts);
    },
    deleteGlobalAlert(state, alertID) {
      for (let i in state.globalAlerts) {
        if (state.globalAlerts[i].id == alertID) {
          state.globalAlerts.splice(i, 1);
          break;
        }
      }
    },

    set_list_of_currency(state, list_of_currency) {
      state.list_of_currency = list_of_currency;
    },
    set_menu_list(state, menu_list) {
      state.menu_list = menu_list;
    },


    set_was_changed(state, bool) {
      state.was_changed = bool;
    },
    set_draft_id(state, draft_id) {
      state.draft_id = draft_id;
    },
    set_selected_metro(state, selected_metro) {
      state.selected_metro = selected_metro;
    },
    set_status_modal_filters_region(state, bool) {
      state.status_modal_filters_region = bool;
    },
    set_modal_list_city(state, modal_list_city) {
      state.modal_list_city = modal_list_city;
    },
    set_modal_list_metro(state, modal_list_metro) {
      state.modal_list_metro = modal_list_metro;
    },

    set_modal_list_district(state, modal_list_district) {
      state.modal_list_district = modal_list_district;
    },

    set_next_redirect(state, next_redirect) {
      state.next_redirect = next_redirect;
    },
    set_modal_accept(state, modal_accept) {
      state.modal_accept = modal_accept;
    },
    set_selected_city_id(state, selected_city_id) {
      state.selected_city_id = selected_city_id;
    },

    set_filter_count_rooms(state, filter_count_rooms) {
      state.filter_count_rooms[filter_count_rooms] = true;
    },
    unset_filter_count_rooms(state, filter_count_rooms) {
      state.filter_count_rooms[filter_count_rooms] = false;
    },
    clear_filter_count_rooms(state) {
      state.filter_count_rooms = [];
    },


    push_filter_selected_district(state, filter_selected_district) {
      state.filter_selected_district.push(filter_selected_district)
    },

    unset_filter_selected_district(state, filter_selected_district) {
      for (let i in state.filter_selected_district) {
        if (state.filter_selected_district[i] == filter_selected_district) {
          state.filter_selected_district.splice(i, 1);
        }
      }
    },
    clear_filter_selected_district(state) {
      state.filter_selected_district = [];
    },


    push_filter_selected_metro(state, filter_selected_metro) {
      console.log("Пушим")
      console.log(filter_selected_metro)
      state.filter_selected_metro.push(filter_selected_metro)
    },

    unset_filter_selected_metro(state, filter_selected_metro) {
      for (let i in state.filter_selected_metro) {
        if (state.filter_selected_metro[i] == filter_selected_metro) {
          state.filter_selected_metro.splice(i, 1);
        }
      }
    },
    clear_filter_selected_metro(state) {
      state.filter_selected_metro = [];
    },


    set_array_filters_main_block(state, array) {
      state.filters_main_block = array;
    },
    set_filters_main_block(state, filters_main_block) {
      state.filters_main_block[filters_main_block] = true;
    },
    unset_filters_main_block(state, filters_main_block) {
      state.filters_main_block[filters_main_block] = false;
    },
    clear_filters_main_block(state) {
      state.filters_main_block = [false, true];
    },
    set_filter_sell_type(state, filter_sell_type) {
      state.filter_sell_type = filter_sell_type;
    },
    set_filter_sorted_price(state, filter_sorted_price) {
      state.filter_sorted_price = filter_sorted_price;
    },
    set_filter_date(state, filter_date) {
      state.filter_date = filter_date;
    },
    set_filter_time_limit(state, filter_time_limit) {
      state.filter_time_limit = filter_time_limit;
    },
    set_filter_object_type(state, filter_object_type) {
      state.filter_object_type = filter_object_type;
    },
    set_filter_sell_selling_type(state, filter_sell_selling_type) {
      state.filter_sell_selling_type = filter_sell_selling_type;
    },

    set_status_map_catalog(state, bool) {
      state.status_map_catalog = bool;
    },

    set_settings(state, obj) {
      state.settings[obj.param] = obj.value;
    },
    set_selected_country(state, selected_country) {
      state.selected_country = selected_country;
    },

    set_selected_district(state, selected_district) {
      state.selected_district = selected_district;
    },
    set_selected_country_iso(state, selected_country_iso) {
      state.selected_country_iso = selected_country_iso;
    },
    addObjects(state, objects) {
      state.objects = [...state.objects, ...objects];
    },
    setUserInfoForModal(state, userInfoForModal) {
      state.userInfoForModal = userInfoForModal;
    },
    setLoadingAuthorization(state, bool) {
      state.loading_authorization = bool;
    },
    setSearch(state, search) {
      state.search = search;
    },
    setObjects(state, objects) {
      state.objects = objects;
    },
    setAuthorization(state, bool) {
      state.authorization = bool;
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setBreadcrumbList(state, breadcrumbList) {
      state.breadcrumbList = breadcrumbList;
    },
    setUserFavorites(state, userFavorites) {
      state.userFavorites = userFavorites;
    },
    setUserComparison(state, userComparison) {
      state.userComparison = userComparison;
    },
    resetOffset(state, offset) {
      state.offset = offset;
    },
    addOffset(state, offset) {
      state.offset = state.offset + offset;
    },
    setGlobalFilters(state, value) {
      state.filters.global_filters = value;
    },
  },
  actions: {
    async saveFavorites({state, commit}, favorites) {
      try {
        const response = await axios.post(
          state.url_backend + '?saveFavorites',
          {
            params: {
              favorites: favorites,
              userID: state.userInfo.id,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async saveComparison({state, commit}, comparison) {
      try {
        const response = await axios.post(
          state.url_backend + '?saveComparison',
          {
            params: {
              comparison: comparison,
              userID: state.userInfo.id,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async getLoadObjectsFavorites({state}) {
      try {
        const response = await axios.post(
          state.url_backend + '?getLoadObjectsFavorites',
          {
            favorites: state.userFavorites,
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        return response.data;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async getLoadObjects({state, commit, getters}, params) {
      let offset;

      console.log("offset " + state.offset)
      console.log("params.loadMore " + params.loadMore)

      if (params.loadMore) {
        offset = state.offset;
      } else {
        offset = 0;
      }

      try {
        const response = await axios.post(
          state.url_backend + '?getObjects&offset=' + offset,
          {
            filters: state.filters,
            selected_city: state.selected_city,
            filter_sell_type: state.filter_sell_type,
            filter_sorted_price: state.filter_sorted_price,
            filter_date: state.filter_date,
            filter_time_limit: state.filter_time_limit,
            filter_object_type: state.filter_object_type,
            filter_sell_selling_type: state.filter_sell_selling_type,
            filter_selected_district: state.filter_selected_district,
            filter_selected_metro: state.filter_selected_metro,
            filter_count_rooms: state.filter_count_rooms,
            filters_main_block: state.filters_main_block,
            search: state.search,
            first_load: params.first_load,
            objects_id: getters.objects_id,
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        if (response.data) {
          if (response.data.status == "not enough") {
            commit('set_status_count_objects', false);
            if (!response.data.objects) {
              commit('set_objects_if_not_enough', null);
            } else {
              commit('set_objects_if_not_enough', response.data.objects);
            }
          } else {
            commit('set_status_count_objects', true);
            if (response.data) {
              if (params.loadMore) {
                commit('addOffset', response.data.length);
                commit('addObjects', response.data);
              } else {
                commit('resetOffset', response.data.length);
                commit('setObjects', response.data);
              }
              return true;
            } else if (response.data === null && !params.loadMore) {
              commit('setObjects', '');
            } else {

            }
          }
        } else if (response.data == null && !params.loadMore) {
          commit('setObjects', '');
        } else {

        }


      } catch (e) {
        console.log(e);
        return false;
      }
    },
  },
  modules: {}
})
