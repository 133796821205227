<template>
  <div class="loading">
    <img src="@/assets/img/loading.gif" />
  </div>

</template>

<script>
export default {
  components: {

  },
  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {
  },
  computed: {
  },
  watch: {},
}
</script>

<style>
  .loading {
    width: 100%;
    height: calc(90vh - 260px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }
</style>
