<template>

  <div class="menu_top_fixed" v-if="loading_menu">
    <div class="main_menu menu-top">
      <div class="menu-top__left-part">
        <div class="main_menu_elem elem_burger" @click="openModalMenu()">
          <div class="main_menu_elem_burger">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div @mouseover="upHere = true" @mouseleave="upHere = false"
             class="main_menu_elem img_logo menu-top__logo-wrapper"
             @click="openMainPageWithoutFilters()">
          <div class="logo_slider menu-top__logo">
            <TransitionGroup name="logo_slide">
              <div v-if="count_logo_slide === 1"><img src="../logo1.svg"></div>
              <div v-if="count_logo_slide === 2">
                <svg width="44" height="43" viewBox="0 0 44 43" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <use xlink:href='../sprite.svg#logo-slide-2'/>
                </svg>
              </div>
              <div v-if="count_logo_slide === 3">
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <use xlink:href='../sprite.svg#logo-slide-3'/>
                </svg>
              </div>
              <div v-if="count_logo_slide === 4">
                <svg width="41" height="44" viewBox="0 0 41 44" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <use xlink:href='../sprite.svg#logo-slide-4'/>
                </svg>
              </div>
              <div v-if="count_logo_slide === 5">
                <svg width="41" height="41" viewBox="0 0 41 41" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <use xlink:href='../sprite.svg#logo-slide-5'/>
                </svg>
              </div>
              <div v-if="count_logo_slide === 6">
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <use xlink:href='../sprite.svg#logo-slide-6'/>
                </svg>
              </div>
              <div v-if="count_logo_slide === 7">
                <svg width="43" height="43" viewBox="0 0 43 43" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <use xlink:href='../sprite.svg#logo-slide-7'/>
                </svg>
              </div>
              <div v-if="count_logo_slide === 8">
                <svg width="44" height="43" viewBox="0 0 44 43" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <use xlink:href='../sprite.svg#logo-slide-8'/>
                </svg>
              </div>
              <div v-if="count_logo_slide === 9">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <use xlink:href='../sprite.svg#logo-slide-9'/>
                </svg>
              </div>
            </TransitionGroup>
          </div>

          <span class="menu-top__text-logo">
            <img width="82" src="../logo-text.svg">
          </span>
        </div>

        <Transition name="slideTop">
          <div class="mobile_menu" v-if="mobile_menu">

            <svg class="mobile_menu_close" @click="this.mobile_menu = false" viewBox="0 0 32 32"
                 xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                 focusable="false"
                 style="display: block; fill: none; height: 24px; width: 24px; stroke: currentcolor; stroke-width: 3; overflow: visible;">
              <path d="m6 6 20 20"></path>
              <path d="m26 6-20 20"></path>
            </svg>

            <div class="mobile_menu_settings_wrapper">
              <div class="bottom_menu_row_elem" @click="$router.push(`/tariffs`)">Тарифы</div>
              <div class="bottom_menu_row_elem" @click="$router.push(`/about-us`)">О нас</div>
              <div class="main_menu_elem select_city" @click="modalSelectCity()">
                <span v-if="this.selected_city">{{ this.selected_city }}</span>
                <span v-if="this.selected_country && !this.selected_city">{{
                    this.selected_country
                  }}</span>
                <span v-if="!this.selected_country && !this.selected_city">Выберите город</span>
              </div>
              <div class="bottom_menu_row_elem settings_elems" v-if="!load_setting">
                <div
                  @click="openOneTypeModal('currency')">
                  {{ this.settings.currency_symbol }}
                </div>
                <div
                  @click="openOneTypeModal('unit')">
                  {{ this.settings.unit }}
                </div>
                <div
                  @click="openOneTypeModal('language')">
                  <img :src="`../img/country/${this.settings.language_img}`">
                </div>
              </div>
              <!--<div class="bottom_menu_row_elem" @click="$router.push(`/payment`)">
                Оплата
              </div>-->
            </div>

            <div class="mobile_menu_group">
              <div @click="setOneParam('sell_type', 2)">
                <span class="mobile_menu_item">Аренда</span>
              </div>

              <div>
                <div @click="setOneParam('sell_type', 3)">
                  <span class="mobile_menu_item">Продажа</span>
                </div>
              </div>

              <div>
                <div @click="setOneParam('sell_selling_type', 1)">
                  <span class="mobile_menu_item">Новостройки</span>
                </div>
              </div>

              <div v-if="checkAcceptedMenu('Дома и участки')">
                <div
                  @click="setOneParamAndTypeObject('sell_type', 0, [false, false, false, false, true, true, true, true, true, true])">
                  <span class="mobile_menu_item">Дома и участки</span>
                </div>
              </div>

              <div>
                <div @click="setOneParam('sell_type', 0, false)">
                  <span class="mobile_menu_item">Коммерческая</span>
                </div>
              </div>

              <div>
                <div @click="$router.push(`/services`)">
                  <span class="mobile_menu_item">Сервисы</span>
                </div>
              </div>
            </div>

            <div class="mobile_menu_group">
              <!--<div class="mobile_menu_item" @click="$router.push(`/bc`)">
                Бизнес Центры
              </div>
              <div class="mobile_menu_item" @click="$router.push(`/tc`)">
                Торговые центры
              </div>
              <div class="mobile_menu_item" @click="$router.push(`/zhk`)">
                Жилые комплексы
              </div>
              <div class="mobile_menu_item" @click="$router.push(`/developers`)">
                Девелоперы
              </div>
              <div class="mobile_menu_item" @click="$router.push(`/agents`)">
                Агенты
              </div>
              <div class="mobile_menu_item" @click="$router.push(`/agency`)">
                Агентства
              </div>-->
              <!--<div class="mobile_menu_item" @click="$router.push(`/guides`)">
                Гиды
              </div>
              <div class="mobile_menu_item" @click="$router.push(`/translators`)">
                Переводчики
              </div>!-->
              <div v-if="this.userInfo && this.userInfo.admin" class="mobile_menu_item"
                   @click="$router.push(`/moder`)">
                Модерация
              </div>
            </div>
            <div v-if="!this.userInfo" class="main_menu_elem menu-top__btn-login"
                 @click="this.$emit('changeStatusModalEntry', true)">
              Войти
            </div>
            <div v-else class="main_menu_elem menu-top__btn-login" @click="exit()">
              Выйти
            </div>

          </div>


        </Transition>

        <div class="menu-top__dropdown-wrapper">
          <div class="dropdown_1 menu-top__dropdown">
            <div class="main_menu_elem dropbtn_1 menu-top__dropbtn" @click="setOneParam('sell_type', 2)">
              <span>Аренда</span>
              <svg width='12' height='12' class='menu-top__icon'>
                <use xlink:href='../sprite.svg#arrow-down'/>
              </svg>
            </div>
            <div class="dropdown_content_1 dropdown_content">
              <span v-if="checkAcceptedMenu('Аренда', 'Длительная')"
                    @click="setTwoParam('sell_type', 2, 'time_limit', 2)">Длительная</span>
              <span v-if="checkAcceptedMenu('Аренда', 'Посуточная')"
                    @click="setTwoParam('sell_type', 2, 'time_limit', 1)">Посуточная</span>
              <span v-if="checkAcceptedMenu('Аренда', 'Квартиры')"
                    @click="setOneParamAndTypeObject('sell_type', 2, [false, true])">Квартиры</span>
              <span v-if="checkAcceptedMenu('Аренда', 'Апартаменты')"
                    @click="setOneParamAndTypeObject('sell_type', 2, [false, false, false, false, false, false, false, false, false, false, false, true])">Апартаменты</span>
              <span v-if="checkAcceptedMenu('Аренда', 'Комнаты')"
                    @click="setOneParamAndTypeObject('sell_type', 2, [false, false, true])">Комнаты</span>
              <span v-if="checkAcceptedMenu('Аренда', 'Дома и коттеджи')"
                    @click="setOneParamAndTypeObject('sell_type', 2, [false, false, false, false, true, true, true, true])">Дома и коттеджи</span>
              <span @click="checkForAuth()">Сдать</span>
            </div>
          </div>

          <div class="dropdown_2 menu-top__dropdown">
            <div class="main_menu_elem dropbtn_2 menu-top__dropbtn"
                 @click="setOneParam('sell_type', 3)">
              <span>Продажа</span>
              <svg width='12' height='12' class='menu-top__icon'>
                <use xlink:href='../sprite.svg#arrow-down'/>
              </svg>
            </div>
            <div class="dropdown_content_2 dropdown_content">
              <span v-if="checkAcceptedMenu('Продажа', 'Квартиры')"
                    @click="setOneParamAndTypeObject('sell_type', 3, [false, true])">Квартиры</span>
              <span v-if="checkAcceptedMenu('Продажа', 'Апартаменты')"
                    @click="setOneParamAndTypeObject('sell_type', 3, [false, false, false, false, false, false, false, false, false, false, false, true])">Апартаменты</span>
              <span v-if="checkAcceptedMenu('Продажа', 'Квартиры в новостройке')"
                    @click="setTwoParamAndTypeObject('sell_type', 3, 'sell_selling_type', 1, [false, true])">Квартиры в новостройке</span>
              <span v-if="checkAcceptedMenu('Продажа', 'Вторичная продажа')"
                    @click="setTwoParam('sell_type', 3, 'sell_selling_type', 2)">Вторичная продажа</span>
              <span v-if="checkAcceptedMenu('Продажа', 'Комнаты и доли')"
                    @click="setOneParamAndTypeObject('sell_type', 3, [false, false, false, true, true])">Комнаты и доли</span>
              <span v-if="checkAcceptedMenu('Продажа', 'Дома и коттеджи')"
                    @click="setOneParamAndTypeObject('sell_type', 3, [false, false, false, false, true, true, true, true])">Дома и коттеджи</span>
              <span @click="checkForAuth()">Продать</span>
            </div>
          </div>

          <div class="dropdown_3 menu-top__dropdown" v-if="checkAcceptedMenu('Новостройки')">
            <div class="main_menu_elem dropbtn_3 menu-top__dropbtn"
                 @click="setOneParam('sell_selling_type', 1)">
              <span>Новостройки</span>
              <svg width='12' height='12' class='menu-top__icon'>
                <use xlink:href='../sprite.svg#arrow-down'/>
              </svg>
            </div>
            <div class="dropdown_content_3 dropdown_content" v-if="checkAcceptedMenu('Новостройки', 'Квартиры') || checkAcceptedMenu('Новостройки', 'Апартаменты')">
              <span v-if="checkAcceptedMenu('Новостройки', 'Квартиры')"
                    @click="setTwoParamAndTypeObject('sell_type', 0, 'sell_selling_type', 1, [false, true])">Квартиры</span>
              <span @click="$router.push(`/zhk`)">Жилые комплексы</span>
              <!--<span @click="$router.push(`/`)">Каталог коттеджных посёлков</span>-->
              <span v-if="checkAcceptedMenu('Новостройки', 'Апартаменты')"
                    @click=" setTwoParamAndTypeObject('sell_type', 0, 'sell_selling_type', 1, [false, false, false, false, false, false, false, false, false, false, false, true], 0)">Апартаменты</span>
            </div>
          </div>

          <div class="dropdown_4 menu-top__dropdown" v-if="checkAcceptedMenu('Дома и участки')">
            <div class="main_menu_elem dropbtn_4 menu-top__dropbtn"
                 @click="setOneParamAndTypeObject('sell_type', 0, [false, false, false, false, true, true, true, true, true, true])">
              <span>Дома и участки</span>
              <svg width='12' height='12' class='menu-top__icon'>
                <use xlink:href='../sprite.svg#arrow-down'/>
              </svg>
            </div>
            <div class="dropdown_content_4 dropdown_content">
              <span v-if="checkAcceptedMenu('Дома и участки', 'Аренда')"
                    @click="setOneParamAndTypeObject('sell_type', 2, [false, false, false, false, true, true, true, true, true, true])">Аренда</span>
              <span v-if="checkAcceptedMenu('Дома и участки', 'Продажа домов и дач')"
                    @click="setOneParamAndTypeObject('sell_type', 3, [false, false, false, false, false, true, true, true, true, true])">Продажа домов и дач</span>
              <span v-if="checkAcceptedMenu('Дома и участки', 'Продажа участков')" @click="setOneParamAndTypeObject('sell_type', 3, [false, false, false, false, false, false, false, false, false, true])">Продажа участков</span>

              <!--<span v-if="checkAcceptedMenu('Жилые Комплексы')" @click="$router.push(`/zhk`)">Жилые комплексы</span>-->
              <span v-if="checkAcceptedMenu('Переводчики')" @click="$router.push(`/translators`)">Переводчики</span>
              <!--<span v-if="checkAcceptedMenu('Торговые Центры')" @click="$router.push(`/tc`)">Торговые центры</span>
              <span v-if="checkAcceptedMenu('Склады')" @click="$router.push(`/storage`)">Склады</span>
              <span v-if="checkAcceptedMenu('ГИДЫ')" @click="$router.push(`/guides`)">ГИДЫ</span>-->
              <!--<span v-if="checkAcceptedMenu('Девелоперы')" @click="$router.push(`/developers`)">Девелоперы</span>-->
              <span v-if="checkAcceptedMenu('Коттеджные Посёлки')" @click="$router.push(`/cottages`)">Коттеджные поселки</span>
              <!--<span v-if="checkAcceptedMenu('Бизнес Центры')" @click="$router.push(`/bc`)">Бизнес центры</span>
              <span v-if="checkAcceptedMenu('Агенты')" @click="$router.push(`/agents`)">Агенты</span>
              <span v-if="checkAcceptedMenu('Агентства')" @click="$router.push(`/agency`)">Агентства</span>-->


            </div>
          </div>

          <div class="dropdown_6 menu-top__dropdown" v-if="checkAcceptedMenu('Коммерческая')">
            <div class="main_menu_elem dropbtn_6 menu-top__dropbtn"
                 @click="setOneParam('sell_type', 0, false)">
              <span>Коммерческая</span>
              <svg width='12' height='12' class='menu-top__icon'>
                <use xlink:href='../sprite.svg#arrow-down'/>
              </svg>
            </div>
            <div class="dropdown_content_6 dropdown_content">
              <div>
                <span @click="setOneParam('sell_type', 2, false)"
                      v-if="checkAcceptedMenu('Коммерческая', 'Аренда')"
                      class="dropdown_content_header">Аренда</span>
                <span v-if="checkAcceptedMenu('Коммерческая', 'Аренда', 'Офиса')"
                      @click="setOneParamAndTypeObject('sell_type', 2, [false, true], false)">Офиса</span>
                <span v-if="checkAcceptedMenu('Коммерческая', 'Аренда', 'Коворкинга')"
                      @click="setOneParamAndTypeObject('sell_type', 2, [false, false,false,false,false,false,false,false,true], false)">Коворкинга</span>
                <span v-if="checkAcceptedMenu('Коммерческая', 'Аренда', 'Торговой площади')"
                      @click="setOneParamAndTypeObject('sell_type', 2, [false, false,false,true], false)">Торговой площади</span>
                <span v-if="checkAcceptedMenu('Коммерческая', 'Аренда', 'Складского помещения')"
                      @click="setOneParamAndTypeObject('sell_type', 2, [false, false,false,false,false,false,true], false)">Складского помещения</span>
              </div>
              <div>
                <span @click="setOneParam('sell_type', 3, false)"
                      v-if="checkAcceptedMenu('Коммерческая', 'Продажа')"
                      class="dropdown_content_header">Продажа</span>
                <span v-if="checkAcceptedMenu('Коммерческая', 'Продажа', 'Офиса')"
                      @click="setOneParamAndTypeObject('sell_type', 3, [false, true], false)">Офиса</span>
                <span v-if="checkAcceptedMenu('Коммерческая', 'Продажа', 'Торговой площади')"
                      @click="setOneParamAndTypeObject('sell_type', 3, [false, false,false,true], false)">Торговой площади</span>
                <span v-if="checkAcceptedMenu('Коммерческая', 'Продажа', 'Складского помещения')"
                      @click="setOneParamAndTypeObject('sell_type', 3, [false, false,false,false,false,false,true], false)">Складского помещения</span>
                <span v-if="checkAcceptedMenu('Коммерческая', 'Продажа', 'Бизнеса')"
                      @click="setOneParamAndTypeObject('sell_type', 3, [false, false,false,false,false,false,false,false,true], false)">Бизнеса</span>
              </div>
              <div>
                <span @click="$router.push(`/bc`)">Бизнес-центры</span>
                <span @click="$router.push(`/tc`)">Торговые центры</span>
                <span @click="$router.push(`/storage`)">Складские комплексы</span>
              </div>
            </div>
          </div>

          <div class="dropdown_5 menu-top__dropdown">
            <div class="main_menu_elem dropbtn_5 menu-top__dropbtn" @click="$router.push(`/services`)">
              <span>Сервисы</span>
              <svg width='12' height='12' class='menu-top__icon'>
                <use xlink:href='../sprite.svg#arrow-down'/>
              </svg>
            </div>
            <div class="dropdown_content_5 dropdown_content">
              <span v-if="checkAcceptedMenu('Девелоперы')" @click="$router.push(`/developers`)">Девелоперы</span>
              <span v-if="checkAcceptedMenu('ГИДЫ')" @click="$router.push(`/guides`)">ГИДЫ</span>
              <span v-if="checkAcceptedMenu('Агенты')" @click="$router.push(`/agents`)">Агенты</span>
              <span v-if="checkAcceptedMenu('Агентства')" @click="$router.push(`/agency`)">Агентства</span>
              <span @click="$router.push(`/services/mortgage`)">Ипотека</span>
              <span @click="$router.push(`/services/estimation`)">Оценка</span>
              <span @click="$router.push(`/services/select-realtor`)">Подбор риелтора</span>
              <span @click="$router.push(`/services/save-or-buy`)">Копить или купить</span>
              <span @click="$router.push(`/services/stocks`)">Акции</span>
              <span @click="$router.push(`/services/exchange`)">Обмен квартиры</span>
              <span @click="$router.push(`/services/state-support`)">Господдержка</span>
              <span @click="$router.push(`/services/analytics`)">Аналитика бизнесу</span>
              <span @click="$router.push(`/services/check`)">Проверка недвижимости</span>
              <span @click="$router.push(`/services/selection`)">Подобрать новостройку</span>
              <span @click="$router.push(`/services/select-designer`)">Подбор дизайнера</span>
              <span @click="$router.push(`/services/purchase`)">Удалённая покупка</span>
            </div>
          </div>
          <div class="dropdown_7 menu-top__dropdown">
            <div class="main_menu_elem dropbtn_7 menu-top__dropbtn">
              <span>Еще</span>
              <svg width='12' height='12' class='menu-top__icon'>
                <use xlink:href='../sprite.svg#arrow-down'/>
              </svg>
            </div>
            <div class="dropdown_content_7 dropdown_content">
              <div class="dropdown_content_wrapper" v-if="checkAcceptedMenu('Дома и участки')">
                <div class="main_menu_elem dropbtn_4 menu-top__dropbtn"
                     @click="setOneParamAndTypeObject('sell_type', 0, [false, false, false, false, true, true, true, true, true, true])">
                  <span class="dropdown_content__title">Дома и участки</span>
                </div>
                <div class="dropdown_content_list">
                  <span v-if="checkAcceptedMenu('Дома и участки', 'Аренда')"
                        @click="setOneParamAndTypeObject('sell_type', 2, [false, false, false, false, true, true, true, true, true, true])">Аренда</span>
                  <span v-if="checkAcceptedMenu('Дома и участки', 'Продажа домов и дач')"
                        @click="setOneParamAndTypeObject('sell_type', 3, [false, false, false, false, false, true, true, true, true, true])">Продажа домов и дач</span>
                  <span v-if="checkAcceptedMenu('Дома и участки', 'Продажа участков')"
                        @click="setOneParamAndTypeObject('sell_type', 3, [false, false, false, false, false, false, false, false, false, true])">Продажа участков</span>
                </div>
              </div>
              <div class="dropdown_content_wrapper">
                <div class="main_menu_elem dropbtn_6 menu-top__dropbtn"
                     v-if="checkAcceptedMenu('Коммерческая')"
                     @click="setOneParam('sell_type', 0, false)">
                  <span class="dropdown_content__title">Коммерческая</span>
                </div>
                <div class="dropdown_content_list">
                  <div>
                    <span @click="setOneParam('sell_type', 2, false)"
                          v-if="checkAcceptedMenu('Коммерческая', 'Аренда')"
                          class="dropdown_content_header">Аренда</span>
                    <span v-if="checkAcceptedMenu('Коммерческая', 'Аренда', 'Офиса')"
                          @click="setOneParamAndTypeObject('sell_type', 2, [false, true], false)">Офиса</span>
                    <span v-if="checkAcceptedMenu('Коммерческая', 'Аренда', 'Коворкинга')"
                          @click="setOneParamAndTypeObject('sell_type', 2, [false, false,false,false,false,false,false,false,true], false)">Коворкинга</span>
                    <span v-if="checkAcceptedMenu('Коммерческая', 'Аренда', 'Торговой площади')"
                          @click="setOneParamAndTypeObject('sell_type', 2, [false, false,false,true], false)">Торговой площади</span>
                    <span v-if="checkAcceptedMenu('Коммерческая', 'Аренда', 'Складского помещения')"
                          @click="setOneParamAndTypeObject('sell_type', 2, [false, false,false,false,false,false,true], false)">Складского помещения</span>
                  </div>
                  <div>
                    <span @click="setOneParam('sell_type', 3, false)"
                          v-if="checkAcceptedMenu('Коммерческая', 'Продажа')"
                          class="dropdown_content_header">Продажа</span>
                    <span v-if="checkAcceptedMenu('Коммерческая', 'Продажа', 'Офиса')"
                          @click="setOneParamAndTypeObject('sell_type', 3, [false, true], false)">Офиса</span>
                    <span v-if="checkAcceptedMenu('Коммерческая', 'Продажа', 'Торговой площади')"
                          @click="setOneParamAndTypeObject('sell_type', 3, [false, false,false,true], false)">Торговой площади</span>
                    <span v-if="checkAcceptedMenu('Коммерческая', 'Продажа', 'Складского помещения')"
                          @click="setOneParamAndTypeObject('sell_type', 3, [false, false,false,false,false,false,true], false)">Складского помещения</span>
                    <span v-if="checkAcceptedMenu('Коммерческая', 'Продажа', 'Бизнеса')"
                          @click="setOneParamAndTypeObject('sell_type', 3, [false, false,false,false,false,false,false,false,true], false)">Бизнеса</span>
                  </div>
                  <!--<div>
                    <span @click="$router.push(`/bc`)">Бизнес-центры</span>
                    <span @click="$router.push(`/tc`)">Торговые центры</span>
                    <span>Складские комплексы</span>
                  </div>-->
                </div>
              </div>
              <div class="dropdown_content_wrapper">
                <div class="main_menu_elem dropbtn_5 menu-top__dropbtn"
                     @click="$router.push(`/services`)">
                  <span class="dropdown_content__title">Сервисы</span>
                </div>
                <div class="dropdown_content_list">
                  <span @click="$router.push(`/services/mortgage`)">Ипотека</span>
                  <span @click="$router.push(`/services/estimation`)">Оценка</span>
                  <span @click="$router.push(`/services/select-realtor`)">Подбор риелтора</span>
                  <span @click="$router.push(`/services/save-or-buy`)">Копить или купить</span>
                  <span @click="$router.push(`/services/stocks`)">Акции</span>
                  <span @click="$router.push(`/services/exchange`)">Обмен квартиры</span>
                  <span @click="$router.push(`/services/state-support`)">Господдержка</span>
                  <span @click="$router.push(`/services/analytics`)">Аналитика бизнесу</span>
                  <span @click="$router.push(`/services/check`)">Проверка недвижимости</span>
                  <span @click="$router.push(`/services/selection`)">Подобрать новостройку</span>
                  <span @click="$router.push(`/services/select-designer`)">Подбор дизайнера</span>
                  <span @click="$router.push(`/services/purchase`)">Удалённая покупка</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="menu-top__right-part">

        <button class="main_menu_elem menu-top__btn" @click="$router.push(`/favorites`)"
                aria-label="Избранное">
          <svg width='16' height='16' class='panorama-menu__icon close-icon'>
            <use xlink:href='../sprite.svg#favorite'/>
          </svg>
        </button>
        <button class="main_menu_elem menu-top__btn" @click="$router.push(`/comparison`)"
                aria-label="Сравнения">
          <svg width='16' height='16' class='panorama-menu__icon close-icon'>
            <use xlink:href='../sprite.svg#compare'/>
          </svg>
        </button>

        <div class="main_menu_elem menu-top__btn menu-top__btn--lang">
          <div class="language_selected" @click="changeBool('modal_settings')">
            <svg height="28px" width="28px">
              <use xlink:href='../sprite.svg#lang'/>
            </svg>
          </div>
        </div>

        <div class="menu-top__user-wrapper menu-top__btn menu-top__btn--profile" v-if="this.userInfo">
          <div class="menu-top__user-btn" @click="$router.push(`/account`)">
            <svg class="main_menu_interaction_login_user menu-top__user-icon"
                 xmlns="http://www.w3.org/2000/svg"
                 aria-hidden="true"
                 focusable="false"
            >
              <use xlink:href='../sprite.svg#user'/>
            </svg>
          </div>
        </div>

        <div class="main_menu_elem menu-top__btn-create" @click="checkForAuth()">
          Создать <span class="menu-top__btn-create-full">объявление</span>
        </div>

        <div v-if="!this.userInfo" class="main_menu_elem menu-top__btn-login"
             @click="this.$emit('changeStatusModalEntry', true)">
          Войти
        </div>
        <div v-else class="main_menu_elem menu-top__btn-login" @click="exit()">
          Выйти
        </div>
      </div>

    </div>
    <div class="dop_row_main-menu" v-if="this.status_count_objects">
      <div class="catalog_main-block_filters_second-row">
        <div class="catalog_main-block_filters_second-row_select">
          <div class="catalog_main-block_filters_second-row_select_wrapper">
            <div class="catalog_main-block_filters_second-row_select_elem"
                 @mouseover="select_type_selling = 1" @mouseleave="select_type_selling = 0">
              <span v-if="this.filters.sell_type == 2">Аренда</span>
              <span v-if="this.filters.sell_type == 3">Продажа</span>
              <span v-if="this.filters.sell_type == 0">Тип сделки</span>
              <svg width='12' height='12' class='menu-top__icon'>
                <use xlink:href='../sprite.svg#arrow-down'/>
              </svg>
              <div class="catalog_main-block_filters_second-row_modal" v-if="select_type_selling == 1"
                   @click.stop @mouseover="select_type_selling = 1"
                   @mouseleave="select_type_selling = 0">
                <span class="select_item" :class="{active_param: this.filters.sell_type == 3}"
                      @click="this.filters.sell_type = 3; changeBool('select_type_selling', 1)">Продажа</span>
                <span class="select_item" :class="{active_param: this.filters.sell_type == 2}"
                      @click="this.filters.sell_type = 2; changeBool('select_type_selling', 1)">Аренда</span>
              </div>
            </div>
            <div class="catalog_main-block_filters_second-row_select_elem"
                 :class="{disabled: this.filters.sell_type == 0}"
                 @mouseover="select_first_filter = 1" @mouseleave="select_first_filter = 0"
            >
              <span v-if="this.filters.type_property == 1">Жилая</span>
              <span v-if="this.filters.type_property == 2">Коммерческая</span>
              <span v-if="this.filters.type_property == 0">Тип недвиж.</span>
              <svg width='12' height='12' class='menu-top__icon'>
                <use xlink:href='../sprite.svg#arrow-down'/>
              </svg>
              <div class="catalog_main-block_filters_second-row_modal" v-if="select_first_filter == 1"
                   @mouseover="select_first_filter = 1" @mouseleave="select_first_filter = 0">
                <div class="filter_toggle_type_object">
                  <span @click="this.filters.type_property = 1"
                        :class="{active_filter: this.filters.type_property == 1}"
                        class="filter_toggle_type_item">Жилая</span>
                  <span @click="this.filters.type_property = 2"
                        :class="{active_filter: this.filters.type_property == 2}"
                        class="filter_toggle_type_item">Коммерческая</span>
                </div>

                <div class="filters_group"
                     v-if="this.filters.sell_type == 0">
                  <div>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Жилая', 'Квартиры')"
                                       :label="'Квартира'" :value="1"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Жилая', 'Апартаменты')"
                                       :label="'Апартаменты'" :value="11"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Жилая', 'Комната')"
                                       :label="'Комната'" :value="2"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Жилая', 'Койко-место')"
                                       :label="'Койко-место'" :value="3"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Жилая', 'Дом/Дача')"
                                       :label="'Дом/Дача'" :value="4"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Жилая', 'Коттедж')"
                                       :label="'Коттедж'" :value="5"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Жилая', 'Таунхаус')"
                                       :label="'Таунхаус'" :value="6"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Жилая', 'Часть дома')"
                                       :label="'Часть дома'" :value="7"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Жилая', 'Земельный участок')"
                                       :label="'Земельный участок'" :value="9"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                  </div>
                </div>

                <div class="filters_group"
                     v-if="this.filters.type_property == 1 && this.filters.sell_type == 2">
                  <div>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Жилая', 'Квартиры')"
                                       :label="'Квартира'" :value="1"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Жилая', 'Апартаменты')"
                                       :label="'Апартаменты'" :value="11"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Жилая', 'Комната')"
                                       :label="'Комната'" :value="2"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Жилая', 'Койко-место')"
                                       :label="'Койко-место'" :value="3"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Жилая', 'Дом/Дача')"
                                       :label="'Дом/Дача'" :value="4"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Жилая', 'Коттедж')"
                                       :label="'Коттедж'" :value="5"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Жилая','Таунхаус')"
                                       :label="'Таунхаус'" :value="6"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Жилая', 'Часть дома')"
                                       :label="'Часть дома'" :value="7"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Жилая', 'Земельный участок')"
                                       :label="'Земельный участок'" :value="9"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                  </div>
                </div>

                <div v-if="this.filters.type_property == 2 && this.filters.sell_type == 2">
                  <div>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Коммерческая', 'Офис')"
                                       :label="'Офис'" :value="1"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Коммерческая', 'Апартаменты')"
                                       :label="'Апартаменты'" :value="11"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Коммерческая', 'Здание')"
                                       :label="'Здание'" :value="2"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox
                      v-if="checkAcceptedMenu('Аренда', 'Коммерческая', 'Торговая площадь')"
                      :label="'Торговая площадь'" :value="3"
                      :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox
                      v-if="checkAcceptedMenu('Аренда', 'Коммерческая', 'Помещение свободного назначения')"
                      :label="'Помещение свободного назначения'" :value="4"
                      :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Коммерческая', 'Производство')"
                                       :label="'Производство'" :value="5"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Коммерческая','Склад')"
                                       :label="'Склад'" :value="6"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Коммерческая','Гараж')"
                                       :label="'Гараж'" :value="7"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Аренда', 'Коммерческая','Коворкинг')"
                                       :label="'Коворкинг'" :value="8"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox
                      v-if="checkAcceptedMenu('Аренда', 'Коммерческая', 'Коммерческая земля')"
                      :label="'Коммерческая земля'" :value="9"
                      :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                  </div>
                </div>

                <div class="filters_group"
                     v-if="this.filters.type_property == 1 && this.filters.sell_type == 3">
                  <div>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Продажа', 'Жилая', 'Квартиры')"
                                       :label="'Квартира'" :value="1"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Продажа', 'Жилая', 'Апартаменты')"
                                       :label="'Апартаменты'" :value="11"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Продажа', 'Жилая', 'Комната')"
                                       :label="'Комната'" :value="3"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Продажа', 'Жилая', 'Доля в квартире')"
                                       :label="'Доля в квартире'" :value="4"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Продажа', 'Жилая', 'Коттедж')"
                                       :label="'Коттедж'" :value="6"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Продажа', 'Жилая', 'Таунхаус')"
                                       :label="'Таунхаус'" :value="7"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Продажа', 'Жилая','Дом/Дача')"
                                       :label="'Дом/Дача'" :value="5"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Продажа', 'Жилая', 'Часть дома')"
                                       :label="'Часть дома'" :value="8"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Продажа', 'Жилая', 'Земельный участок')"
                                       :label="'Земельный участок'" :value="9"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                  </div>
                </div>

                <div v-if="this.filters.type_property == 2 && this.filters.sell_type == 3">
                  <div>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Продажа', 'Коммерческая', 'Офис')"
                                       :label="'Офис'" :value="1"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Продажа', 'Коммерческая', 'Апартаменты')"
                                       :label="'Апартаменты'" :value="11"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Продажа', 'Коммерческая', 'Здание')"
                                       :label="'Здание'" :value="2"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox
                      v-if="checkAcceptedMenu('Продажа', 'Коммерческая', 'Торговая площадь')"
                      :label="'Торговая площадь'" :value="3"
                      :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox
                      v-if="checkAcceptedMenu('Продажа', 'Коммерческая', 'Помещение свободного назначения')"
                      :label="'Помещение свободного назначения'" :value="4"
                      :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox
                      v-if="checkAcceptedMenu('Продажа', 'Коммерческая', 'Производство')"
                      :label="'Производство'" :value="5"
                      :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Продажа', 'Коммерческая', 'Склад')"
                                       :label="'Склад'" :value="6"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Продажа', 'Коммерческая', 'Гараж')"
                                       :label="'Гараж'" :value="7"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox
                      v-if="checkAcceptedMenu('Продажа', 'Коммерческая', 'Коммерческая земля')"
                      :label="'Коммерческая земля'" :value="9"
                      :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                    <MainLabelCheckBox v-if="checkAcceptedMenu('Продажа', 'Коммерческая', 'Бизнес')"
                                       :label="'Бизнес'" :value="8"
                                       :statusChecked="this.filters_main_block"></MainLabelCheckBox>
                  </div>
                </div>

              </div>
            </div>
            <div class="catalog_main-block_filters_second-row_select_elem"
                 @mouseover="select_first_filter = 2" @mouseleave="select_first_filter = 0"
            >
              Комнат
              <svg width='12' height='12' class='menu-top__icon'>
                <use xlink:href='../sprite.svg#arrow-down'/>
              </svg>
              <div class="catalog_main-block_filters_second-row_modal" v-if="select_first_filter == 2"
                   @mouseover="select_first_filter = 2" @mouseleave="select_first_filter = 0">

                <div class="select_rooms_wrapper">
                  <div class="select_rooms" v-for="(rooms, index) in 6" @click="changeStatusRoom(rooms)"
                       :class="{active_rooms: this.filter_count_rooms[rooms]}">
                    <span v-if="rooms == 6">6+</span>
                    <span v-else>{{ rooms }}</span>
                  </div>
                </div>
                <div class="select_checkbox_wrapper">
                  <MainLabelCheckBox :label="'Студия'" :value="7"
                                     :statusChecked="this.filter_count_rooms"></MainLabelCheckBox>
                  <MainLabelCheckBox :label="'Свободная планировка'" :value="8"
                                     :statusChecked="this.filter_count_rooms"></MainLabelCheckBox>
                </div>
              </div>
            </div>
            <div class="catalog_main-block_filters_second-row_select_elem"
                 @mouseover="select_first_filter = 3" @mouseleave="select_first_filter = 0">
              <span
                v-if="this.filters.price_from && this.filters.price_to">{{
                  this.filters.price_from
                }} - {{ this.filters.price_to }}</span>
              <span v-else-if="this.filters.price_from">От {{ this.filters.price_from }}</span>
              <span v-else-if="this.filters.price_to">До {{ this.filters.price_to }}</span>
              <span v-else>Цена</span>
              <div class="catalog_main-block_filters_second-row_modal filters-number-group"
                   v-if="select_first_filter == 3" @mouseover="select_first_filter = 3"
                   @mouseleave="select_first_filter = 0">
                <MainInputNumber :placeholder="'От'" v-model="this.filters.price_from"></MainInputNumber>
                <MainInputNumber :placeholder="'До'" v-model="this.filters.price_to"></MainInputNumber>
              </div>
            </div>
          </div>
          <div class="catalog_main-block_filters_second-row_search search">
            <div class="menu_mobile search">
              <div class="menu_mobile_search_icon">
                <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                     focusable="false"
                     style="display: block; height: 16px; width: 16px; fill: currentcolor;">
                  <path
                    d="M13 0c7.18 0 13 5.82 13 13 0 2.868-.929 5.519-2.502 7.669l7.916 7.917-2.828 2.828-7.917-7.916A12.942 12.942 0 0 1 13 26C5.82 26 0 20.18 0 13S5.82 0 13 0zm0 4a9 9 0 1 0 0 18 9 9 0 0 0 0-18z"
                    opacity=".8"></path>
                </svg>
              </div>

              <div class="menu_mobile_text">
                <form>
                  <input class="input_search_full" autocomplete="nope" type="text" v-model="search_query"
                         placeholder="Город, адрес, метро, район, шоссе или ЖК">
                  <input class="input_search_mobile" autocomplete="nope" type="text"
                         v-model="search_query"
                         placeholder="Хочу найти">
                </form>
                <!--<div class="menu_mobile_settings" @click="this.changeStatusFiltersModal(true)">
                  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                       focusable="false"
                       style="display: block; height: 16px; width: 16px; fill: rgb(34, 34, 34);">
                    <path
                      d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                  </svg>
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="catalog_main-block_filters_btns">
        <MainButton @click="openMainPage()">Найти</MainButton>
        <span class="btn-group__wrapper">
          <MainButton v-if="!this.status_map_catalog" @click="showOnMap()">Показать на карте</MainButton>
          <MainButton v-if="this.status_map_catalog" @click="showOnMap()">Показать списком</MainButton>
        </span>
      </div>
    </div>
    <div class="modal_select_city_back" v-if="modal_settings">
      <div class="modal_select_city">
        <div class="modal_select_city_header">
          <div class="modal_header_tabs" v-if="!modal_type_one">
            <span @click="active_tab_setting = 'language'"
                  :class="{active_tab_setting: active_tab_setting === 'language'}">Язык</span>
            <span @click="active_tab_setting = 'currency'"
                  :class="{active_tab_setting: active_tab_setting === 'currency'}">Валюта</span>
            <span @click="active_tab_setting = 'unit'"
                  :class="{active_tab_setting: active_tab_setting === 'unit'}">Единицы измерения</span>
          </div>
          <svg class="modal_select_city_header_close" @click="this.modal_settings = false;"
               viewBox="0 0 32 32"
               xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
               focusable="false"
               style="display: block; fill: none; height: 24px; width: 24px; stroke: currentcolor; stroke-width: 3; overflow: visible;">
            <path d="m6 6 20 20"></path>
            <path d="m26 6-20 20"></path>
          </svg>
        </div>

        <div class="modal_settings_content"
             v-if="active_tab_setting === 'language' || modal_type_one === 'language'">
          <ul class="language_selected_list">
            <!--<li class="ul-lang__li"><a class="ul-lang__a"
                                       @click="changeLanguage('ES', 'esp-border-rad.svg', 'es_ES')"
                                       title="Espanol"
                                       rel="alternate">
              <img class="ul-lang__icon-wrap" alt="Espanol"
                   src="/img/country/esp-border-rad.svg">
              <p class="ul-lang__name">Español</p>
            </a></li>
            <li class="ul-lang__li"><a class="ul-lang__a"
                                       @click="changeLanguage('FR', 'fra-border-rad.svg', 'fr_FR')"
                                       title="Français"
                                       rel="alternate">
              <img class="ul-lang__icon-wrap" alt="Français"
                   src="/img/country/fra-border-rad.svg">
              <p class="ul-lang__name">Français</p>
            </a></li>-->
            <li class="ul-lang__li"><a class="ul-lang__a"
                                       @click="changeLanguage('RU', 'rus-border-rad.svg', 'ru_RU')"
                                       title="Russian"
                                       rel="alternate">
              <img class="ul-lang__icon-wrap" alt="Russian"
                   src="/img/country/rus-border-rad.svg">
              <p class="ul-lang__name">Русский</p>
            </a></li>
            <!--<li class="ul-lang__li"><a class="ul-lang__a"
                                       @click="changeLanguage('UZ', 'uzb-border-rad.svg', 'uz_UZ')"
                                       title="Uzbek"
                                       rel="alternate">
              <img class="ul-lang__icon-wrap" alt="Uzbek"
                   src="/img/country/uzb-border-rad.svg">
              <p class="ul-lang__name">O'zbek</p>
            </a></li>
            <li class="ul-lang__li"><a class="ul-lang__a"
                                       @click="changeLanguage('US', 'usa-border-rad.svg', 'en_US')"
                                       title="English"
                                       rel="alternate">
              <img class="ul-lang__icon-wrap" alt="English"
                   src="/img/country/usa-border-rad.svg">
              <p class="ul-lang__name">English</p>
            </a></li>-->
            <!--<li class="ul-lang__li"><a class="ul-lang__a"
                                       @click="changeLanguage('CN', 'chn-border-rad.svg', 'zh_CN')"
                                       title="Chinese"
                                       rel="alternate">
              <img class="ul-lang__icon-wrap" alt="Chinese"
                   src="/img/country/chn-border-rad.svg">
              <p class="ul-lang__name">中文</p>
            </a></li>
            <li class="ul-lang__li"><a class="ul-lang__a"
                                       @click="changeLanguage('JP', 'jpn-border-rad.svg', 'ja_JP')"
                                       title="Japanese"
                                       rel="alternate">
              <img class="ul-lang__icon-wrap" alt="Japanese"
                   src="/img/country/jpn-border-rad.svg">
              <p class="ul-lang__name">日本語</p>
            </a></li>
            <li class="ul-lang__li"><a class="ul-lang__a"
                                       @click="changeLanguage('AE', 'are-border-rad.svg', 'ar_AE')"
                                       title="Arabic"
                                       rel="alternate">
              <img class="ul-lang__icon-wrap" alt="Arabic"
                   src="/img/country/are-border-rad.svg">
              <p class="ul-lang__name">العربية</p>
            </a></li>
            <li class="ul-lang__li"><a class="ul-lang__a"
                                       @click="changeLanguage('DE', 'deu-border-rad.svg', 'de_DE')"
                                       title="Deutsch"
                                       rel="alternate">
              <img class="ul-lang__icon-wrap" alt="Deutsch"
                   src="/img/country/deu-border-rad.svg">
              <p class="ul-lang__name">Deutsch</p>
            </a></li>
            <li class="ul-lang__li"><a class="ul-lang__a"
                                       @click="changeLanguage('IN', 'ind-border-rad.svg', 'hi_IN')"
                                       title="Hindi"
                                       rel="alternate">
              <img class="ul-lang__icon-wrap" alt="Hindi"
                   src="/img/country/ind-border-rad.svg">
              <p class="ul-lang__name">हिन्दी</p>
            </a></li>
            <li class="ul-lang__li"><a class="ul-lang__a"
                                       @click="changeLanguage('MY', 'mys-border-rad.svg', 'ms_MY')"
                                       title="Malay"
                                       rel="alternate">
              <img class="ul-lang__icon-wrap" alt="Malay"
                   src="/img/country/mys-border-rad.svg">
              <p class="ul-lang__name">Melayu</p>
            </a></li>
            <li class="ul-lang__li"><a class="ul-lang__a"
                                       @click="changeLanguage('PT', 'prt-border-rad.svg', 'pt_PT')"
                                       title="Portuguese"
                                       rel="alternate">
              <img class="ul-lang__icon-wrap" alt="Portuguese"
                   src="/img/country/prt-border-rad.svg">
              <p class="ul-lang__name">Português</p>
            </a></li>
            <li class="ul-lang__li"><a class="ul-lang__a"
                                       @click="changeLanguage('IL', 'isr-border-rad.svg', 'he_IL')"
                                       title="Hebrew"
                                       rel="alternate">
              <img class="ul-lang__icon-wrap" alt="Hebrew"
                   src="/img/country/isr-border-rad.svg">
              <p class="ul-lang__name">עברית</p>
            </a></li>
            <li class="ul-lang__li"><a class="ul-lang__a"
                                       @click="changeLanguage('VN', 'vnm-border-rad.svg', 'vi_VN')"
                                       title="Vietnamese"
                                       rel="alternate">
              <img class="ul-lang__icon-wrap" alt="Vietnamese"
                   src="/img/country/vnm-border-rad.svg">
              <p class="ul-lang__name">Tiếng Việt</p>
            </a></li>
            <li class="ul-lang__li"><a class="ul-lang__a"
                                       @click="changeLanguage('H', 'hkg-border-rad.svg', 'yue_H')"
                                       title="Yue Chinese"
                                       rel="alternate">
              <img class="ul-lang__icon-wrap" alt="Yue Chinese"
                   src="/img/country/hkg-border-rad.svg">
              <p class="ul-lang__name">粵語</p>
            </a></li>
            <li class="ul-lang__li"><a class="ul-lang__a"
                                       @click="changeLanguage('KR', 'kor-border-rad.svg', 'ko_KR')"
                                       title="Korean"
                                       rel="alternate">
              <img class="ul-lang__icon-wrap" alt="Korean"
                   src="/img/country/kor-border-rad.svg">
              <p class="ul-lang__name">한국인</p>
            </a></li>
            <li class="ul-lang__li"><a class="ul-lang__a"
                                       @click="changeLanguage('TR', 'tur-border-rad.svg', 'tr_TR')"
                                       title="Turkish"
                                       rel="alternate">
              <img class="ul-lang__icon-wrap" alt="Turkish"
                   src="/img/country/tur-border-rad.svg">
              <p class="ul-lang__name">Türk</p>
            </a></li>!-->
          </ul>
        </div>
        <div class="modal_settings_content"
             v-if="active_tab_setting === 'currency' || modal_type_one === 'currency'">
          <div class="language_selected_list">
            <div class="language_selected_list_elem" v-for="(elem, index) in this.list_of_currency"
                 @click="select_currency(elem)">
              <span>{{ elem.name }}</span>
              <span>{{ elem.currency }} – {{ elem.symbol }}</span>
            </div>
          </div>
        </div>
        <div class="modal_settings_content"
             v-if="active_tab_setting === 'unit' || modal_type_one === 'unit'">
          <div class="language_selected_list">
            <div class="language_selected_list_elem" v-for="(elem, index) in list_of_unit"
                 @click="select_unit(elem)">
              <span>{{ elem }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal_select_city_back" v-if="this.modal_list_district">
      <div class="modal_select_city">
        <div class="modal_select_city_header">
          <h2>Выберите район</h2>
          <svg class="modal_select_city_header_close"
               @click="this.set_modal_list_district(false); this.set_status_modal_filters_region(false);"
               viewBox="0 0 32 32"
               xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
               focusable="false"
               style="display: block; fill: none; height: 24px; width: 24px; stroke: currentcolor; stroke-width: 3; overflow: visible;">
            <path d="m6 6 20 20"></path>
            <path d="m26 6-20 20"></path>
          </svg>
        </div>

        <div class="modal_select_city_header_list_filters" v-if="district_only_city">
          <div v-if="this.status_modal_filters_region && popular_district_city.length > 0"
               class="modal_select_city_header_list_filters_popular">
            <h2>Популярные районы</h2>
            <div class="popular_list">
              <div class="modal_select_city_elem" v-for="(pop, index) in popular_district_city"
                   @click="selectDistrictInArrayFilter(pop, checkDistrictInArrayFilter(pop.district_name));">
                <span :class="{selected: checkDistrictInArrayFilter(pop.district_name)}">
                  <p v-if="pop[this.settings.language_url]">{{ pop[this.settings.language_url] }}</p>
                  <p v-else>{{ pop.district_name }}</p>
                </span>
              </div>
            </div>
          </div>
          <div v-else-if="popular_district_city.length > 0"
               class="modal_select_city_header_list_filters_popular">
            <h2>Популярные районы</h2>
            <div class="popular_list">
              <div class="modal_select_city_elem" v-for="(pop, index) in popular_district_city"
                   @click="selectDistrict(pop);">
                <span :class="{selected: pop.district_name === this.selected_district}">
                  <p v-if="pop[this.settings.language_url]">{{ pop[this.settings.language_url] }}</p>
                  <p v-else>{{ pop.district_name }}</p>
                </span>
              </div>
            </div>
          </div>

          <div v-if="this.status_modal_filters_region">
            <div class="modal_select_city_elem" v-for="(district, index) in district_only_city"
                 @click="selectDistrictInArrayFilter(district, checkDistrictInArrayFilter(district.district_name));">
              <span :class="{selected: checkDistrictInArrayFilter(district.district_name)}">
                <p v-if="district[this.settings.language_url]">{{
                    district[this.settings.language_url]
                  }}</p>
                <p v-else>{{ district.district_name }}</p>
              </span>
            </div>
          </div>
          <div v-else>
            <div class="modal_select_city_elem" v-for="(district, index) in district_only_city"
                 @click="selectDistrict(district);">
              <span :class="{selected: district.district_name === this.selected_district}">
                <p v-if="district[this.settings.language_url]">{{
                    district[this.settings.language_url]
                  }}</p>
                <p v-else>{{ district.district_name }}</p>
              </span>
            </div>
          </div>
        </div>

        <div v-else class="modal_select_city_header_list">
          <h2>Не найдено</h2>
        </div>

        <div v-if="this.status_modal_filters_region" class="modal_select_city_header_edit_btns">
          <span class="modal_select_city_header_edit_btn" @click="clearAllDistrict()">Очистить всё</span>
          <span class="modal_select_city_header_edit_btn" @click="selectAllDistrict()">Выбрать всё</span>
        </div>

      </div>
    </div>
    <div class="modal_select_city_back" v-if="this.modal_list_metro">
      <div class="modal_select_city modal_select_metro">
        <div class="modal_select_city_header">
          <h2>Выберите метро</h2>
          <svg class="modal_select_city_header_close"
               @click="this.set_modal_list_metro(false); this.set_status_modal_filters_region(false);"
               viewBox="0 0 32 32"
               xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
               focusable="false"
               style="display: block; fill: none; height: 24px; width: 24px; stroke: currentcolor; stroke-width: 3; overflow: visible;">
            <path d="m6 6 20 20"></path>
            <path d="m26 6-20 20"></path>
          </svg>
        </div>

        <div class="modal_select_city_header_list_filters" v-if="this.metro_only_city">

          <div v-if="this.status_modal_filters_region">
            <div class="modal_select_city_elem" v-for="(metro, index) in this.metro_only_city"
                 @click="selectMetroInArrayFilter(metro, checkMetroInArrayFilter(metro.metro));">
              <span :class="{selected: checkMetroInArrayFilter(metro.metro)}">
                <p v-if="metro[this.settings.language_url]">{{
                    metro[this.settings.language_url]
                  }}</p>
                <p v-else>{{ metro.metro }}</p>
              </span>
            </div>
          </div>
          <div v-else>
            <div class="modal_select_city_elem" v-for="(metro, index) in this.metro_only_city"
                 @click="selectMetro(metro);">
              <span :class="{selected: metro.metro === this.selected_metro}">
                <p v-if="metro[this.settings.language_url]">{{
                    metro[this.settings.language_url]
                  }}</p>
                <p v-else>{{ metro.metro }}</p>
              </span>
            </div>
          </div>
        </div>

        <div v-else class="modal_select_city_header_list">
          <h2>Не найдено</h2>
        </div>

        <div v-if="this.status_modal_filters_region" class="modal_select_city_header_edit_btns">
          <span class="modal_select_city_header_edit_btn" @click="clearAllMetro()">Очистить всё</span>
          <span class="modal_select_city_header_edit_btn" @click="selectAllMetro()">Выбрать всё</span>
        </div>

      </div>
    </div>
    <div class="modal_select_city_back" v-if="this.modal_list_city">
      <div class="modal_select_city">
        <div class="modal_select_city_header">
          <h2>Выберите регион или город</h2>
          <div class="modal_select_city_header_search">
            <div class="filters_modal_content_filters_row_inputs_elem">
              <input autocomplete="nope" v-model="search_query" type="text"
                     placeholder="Выберите страну или город">
            </div>
            <button>Поиск</button>
            <span class="select_other" v-if="this.selected_country"
                  @click="selectCountry(null)">Другая страна</span>
          </div>

          <div class="popular_result" v-if="only_popular_city.length > 0">
            <h2>Популярные города</h2>
            <span v-for="pop in only_popular_city" @click="selectCity(pop); search_query = null"
                  :class="{selected: pop.name === this.selected_city}">
              <p v-if="pop[this.settings.language_url]">{{ pop[this.settings.language_url] }}</p>
              <p v-else>{{ pop.name }}</p>
            </span>
          </div>

          <div class="modal_select_city_header_result" v-if="search_result">
            <span v-for="(elem, index) in search_result">{{ elem }}</span>
          </div>

          <svg class="modal_select_city_header_close"
               @click="this.set_modal_list_city(false); this.set_status_modal_filters_region(false);"
               viewBox="0 0 32 32"
               xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
               focusable="false"
               style="display: block; fill: none; height: 24px; width: 24px; stroke: currentcolor; stroke-width: 3; overflow: visible;">
            <path d="m6 6 20 20"></path>
            <path d="m26 6-20 20"></path>
          </svg>
        </div>

        <div class="modal_select_city_header_list" v-if="!this.selected_country && !search_query">
          <div class="modal_select_city_elem" v-for="(country, index) in list_of_country"
               @click="selectCountry(country); search_query = null">
            <span :class="{selected: country.name === this.selected_country}">
              <p v-if="country[this.settings.language_url]">{{ country[this.settings.language_url] }}</p>
              <p v-else>{{ country.name }}</p>
            </span>
          </div>
        </div>

        <div class="modal_select_city_header_list" v-if="this.selected_country || search_query">
          <div class="modal_select_city_elem" v-for="(s_elem, index) in searchQuery">
            <span v-if="!s_elem.region_id" @click="selectCountry(s_elem); search_query = null"
                  :class="{selected: s_elem.name === this.selected_city}">
              <p v-if="s_elem[this.settings.language_url]">{{ s_elem[this.settings.language_url] }}</p>
              <p v-else>{{ s_elem.name }}</p>
            </span>
            <span v-else @click="selectCity(s_elem); search_query = null"
                  :class="{selected: s_elem.name === this.selected_city}">
              <p v-if="s_elem[this.settings.language_url]">{{ s_elem[this.settings.language_url] }}</p>
              <p v-else>{{ s_elem.name }}</p>
            </span>
          </div>
        </div>

        <div class="modal_select_city_header_list"
             v-if="(search_query && !searchQuery.length) || (!searchQuery.length && !search_query)">
          <h2>Не найдено</h2>
        </div>

      </div>
    </div>

    <div class="bottom_menu">
      <div class="bottom_menu_row mobile_hidden">
        <div class="copyright-block">
          {{ this.site_url }} © All right reserved, {{ new Date().getFullYear() }}
        </div>


        <!--<div class="bottom_menu_row_elem" @click="$router.push(`/bc`)">
          Бизнес Центры
        </div>
        <div class="bottom_menu_row_elem" @click="$router.push(`/tc`)">
          Торговые центры
        </div>
        <div class="bottom_menu_row_elem" @click="$router.push(`/zhk`)">
          Жилые комплексы
        </div>
        <div class="bottom_menu_row_elem" @click="$router.push(`/storage`)">
          Склады
        </div>
        <div class="bottom_menu_row_elem" @click="$router.push(`/cottages`)">
          Коттеджные поселки
        </div>
        <div class="bottom_menu_row_elem" @click="$router.push(`/developers`)">
          Девелоперы
        </div>
        <div class="bottom_menu_row_elem" @click="$router.push(`/agents`)">
          Агенты
        </div>
        <div class="bottom_menu_row_elem" @click="$router.push(`/agency`)">
          Агентства
        </div>
        <div class="bottom_menu_row_elem" @click="$router.push(`/guides`)">
          Гиды
        </div>
        <div class="bottom_menu_row_elem" @click="$router.push(`/translators`)">
          Переводчики
        </div>-->


        <div v-if="this.userInfo && this.userInfo.admin" class="bottom_menu_row_elem"
             @click="$router.push(`/moder`)">
          Модерация
        </div>

        <!--<button class="main_menu_elem menu-top__btn" @click="$router.push(`/favorites`)" aria-label="Избранное">
          <svg width='16' height='16' class='panorama-menu__icon close-icon'>
            <use xlink:href='../sprite.svg#favorite'/>
          </svg>
        </button>
        <button class="main_menu_elem menu-top__btn" @click="$router.push(`/comparison`)" aria-label="Сравнения">
          <svg width='16' height='16' class='panorama-menu__icon close-icon'>
            <use xlink:href='../sprite.svg#compare'/>
          </svg>
        </button>!-->

        <div class="bottom_menu_row_elem settings_elems" style="margin-left: auto" v-if="!load_setting">
          <div class="bottom_menu_row_elem" @click="$router.push(`/about-us`)">О нас</div>
          <div class="bottom_menu_row_elem" @click="$router.push(`/tariffs`)">Тарифы</div>
          <div class="main_menu_elem select_city" @click="modalSelectCity()">
            <span v-if="this.selected_city">{{ this.selected_city }}</span>
            <span v-if="this.selected_country && !this.selected_city">{{ this.selected_country }}</span>
            <span v-if="!this.selected_country && !this.selected_city">Выберите город</span>
          </div>
          <div
            @click="openOneTypeModal('currency')">
            {{ this.settings.currency_symbol }}
          </div>
          <div
            @click="openOneTypeModal('unit')">
            {{ this.settings.unit }}
          </div>
          <div
            @click="openOneTypeModal('language')">
            <img :src="`../img/country/${this.settings.language_img}`">
          </div>
        </div>


        <!--<div class="bottom_menu_row_elem" @click="$router.push(`/about-us`)">
          О нас
        </div>-->
        <!--<div class="bottom_menu_row_elem"
             @click="$router.push(`/payment`)">
          Оплата
        </div>-->

      </div>
      <div class="mobile_hidden"></div>
      <div class="mobile_bottom_menu">
        <div class="mobile_bottom_menu_elem" @click="$router.push(`/`)">
          <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
               focusable="false">
            <use xlink:href='../sprite.svg#home'/>
          </svg>
          <span>Главная</span>
        </div>
        <div class="mobile_bottom_menu_elem" @click="$router.push(`/favorites`)">
          <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
               focusable="false">
            <use xlink:href='../sprite.svg#favorite'/>
          </svg>
          <span>Избранное</span>
        </div>
        <div class="mobile_bottom_menu_elem" @click="$router.push(`/comparison`)">
          <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
               focusable="false">
            <use xlink:href='../sprite.svg#compare'/>
          </svg>
          <span>Сравнение</span>
        </div>

        <div v-if="!this.userInfo" class="mobile_bottom_menu_elem"
             @click="this.$emit('changeStatusModalEntry', true)">
          <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
               focusable="false">
            <use xlink:href='../sprite.svg#user'/>
          </svg>
          <span>Вход</span>
        </div>
        <div v-else class="mobile_bottom_menu_elem" @click="exit()">
          <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
               focusable="false">
            <use xlink:href='../sprite.svg#user'/>
          </svg>
          <span>Выход</span>
        </div>


      </div>
    </div>

    <transition name="fade">
      <div class="modal_select_city_back_filters" v-if="statusModalFilters">
        <ModalFilters v-if="statusModalFilters" v-on:changeStatusFiltersModal="changeStatusFiltersModal"
                      v-on:activateFilters="activateFilters"></ModalFilters>

      </div>
    </transition>

  </div>

</template>

<script>
import axios from "axios";
import {mapActions, mapMutations, mapState, mapGetters} from "vuex";
import ModalFilters from "@/components/ModalFilters";

export default {
  components: {
    ModalFilters,
  },
  emits: ["changeStatusFiltersModal", "changeStatusModalEntry", "startSearch", "updateCatalog"],
  data() {
    return {
      todo: [],

      site_url: window.location.hostname,

      statusModalFilters: false,

      loading_menu: false,

      select_first_filter: false,
      select_type_selling: false,

      load_setting: true,

      mobile_menu: false,

      upHere: false,
      count_logo_slide: 1,

      active_tab_setting: 'language',
      search_result: null,

      list_of_city: null,

      list_of_country: null,
      list_of_unit: [
        'Метры', 'Футы', 'Дюймы'
      ],

      search_query: null,

      modal_settings: false,
      modal_type_one: null,
    }
  },
  computed: {
    ...mapGetters([
      'metro_only_city',
      'district_only_city'
    ]),
    only_iso() {
      return this.list_of_city.filter(
        (elem) => elem.country_iso3 == this.selected_country_iso
      )
    },

    only_popular_city() {
      return this.list_of_city.filter(
        (elem) => elem.popular == 1
      )
    },

    popular_district_city() {
      return this.district_only_city.filter(
        (elem) => elem.popular == 1
      )
    },


    searchQuery() {
      if (this.search_query && this.search_query !== '') {

        let city = this.delete_all_null(this.list_of_city).filter(
          (elem) => elem[this.settings.language_url].toLowerCase().includes(this.search_query.toLowerCase())
        )
        let country = this.delete_all_null(this.list_of_country).filter(
          (elem) => elem[this.settings.language_url].toLowerCase().includes(this.search_query.toLowerCase())
        )

        return Object.assign(city, country)

      } else {
        if (!this.selected_country) {
          return this.list_of_country;
        } else if (this.selected_country) {
          return this.only_iso;
        }
      }
    },

    ...mapState({
      search: state => state.search,
      url_backend: state => state.url_backend,
      url_backend_img: state => state.url_backend_img,
      selected_city: state => state.selected_city,
      selected_country: state => state.selected_country,
      selected_district: state => state.selected_district,
      selected_country_iso: state => state.selected_country_iso,
      userInfo: state => state.userInfo,
      settings: state => state.settings,
      list_of_currency: state => state.list_of_currency,
      filters: state => state.filters,
      filter_type_property: state => state.filter_type_property,
      status_map_catalog: state => state.status_map_catalog,
      filters_main_block: state => state.filters_main_block,
      filter_count_rooms: state => state.filter_count_rooms,
      selected_city_id: state => state.selected_city_id,
      modal_list_district: state => state.modal_list_district,
      modal_list_city: state => state.modal_list_city,
      status_modal_filters_region: state => state.status_modal_filters_region,
      filter_selected_district: state => state.filter_selected_district,
      modal_list_metro: state => state.modal_list_metro,
      selected_metro: state => state.selected_metro,
      menu_list: state => state.menu_list,
      list_of_metro: state => state.list_of_metro,
      filter_selected_metro: state => state.filter_selected_metro,
      list_of_district: state => state.list_of_district,
      status_count_objects: state => state.status_count_objects,
    }),
  },
  watch: {
    selected_city(newVal) {
      this.checkIssetObjectsInMenu()
        .then(res => {
          this.set_menu_list(res);
        })
        .catch(err => {
          console.log(err)
        })
    },
    'filters.type_property'(newVal) {
      //this.clear_filters_main_block();
    },
    'filters.sell_type'(newVal) {
      //this.clear_filters_main_block();
      if (this.filters.type_property == 0) {
        this.filters.type_property = 1;
      }
    },
    search_query(newVal, oldVal) {
      clearInterval(this.timerInterval);
      this.setSearch(newVal);
      if (newVal || newVal === '') {
        this.timerInterval = setTimeout(() => {
          this.$emit('updateCatalog');
          // this.getLoadObjects({'loadMore': false})
          //   .then(res => {
          //     this.activateFilters();
          //   })
          //   .catch(err => {
          //     console.log("Ошибка: " + err)
          //   })
        }, "1000");
      }
    },
    upHere(newVal) {
      if (newVal) {
        if (this.count_logo_slide === 9) {
          this.count_logo_slide = 1;
        } else {
          this.count_logo_slide++;
        }
      }
    },
    $route(newVal) {
      this.set_modal_list_city(false);
      this.set_status_modal_filters_region(false);
      this.mobile_menu = false;
    }
  },
  methods: {
    checkAcceptedMenu(menu, dop_one = null, dop_second = null) {
      if (this.menu_list) {
        if (dop_second) {
          if (this.menu_list[menu]) {
            if (this.menu_list[menu][dop_one]) {
              if (this.menu_list[menu][dop_one][dop_second]) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else if (dop_one) {
          if (this.menu_list[menu]) {
            if (this.menu_list[menu][dop_one]) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          if (this.menu_list[menu]) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    },
    delete_all_null(array) {
      return array.filter(
        (elem) => elem[this.settings.language_url]
      )
    },
    closeAllSelectsAndOpen(open, value) {
      if (open == 'select_type_selling') {
        this.select_first_filter = 0;
      } else {
        this.select_type_selling = 0;
      }
      if (this[open] == value) {
        this[open] = 0;
      } else {
        this[open] = value;
      }
    },
    async setOneParamAndTypeObject(param, value, type, living = true) {
      this.resetAllDopFilters()
        .then(res => {
          if (living) {
            this.filters.type_property = 1;
          } else {
            this.filters.type_property = 2;
          }
          this.filters[param] = value;
        })
        .then(res => {
          this.set_array_filters_main_block(type);
        })
        .then(res => {
          console.log("Стал: ")
          console.log(this.filters_main_block)
          this.$router.push(`/`);
          return true;
        })
        .then(res => {
          this.$emit('updateCatalog');
          return true;
        })
    },
    setTwoParamAndTypeObject(param_one, value_one, param_two, value_two, type, living = true) {
      this.resetAllDopFilters()
        .then(res => {
          if (living == 0) {
            this.filters.type_property = 0;
          } else if (living) {
            this.filters.type_property = 1;
          } else {
            this.filters.type_property = 2;
          }
          this.filters[param_one] = value_one;
          this.filters[param_two] = value_two;
          return true;
        })
        .then(res => {
          this.set_array_filters_main_block(type);
        })
        .then(res => {
          this.$router.push(`/`);
          return true;
        })
        .then(res => {
          this.$emit('updateCatalog');
          return true;
        })
    },
    setTwoParam(param_one, value_one, param_two, value_two, living = true) {
      this.resetAllDopFilters()
        .then(res => {
          if (living) {
            this.filters.type_property = 1;
          } else {
            this.filters.type_property = 2;
          }
          this.filters[param_one] = value_one;
          this.filters[param_two] = value_two;
          return true;
        })
        .then(res => {
          this.$router.push(`/`);
          return true;
        })
        .then(res => {
          this.$emit('updateCatalog');
          return true;
        })
    },
    setOneParam(param, value, living = true) {
      this.resetAllDopFilters()
        .then(res => {
          if (living) {
            this.filters.type_property = 1;
          } else {
            this.filters.type_property = 2;
          }
          this.filters[param] = value;
          return true;
        })
        .then(res => {
          this.$router.push(`/`);
          return true;
        })
        .then(res => {
          this.$emit('updateCatalog');
          return true;
        })
    },
    addMainBlockFilter(value) {
      this.resetAllDopFilters()
        .then(res => {
          this.filters_main_block[value] = true;
        })
        .then(res => {
          this.$router.push(`/`);
        })
        .then(res => {
          this.$emit('updateCatalog');
        })
    },
    selectSellType(value) {
      if (this.filters.sell_type == value) {
        this.filters.sell_type = 0;
      } else {
        this.filters.sell_type = value;
      }
    },
    async clearAllMetro() {
      this.clear_filter_selected_metro();
    },
    async clearAllDistrict() {
      this.clear_filter_selected_district();
    },
    async selectAllDistrict() {
      await this.clearAllDistrict();
      for (let i in this.district_only_city) {
        await this.push_filter_selected_district(this.district_only_city[i].district_name);
      }
    },
    async selectAllMetro() {
      await this.clearAllMetro();
      for (let i in this.metro_only_city) {
        await this.push_filter_selected_metro(this.metro_only_city[i].metro);
      }
    },
    checkDistrictInArrayFilter(district_name) {
      for (let i in this.filter_selected_district) {
        if (this.filter_selected_district[i] == district_name) {
          return true;
        }
      }
      return false;
    },
    checkMetroInArrayFilter(metro) {
      for (let i in this.filter_selected_metro) {
        if (this.filter_selected_metro[i] == metro) {
          return true;
        }
      }
      return false;
    },
    selectDistrictInArrayFilter(district, status) {
      if (status) {
        this.unset_filter_selected_district(district.district_name);
      } else {
        this.push_filter_selected_district(district.district_name);
      }
    },
    selectMetroInArrayFilter(metro, status) {
      console.log("Добавляем метро в массив")
      console.log(metro)
      console.log(status)
      if (status) {
        this.unset_filter_selected_metro(metro.metro);
      } else {
        this.push_filter_selected_metro(metro.metro);
      }
    },
    changeStatusFiltersModal(bool) {
      this.statusModalFilters = bool;
      this.statusBackgroundModal = bool;
    },
    openMainPage() {
      this.select_first_filter = 0;
      this.select_type_selling = 0;
      this.$emit('updateCatalog');
      this.$router.push(`/`);
    },
    async showOnMap() {
      if (this.status_map_catalog) {
        await this.set_status_map_catalog(false);
      } else {
        await this.set_status_map_catalog(true);
      }
      this.openMainPage();
    },
    activateFilters() {
      this.$router.push(`/`);
      this.$emit('updateCatalog');
      this.statusModalFilters = false;
    },
    changeStatusRoom(rooms) {
      if (this.filter_count_rooms[rooms]) {
        this.unset_filter_count_rooms(rooms);
      } else {
        this.set_filter_count_rooms(rooms);
      }
    },
    setFilterTypeProperty(type, type_property = null) {
      console.log("Выбранные уже фильтры")
      console.log(this.filters_main_block)


      if (this.filter_type_property != type) {
        this.clear_filters_main_block();
      }

      if (type != this.filter_type_property_selected) {
        this.filter_type_property_selected = type;
        this.clear_filters_main_block();
      } else {
        this.filter_type_property_selected = type;
      }

      if (type_property) {
        if (this.filters_main_block[type_property]) {
          this.unset_filters_main_block(type_property);
        } else {
          this.set_filters_main_block(type_property);
        }
      }


    },
    getTextFilter(property, object) {

      let res;

      if (property == 1) {
        if (object[1]) {
          res += 'Квартира';
        }
        if (object[2]) {
          res += ' Комната';
        }
        if (object[3]) {
          res += ' Койко-место';
        }
        if (object[4]) {
          res += ' Дом, дача';
        }
        if (object[5]) {
          res += ' Часть дома';
        }
        if (object[6]) {
          res += ' Таунхаус';
        }
      } else if (property == 2) {
        if (object[1]) {
          res += 'Офис';
        }
        if (object[2]) {
          res += 'Коворкинг';
        }
        if (object[3]) {
          res += 'Здание';
        }
        if (object[4]) {
          res += 'Торговая площадь';
        }
        if (object[5]) {
          res += 'Помещение свободного назначения';
        }
        if (object[6]) {
          res += 'Производство';
        }
        if (object[7]) {
          res += 'Склад';
        }
        if (object[8]) {
          res += 'Гараж';
        }
        if (object[9]) {
          res += 'Коммерческая земля';
        }
      }

      console.log(object);
      console.log(res);

      return res;
    },
    openModalMenu() {
      this.mobile_menu = true;
    },
    exit() {
      this.setAuthorization(false);
      this.setUserInfo('');
      this.setUserFavorites('');
      localStorage.removeItem('entry_key');
    },
    async setOnlyOneDopParam(param, value, filter_type_property) {
      let mutation = 'set_filter_' + param;
      this.resetAllDopFilters()
        .then(res => {
          this[mutation](value);
        })
        .then(res => {
          this.$router.push(`/`);
        })
        .then(res => {
          this.$emit('updateCatalog');
        })
    },
    async openMainPageWithoutFilters() {
      this.resetAllDopFilters()
        .then(res => {
          this.$router.push(`/`);
        })
        .then(res => {
          this.$emit('updateCatalog', true);
        })
    },
    async resetAllDopFilters() {
      this.filters.sell_type = 0;
      this.filters.time_limit = 0;
      this.filters.sell_selling_type = 0;
      this.filters.planning = [];
      this.set_array_filters_main_block([]);

      this.mobile_menu = false;
      return true;
    },
    async setDopOptionsFilters(filter_sell_type, filter_time_limit, filter_type_property) {
      this.resetAllDopFilters()
        .then(res => {
          this.set_filter_sell_type(filter_sell_type);
          this.set_filter_time_limit(filter_time_limit);
        })
        .then(res => {
          this.$router.push(`/`);
        })
        .then(res => {
          this.$emit('updateCatalog');
        })
    },
    async setDopOptionsFiltersWithoutSellType(filter_object_type, filter_sell_selling_type, filter_type_property) {
      this.resetAllDopFilters()
        .then(res => {
          this.set_filter_object_type(filter_object_type);
          this.set_filter_sell_selling_type(filter_sell_selling_type);
        })
        .then(res => {
          this.$router.push(`/`);
        })
        .then(res => {
          this.$emit('updateCatalog');
        })
    },

    async setDopOptionsFiltersWithTypeObject(filter_sell_type, filter_object_type, filter_type_property) {
      this.resetAllDopFilters()
        .then(res => {
          this.set_filter_sell_type(filter_sell_type);
          this.set_filter_object_type(filter_object_type);
        })
        .then(res => {
          this.$router.push(`/`);
        })
        .then(res => {
          this.$emit('updateCatalog');
        })
    },

    setDopOptionsFiltersWithTypeObjectAndSellingType(filter_sell_type, filter_object_type, filter_sell_selling_type, filter_type_property) {
      this.resetAllDopFilters()
        .then(res => {
          this.set_filter_sell_type(filter_sell_type);
          this.set_filter_object_type(filter_object_type);
          this.set_filter_sell_selling_type(filter_sell_selling_type);
        })
        .then(res => {
          this.$router.push(`/`);
        })
        .then(res => {
          this.$emit('updateCatalog');
        })
    },

    checkForAuth() {
      if (this.userInfo) {
        this.$router.push(`/create`);
      } else {
        this.$emit('changeStatusModalEntry', true);
      }
    },
    openOneTypeModal(type) {
      this.modal_settings = true;
      this.modal_type_one = type;
      this.active_tab_setting = null;
    },
    select_unit(unit) {
      console.log(unit)
      this.set_settings({param: 'unit', value: unit});
      this.changeBool('modal_settings');
      this.modal_type_one = null;
      localStorage.setItem("unit", unit);
    },
    select_currency(currency) {
      this.set_settings({param: 'currency', value: currency.currency});
      this.set_settings({param: 'currency_symbol', value: currency.symbol});
      this.set_settings({param: 'currency_id', value: currency.id});
      this.set_settings({param: 'currency_from_usd', value: currency.from_USD});
      this.changeBool('modal_settings');
      this.modal_type_one = null;
      localStorage.setItem("currency", currency.currency);
      localStorage.setItem("currency_symbol", currency.symbol);
      localStorage.setItem("currency_id", currency.id);
      localStorage.setItem("currency_from_usd", currency.from_USD);
    },
    changeBool(param, value = null) {
      if (value === null) {
        if (this[param]) {
          this[param] = false;
        } else {
          if (param === 'modal_settings') {
            this.modal_type_one = null;
            this.active_tab_setting = 'language';
          }
          this[param] = true;
        }
      } else {
        if (this[param] == value) {
          this[param] = false;
        } else {
          this[param] = value;
        }
      }
    },
    changeLanguage(lang, img, get_url) {
      this.set_settings({param: 'language', value: lang});
      this.set_settings({param: 'language_img', value: img});
      this.set_settings({param: 'language_url', value: get_url});
      localStorage.setItem("language", lang);
      localStorage.setItem("language_img", img);
      localStorage.setItem("language_url", get_url);
      this.changeBool('modal_settings');
      this.modal_type_one = null;
      this.$router.push('?locale=' + get_url);
      this.loadMainSettings();
    },
    ...mapActions({
      getLoadObjects: 'getLoadObjects',
    }),
    ...mapMutations({
      set_selected_city: 'set_selected_city',
      set_selected_country: 'set_selected_country',
      set_selected_country_iso: 'set_selected_country_iso',
      set_list_of_currency: 'set_list_of_currency',
      set_settings: 'set_settings',
      set_filter_time_limit: 'set_filter_time_limit',
      set_filter_sell_type: 'set_filter_sell_type',
      set_filter_object_type: 'set_filter_object_type',
      set_filter_sell_selling_type: 'set_filter_sell_selling_type',
      set_filters_main_block: 'set_filters_main_block',
      unset_filters_main_block: 'unset_filters_main_block',
      set_status_map_catalog: 'set_status_map_catalog',
      unset_filter_count_rooms: 'unset_filter_count_rooms',
      set_filter_count_rooms: 'set_filter_count_rooms',
      setSearch: 'setSearch',
      setAuthorization: 'setAuthorization',
      setUserInfo: 'setUserInfo',
      setUserFavorites: 'setUserFavorites',
      set_selected_district: 'set_selected_district',
      set_selected_city_id: 'set_selected_city_id',
      set_modal_list_city: 'set_modal_list_city',
      set_modal_list_district: 'set_modal_list_district',
      set_status_modal_filters_region: 'set_status_modal_filters_region',
      push_filter_selected_district: 'push_filter_selected_district',
      unset_filter_selected_district: 'unset_filter_selected_district',
      clear_filter_selected_district: 'clear_filter_selected_district',
      push_filter_selected_metro: 'push_filter_selected_metro',
      unset_filter_selected_metro: 'unset_filter_selected_metro',
      clear_filter_selected_metro: 'clear_filter_selected_metro',
      clear_filters_main_block: 'clear_filters_main_block',
      set_array_filters_main_block: 'set_array_filters_main_block',
      set_modal_list_metro: 'set_modal_list_metro',
      set_selected_metro: 'set_selected_metro',
      set_menu_list: 'set_menu_list',
      set_list_of_metro: 'set_list_of_metro',
      set_list_of_district: 'set_list_of_district',
    }),
    modalSelectCity() {
      if (this.modal_list_city) {
        this.set_modal_list_city(false);
        this.set_status_modal_filters_region(false);
      } else {
        this.set_modal_list_city(true);
      }
    },
    modalSelectDistrict() {
      if (this.modal_list_district) {
        this.set_modal_list_district(false);
        this.set_status_modal_filters_region(false);
      } else {
        this.set_modal_list_district(true);
      }
    },
    selectDistrict(district) {
      this.set_selected_district(district.district_name);
      this.modalSelectDistrict();
    },
    selectMetro(metro) {
      this.set_selected_metro(metro.metro);
      this.set_modal_list_metro(false);
    },
    selectCountry(country) {
      if (country) {
        this.set_selected_country(country.name);
        this.set_selected_country_iso(country.iso3letter);
        localStorage.setItem("selected_country", country.name);
        localStorage.setItem("selected_country_iso", country.iso3letter);
        localStorage.removeItem('selected_city');
        localStorage.removeItem('selected_city_id');
      } else {
        this.set_selected_country(null);
        this.set_selected_country_iso(null);
        this.set_selected_city(null);
        this.set_selected_city_id(null);
        localStorage.removeItem('selected_country');
        localStorage.removeItem('selected_country_iso');
        localStorage.removeItem('selected_city');
        localStorage.removeItem('selected_city_id');
      }
    },
    selectCity(city) {
      this.set_selected_district(null);
      this.set_selected_city(city.name);
      this.set_selected_city_id(city.region_id);
      this.set_modal_list_city(false);
      this.set_status_modal_filters_region(false);
      localStorage.setItem("selected_city", city.name);
      localStorage.setItem("selected_city_id", city.region_id);
    },
    async checkIssetObjectsInMenu() {
      try {
        const response = await axios.post(
          this.url_backend + '?checkIssetObjectsInMenu',
          {
            city: this.selected_city,
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        return response.data;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async getListCity() {
      try {
        const response = await axios.post(
          this.url_backend + '?getListCity',
          {
            translate: this.settings.language_url,
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        return response.data;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async loadMainSettings() {
      this.getListCity()
        .then(res => {
          this.list_of_city = res.city;
          this.set_list_of_metro(res.metro);
          this.set_list_of_district(res.district);
          this.list_of_country = res.country;
          this.set_list_of_currency(res.currency);

          let localUnit = localStorage.getItem("unit");
          if (localUnit) {
            this.set_settings({param: 'unit', value: localUnit});
          }

          let localCurrency = localStorage.getItem("currency");
          if (localCurrency) {
            for (let i in res.currency) {
              if (res.currency[i].currency === localCurrency) {
                this.set_settings({param: 'currency_symbol', value: res.currency[i].symbol});
                this.set_settings({param: 'currency', value: res.currency[i].currency});
                this.set_settings({param: 'currency_id', value: res.currency[i].id});
                this.set_settings({param: 'currency_from_usd', value: res.currency[i].from_USD});
              }
            }
          } else {
            for (let i in res.currency) {
              if (res.currency[i].currency === 'USD') {
                this.set_settings({param: 'currency_symbol', value: res.currency[i].currency});
                this.set_settings({param: 'currency', value: res.currency[i].symbol});
                this.set_settings({param: 'currency_id', value: res.currency[i].id});
                this.set_settings({param: 'currency_from_usd', value: res.currency[i].from_USD});
              }
            }
          }
          return true;
        })
        .then(res => {
          if (!localStorage.getItem("language")) {
            this.set_settings({param: 'language', value: 'RU'});
            this.set_settings({param: 'language_img', value: 'rus-border-rad.svg'});
            this.set_settings({param: 'language_url', value: 'ru_RU'});
          }


          if (!localStorage.getItem("selected_country")) {
            for (let i in this.list_of_country) {
              if (this.list_of_country[i].name == "Uzbekistan") {
                this.selectCountry(this.list_of_country[i]);
                break;
              }
            }

            for (let k in this.list_of_city) {
              if (this.list_of_city[k].name == "Ташкент") {
                this.selectCity(this.list_of_city[k]);
                break;
              }
            }
          } else {
            this.set_selected_city(localStorage.getItem("selected_city"));
            this.set_selected_city_id(localStorage.getItem("selected_city_id"));
            this.set_selected_country(localStorage.getItem("selected_country"));
            this.set_selected_country_iso(localStorage.getItem("selected_country_iso"));
          }

        })
        .then(res => {
          return this.checkIssetObjectsInMenu();
        })
        .then(res => {
          this.set_menu_list(res);
          console.log(res);
        })
        .then(res => {
          this.loading_menu = true;
          this.load_setting = false;
        })
        .catch(err => {
          console.log(err);
        })
    },
  },
  mounted() {
    this.loadMainSettings();
  },
}
</script>

<style>

.main_menu {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #dddddd87;
  background-color: white;
  z-index: 13;
}

@media (min-width: 480px) {
  .main_menu {
    padding: 10px 20px;
  }
}

@media (min-width: 540px) {
  .menu-top__text-logo img {
    width: 120px;
    height: auto;
  }
}

@media (min-width: 768px) {
  .main_menu {
    padding: 10px 40px;
  }
}

@media ((min-width:

768px

) and

(max-width:

1023px

)) {
  .search .input_search_mobile {
    display: none;
  }

  .search .input_search_full {
    display: flex;
    min-width: 320px;
  }
}

@media (min-width: 1350px) {
  .search .input_search_mobile {
    display: none;
  }

  .search .input_search_full {
    display: flex;
    min-width: 320px;
  }
}

@media (min-width: 1024px) {
  .main_menu.menu-top {
    justify-content: space-between;
  }
}

.menu-top__left-part,
.menu-top__right-part {
  display: flex;
  align-items: center;
  gap: 10px;
}

.menu-top__text-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media (min-width: 1350px) {
  .main_menu {
    padding: 10px 80px;
  }
}

.menu-top__dropdown-wrapper {
  display: none;
  overflow: auto;
  font-size: 14px;
}

@media (min-width: 1024px) {
  .menu-top__dropdown-wrapper {
    display: flex;
  }
}

.menu-top__dropdown {
  height: 30px;
  padding: calc((30px - 20.06px) / 2);
}

.menu-top__dropbtn {
  display: flex;
  align-items: flex-end;
  gap: 5px;
}

.menu-top__btn {
  border: none;
  background: transparent;
  height: 28px;
}

@media (min-width: 1024px) {
  .menu-top .menu-top__btn svg,
  .menu-top__btn.menu-top__user-btn svg {
    width: 16px;
    height: 16px;
  }

  .menu-top__btn.menu-top__btn--lang svg {
    width: 16px;
    height: 16px;
  }
}

.menu-top__search-wrapper {
  max-width: 180px;
}

.menu-top__btn,
.menu-top__user-icon,
.menu-top__btn svg {
  width: 28px;
  height: 28px;
}

@media (min-width: 1024px) {
  .menu-top .menu-top__btn,
  .menu-top__btn.menu-top__user-btn svg,
  .menu-top__btn.menu-top__user-icon {
    width: 16px;
    height: 16px;
  }
}

.menu-top__btn-create {
  padding: 0.5em;
  border: 1px solid #3B9ED4;
  border-radius: 10px;
  transition: all 0.3s ease-in;
}

.menu-top__btn-create-full {
  display: none;
}

@media (min-width: 540px) {
  .menu-top__btn-create-full {
    display: inline;
  }
}

.main_menu_elem.menu-top__btn-create:hover {
  background: #3B9ED4;
  color: #fff;
}

.menu-top__btn-login {
  padding: 0.5em;
  background-color: #3B9ED4;
  color: #fff;
  border-radius: 10px;
  display: none;
}

.mobile_menu .menu-top__btn-login {
  display: inline-flex;
}

@media (min-width: 801px) {
  .main_menu .menu-top__btn-login {
    display: inline-flex;
  }
}

.main_menu_elem.menu-top__btn-login:hover {
  background-color: #fff;
  color: #717171;
  border: 1px solid #3B9ED4;
}

.menu-top__dropdown .menu-top__icon {
  height: 16.8px;
  align-self: flex-end;
  display: flex;
}

.main_menu svg,
.menu-top__user-btn svg {
  fill: #4A556D;
}

.main_menu_elem:hover svg,
.menu-top__user-btn:hover svg {
  fill: #3B9ED4;
}

.main_menu_logo img {
  width: 40px;
}

@media (min-width: 1024px) {
  .menu-top .elem_burger {
    display: none;
  }
}

.main_menu_interaction {
  display: flex;
  justify-content: space-between;
  width: 70%;
  align-items: center;
}

.main_menu_interaction_search {
  display: flex;
  align-items: center;
  border-radius: 30px;
  border: 1px solid #DDDDDD;
  padding: 10px;
  box-shadow: 0 2px 10px -6px black;
  justify-content: space-between;
  width: 40vw;
  max-width: 600px;
  cursor: pointer;
}

.main_menu_interaction_search p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: center;
}

.main_menu_interaction_search div:nth-of-type(1), .main_menu_interaction_search div:nth-of-type(2) {
  border-right: 1px solid #DDDDDD;
  margin-right: 10px;
  padding-right: 10px;
}

.main_menu_interaction_search div {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_menu_interaction_search div.main_menu_interaction_search_icon {
  border: none;
  margin-right: 0;
  padding: 0;
  width: 40px;
  height: 32px;
  display: flex;
  background-color: #ff38a1;
  color: white;
  border-radius: 70px;
  align-items: center;
  justify-content: center;
}

.main_menu_interaction_search_icon svg, .menu_mobile_settings_icon svg {
  margin-right: 0;
  cursor: pointer;
}

.main_menu_interaction_login {
  display: flex;
  align-items: center;
  max-width: 80px;
  justify-content: space-between;
  border: 1px solid #DDDDDD;
  border-radius: 30px;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .main_menu_interaction {
    width: 90%;
  }
}

.menu_mobile {
  display: none;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #DDDDDD;
  padding: 10px;
  border-radius: 30px;
  box-shadow: 0 2px 10px -6px black;
  width: 90%;
}

.menu_mobile_text p:nth-of-type(1) {
  font-size: 14px;
  font-weight: 600;
}

.menu_mobile_text p:nth-of-type(2) {
  font-size: 12px;
  color: #717171;
}

@media (max-width: 750px) {
  .menu_mobile {
    display: flex;
  }

  .menu_mobile_background {
    display: flex !important;
    padding: 7px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 12;
    align-items: center;
    justify-content: center;
  }
}

.menu_mobile_settings {
  position: absolute;
  right: -1px;
  top: 0px;
  border: 1px solid #e3e3e3;
  border-radius: 60px;
  padding: 10px;
  cursor: pointer;
}

.menu_mobile_settings svg {
  margin: 0;
}

.menu_mobile_settings_icon {
  border-radius: 30px;
  border: 1px solid #DDDDDD;
  padding: 10px;
}

.menu_mobile_search_icon {
  margin-left: 5px;
}

.main_menu_interaction_login_menu {
  margin-right: 10px;
}

.main_menu_interaction_login_user {
  margin-right: 0;
}

.main_menu_elem {
  cursor: pointer;
  transition: all .3s;
}

.main_menu_elem:hover {
  color: #3B9ED4;
}

.select_city {
  color: #3B9ED4;
}

.modal_select_city_back {
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #0000001f;
}

.modal_select_city_back_filters {
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #0000001f;
}

.modal_select_city {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  width: 80%;
  max-height: 850px;
  height: 88vh;
  box-shadow: 0px 0px 23px -14px black;
  border-radius: 10px;
}

.modal_select_city_elem {
  margin: 5px;
  width: 22%;
  min-width: 100px;
  cursor: pointer;
  transition: all .3s;
  display: flex;
  align-items: center;
}

.modal_select_metro {
  overflow-y: auto;
}

.modal_select_metro .modal_select_city_elem {
  justify-content: flex-start;
}

.modal_select_metro .modal_select_city_header_list_filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.modal_select_city_elem:hover {
  color: #3B9ED4;
}

.catalog_main-block_filters_second-row_search.search {
  width: 100%;
}

@media (min-width: 1024px) {
  .catalog_main-block_filters_second-row_search.search {
    width: 100%;
  }
}

.catalog_main-block_filters_second-row_modal .select_rooms_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.select_rooms {
  padding: 0.5em;
  text-align: center;
  border: 1px solid #DDD;
  border-radius: 10px;
}

.menu_mobile.search {
  display: flex;
  width: 100%;
  padding: 0;
  position: relative;
}

.input_search_mobile {
  display: flex;
}

.input_search_full {
  display: none;
}

@media (min-width: 1350px) {
  .menu_mobile.search {
    width: auto;
  }

  .catalog_main-block_filters_second-row_search.search {
    width: 100%;
  }
}

.search .menu_mobile_text {
  width: 100%;
}

.search .menu_mobile_text input {
  width: 100%;
  border: none;
  padding: 10px;
  border-radius: 40px;
}

.search .menu_mobile_text input:focus-visible {
  outline: none;
}

.search {
  margin: 0 auto;
}

.dropdown_4,
.dropdown_5,
.dropdown_6 {
  display: none;
}

.dropdown_7 {
  display: block;
}

@media (min-width: 1351px) {
  .dropdown_4,
  .dropdown_5,
  .dropdown_6 {
    display: block;
  }

  .dropdown_7 {
    display: none;
  }

  .search {
    max-width: 350px;
  }
}

@media (min-width: 1600px) {
  .catalog_main-block_filters_second-row_select .catalog_main-block_filters_second-row_select_elem {
    max-width: 220px;
  }

  .search {
    max-width: 600px;
  }

  .catalog_main-block_filters_second-row_select .catalog_main-block_filters_second-row_select_elem {
    max-width: 200px;
  }
}

.search .menu_mobile_search_icon {
  z-index: 2;
  margin-left: 10px;
  pointer-events: none;
}

.bottom_menu {
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 10;
  background-color: white;
  padding: 8px 10px;
  border-top: 1px solid #ededed;
  box-shadow: 0px 0px 10px -10px black;
}

.bottom_menu .copyright-block {
  margin-right: 10px;
}

.bottom_menu_row {
  display: flex;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}

.bottom_menu_row_elem {
  margin-right: 10px;
  font-size: 14px;
  color: gray;
  cursor: pointer;
}

@media (max-width: 1300px) {
  .bottom_menu_row {
    width: 100%;
  }
}

.mobile_bottom_menu {
  display: none;
}

.mobile_bottom_menu_elem {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.mobile_bottom_menu svg {
  width: 25px;
  height: 25px;
  fill: #4A556D;
}

.mobile_bottom_menu_elem:focus svg {
  fill: #3B9ED4;
}

.modal_select_city_header h2 {
  margin: 10px 0;
}

.modal_select_city_header_list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-around;
  overflow: auto;
}

.modal_select_city_header_list_filters div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-around;
  overflow: auto;
}

.modal_select_city_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  position: relative;
}

.modal_select_city_header_close {
  position: absolute;
  right: 20px;
  top: 10px;
}

.modal_select_city_header_close:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: .3s;
  transition: 0.3s;
}

.modal_select_city_header_search {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.modal_select_city_header_search .filters_modal_content_filters_row_inputs_elem input {
  margin-left: 0;
}

.modal_select_city_header button {
  background: #3B9ED4;
  color: white;
  box-shadow: 0px 4px 15px rgb(59 158 212);
  border-color: transparent;
  display: inline-block;
  cursor: pointer;
  padding: 0px 15px;
  line-height: 34px;
  border-radius: 6px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: all .3s;
  max-width: 300px;
  font-weight: bold;
}

.select_other {
  margin-left: 20px;
  color: #3B9ED4;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 800px) {
  .mobile_hidden {
    display: none;
  }

  .mobile_bottom_menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }

  .modal_select_city {
    width: 100%;
    height: 100%;
    max-height: none;
  }

  .modal_select_city_elem {
    width: 35%;
  }

  .menu-top__btn,
  .menu-top__btn-login {
    display: none;
  }

  .menu-top__btn.menu-top__btn--lang,
  .menu-top__btn.menu-top__btn--profile {
    display: inline-flex;
  }
}

.dropdown_content {
  display: flex;
  flex-wrap: wrap;
  /* gap: 10px; */
  display: none;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  background-color: #fff;
  z-index: 1;
  padding: 1em;
  border-radius: 10px;
}

.dropdown_content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown_content span {
  display: block;
  cursor: pointer;
  padding: 5px;
  font-size: 16px;
  line-height: 21px;
}

.dropdown_content span:hover {
  color: #3B9ED4;
}

.dropdown_content .dropdown_content__title {
  font-size: 21px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.dropbtn_1,
.dropbtn_2,
.dropbtn_3,
.dropbtn_4,
.dropbtn_5,
.dropbtn_6,
.dropbtn_7 {
  border: none;
  position: relative;
  height: 20.06px;
}

.dropdown_1:hover .dropdown_content_1,
.dropdown_2:hover .dropdown_content_2,
.dropdown_3:hover .dropdown_content_3,
.dropdown_4:hover .dropdown_content_4,
.dropdown_5:hover .dropdown_content_5 {
  display: block;
}

.dropdown_6:hover .dropdown_content_6,
.dropdown_7:hover .dropdown_content_7 {
  display: flex;
}

.dropdown_7:hover .dropdown_content_7 {
  gap: 20px;
  width: max-content;
  right: 10px;
}

.language_selected {
  position: relative;
}

.language_selected_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: space-between;
}

.language_selected_list img {
  width: 80px;
  margin-right: 10px;
}

li {
  list-style: none;
  width: 160px;
}

.ul-lang__li a {
  display: flex;
  justify-content: space-between;
  margin: 5px 3px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
  align-items: center;
}

.modal_header_tabs {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
}

.modal_header_tabs span {
  padding: 10px;
  margin-right: 10px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: #f3f3f3;
  cursor: pointer;
  transition: all .3s;
}

.modal_header_tabs span.active_tab_setting {
  background-color: #3B9ED4;
  color: white;
}

.ul-lang__a {
  transition: all .3s;
  cursor: pointer;
  border-radius: 10px;
  padding-right: 5px;
}

.ul-lang__a:hover {
  box-shadow: 0 2px 10px -6px black;
}

.modal_settings_content {
  overflow: auto;
  margin-top: 30px;
}

.language_selected_list_elem {
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
  width: 25%;
  justify-content: center;
  align-items: flex-start;
  min-width: 200px;
}

.language_selected_list_elem:hover {
  background: #f3f3f3;
}

.main_menu_elem.img_logo {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

.logo_slide-enter-from { /* Элемент появляется */
  transform: translateY(-45px);
  position: absolute;
  pointer-events: none;
}

.logo_slide-enter-active { /* Элемент появляется активен */
  transition: all 1s ease;
  pointer-events: none;
}

.logo_slide-enter-to { /* Элемент Появился */
  transform: translateY(0px);
}

.logo_slide-leave-from { /* Элемент Начинает исчезать */
  transition: all 0.5s ease;
  pointer-events: none;
}

.logo_slide-leave-active { /* Элемент исчезает активен */
  position: absolute;
  pointer-events: none;
  transition: all 0.5s ease;
}

.logo_slide-leave-to { /* Элемент исчезает */
  transform: translateY(45px);
  pointer-events: none;
}

.bottom_menu_row_elem img {
  width: 25px;
}

.bottom_menu_row_elem.settings_elems {
  display: flex;
  align-items: center;
}

.bottom_menu_row_elem.settings_elems div {
  margin-left: 10px;
}

.dropdown_content_header {
  color: black;
  font-weight: bold;
}

.logo_slider img {
  width: 33px;
  margin: 0;
  margin-right: 5px;
}

.main_menu_elem.img_logo svg {
  margin: 0;
  max-width: 45px;
  width: 45px;
}

.main_menu_elem_burger {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.main_menu_elem_burger span {
  background-color: #3B9ED4;
  width: 36px;
  height: 4px;
}

.elem_burger {
  cursor: pointer;
}

.mobile_menu {
  position: fixed;
  width: 100%;
  background-color: white;
  padding: 40px 20px;
  z-index: 15;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mobile_menu_group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mobile_menu .menu-top__btn-login {
  width: fit-content;
}

.mobile_menu_close {
  position: absolute;
  right: 15px;
  top: 15px;
}

.mobile_menu_close:hover {
  transform: rotate(360deg);
  transition: 0.3s;
}

.mobile_menu_settings_wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 200px;
}

@media (min-width: 360px) {
  .mobile_menu_settings_wrapper {
    max-width: none;
  }
}

.catalog_main-block_filters_btns {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.catalog_main-block_filters_second-row {
  width: 100%;
}

@media (min-width: 1350px) {
  .catalog_main-block_filters_second-row {
    width: fit-content;
  }
}

.catalog_main-block_filters_btns .main_btn {
  width: max-content;
  height: 38px;
}

.catalog_main-block_filters_btns .main_btn:hover {
  background: #fff;
  color: #3B9ED4;
  border: 1px solid #3B9ED4;
}

.catalog_main-block_filters_btns .btn-group__wrapper {
  display: none;
}

@media (min-width: 480px) {
  .catalog_main-block_filters_btns .btn-group__wrapper {
    display: inline-flex;
  }
}

.catalog_main-block_filters_second-row_select {
  display: flex;
}

.catalog_main-block_filters_second-row_select,
.catalog_main-block_filters_second-row_select_wrapper {
  align-items: center;
  gap: 10px;
}

.catalog_main-block_filters_second-row_select_wrapper {
  display: none;
}

@media (min-width: 1024px) {
  .catalog_main-block_filters_second-row_select_wrapper {
    display: flex;
  }
}

.catalog_main-block_filters_second-row_select_elem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  padding: 0.5em;
  border: 1px solid #DDD;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  max-width: 127px;
}

.catalog_main-block_filters_second-row_search {
  width: 100%;
}

.catalog_main-block_filters_second-row_modal {
  position: absolute;
  background-color: white;
  top: 30px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  min-width: -moz-max-content;
  min-width: max-content;
  cursor: pointer;
  z-index: 5;
  max-height: 280px;
  overflow: auto;
  width: 100%;
  left: 0;
}

.catalog_main-block_filters_second-row_modal .filter_toggle_type_object {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter_toggle_type_item {
  padding: 0.5em;
  border: 1px solid #ddd;
}

.filter_toggle_type_item:first-child {
  border-radius: 10px 0 0 10px;
}

.filter_toggle_type_item:last-child {
  border-radius: 0 10px 10px 0;
}

.filter_toggle_type_item.active_filter {
  background: #3B9ED4;
  border: 1px solid #3B9ED4;
  color: #fff;
}

.catalog_main-block_filters_second-row_modal .filters_group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.catalog_main-block_filters_second-row_modal.filters-number-group {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 10px;
}

.catalog_main-block_filters_second-row_modal div {
  display: flex;
  flex-direction: column;
}

.dop_row_main-menu {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 10px 40px;
  gap: 10px;
  border-bottom: 1px solid #dddddd87;
  background-color: white;
  z-index: 13;
  top: 35px;
  width: 100%;
}

@media (min-width: 1350px) {
  .dop_row_main-menu {
    padding: 10px 80px;
  }
}

.menu_top_fixed {
  position: sticky;
  top: 0;
  z-index: 10;
}

.modal_select_city_elem .selected {
  color: #3B9ED4;
  font-weight: bold;
}

.modal_select_city_header_edit_btns {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.modal_select_city_header_edit_btn {
  background: #3B9ED4;
  color: #fff;
  padding: 0.5em;
  border-radius: 10px;
  font-size: 16px;
  line-height: 21px;
  cursor: pointer;
}

.modal_select_city_header_edit_btn:hover {
  opacity: 0.7;
}

.active_param {
  color: #3B9ED4;
  font-weight: bold;
}

.main_menu_elem.img_logo.menu-top__logo-wrapper {
  position: relative;
  height: 30px;
  width: 170px;
}

.main_menu_elem.img_logo div {
  overflow: hidden;
  position: relative;
  display: flex;
  height: 40px;
  width: 40px;
  pointer-events: none;
  display: none;
}

@media (min-width: 360px) {
  .main_menu_elem.img_logo div {
    display: flex;
  }
}

.main_menu_elem.img_logo div svg, .main_menu_elem.img_logo div img {
  width: 100%;
  height: 100%;
  max-width: none;
}

@media (max-width: 540px) {
  .main_menu_elem.img_logo.menu-top__logo-wrapper {
    width: 130px;
  }
}

@media (max-width: 360px) {
  .main_menu_elem.img_logo.menu-top__logo-wrapper {
    width: 100%;
  }
}

.modal_select_city_header_list_filters_popular {
  flex-direction: column;
}

.popular_list {
  display: flex;
}

.popular_list span {
  color: gray;
}

.catalog_main-block_filters_second-row_select_elem.disabled {
  pointer-events: none;
  background-color: #d4d4d4;
}
</style>
