import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {createPinia} from 'pinia'

import components from '@/components/UI/index.js';

const app = createApp(App)

Object.keys(components).forEach((component) => {
  app.component(components[component].name, components[component])
})

app
  .use(store)
  .use(router)
  .use(createPinia())
  .mount('#app')
