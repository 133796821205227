import MainButton from "@/components/UI/MainButton";
import MainLabelCheckBox from "@/components/UI/MainLabelCheckBox";
import MainInputNumber from "@/components/UI/MainInputNumber";
import BreadCrumbs from "@/components/UI/BreadCrumbs";
import MainInputText from "@/components/UI/MainInputText";

export default [
  MainButton,
  MainLabelCheckBox,
  MainInputNumber,
  BreadCrumbs,
  MainInputText,
]
