<template>
  <div class="breadcrumbs" v-if="this.breadcrumbList">

    <div v-if="objectInfo">


      <div v-if="objectInfo.type_property == 1"><span class="breadcrumbs_link" @click="setOnlyOneDopParam('type_property', 1, 1)">Жилая недвижимость</span><span>/</span>
      </div>
      <div v-if="objectInfo.type_property == 2"><span class="breadcrumbs_link" @click="setOnlyOneDopParam('type_property', 2, 2)">Коммерческая недвижимость</span><span>/</span>
      </div>

      <div v-if="objectInfo.type_property == 1 && objectInfo.sell_type == 2">


        <div v-if="objectInfo.object_type == 1"><span @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 1, objectInfo.type_property)" class="breadcrumbs_link">Квартиры</span><span>/</span></div>
        <div v-if="objectInfo.object_type == 2"><span @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 2, objectInfo.type_property)" class="breadcrumbs_link">Комнаты</span><span>/</span></div>
        <div v-if="objectInfo.object_type == 3">
          <span @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 3, objectInfo.type_property)" class="breadcrumbs_link">Койко-место</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 4"><span @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 5, objectInfo.type_property)"
            class="breadcrumbs_link">Дома/Дачи</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 5"><span class="breadcrumbs_link"
                                                      @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 5, objectInfo.type_property)">Коттеджи</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 6"><span class="breadcrumbs_link"
                                                      @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 5, objectInfo.type_property)">Таунхаусы</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 7"><span class="breadcrumbs_link"
                                                      @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 5, objectInfo.type_property)">Часть дома</span><span>/</span>
        </div>


      </div>

      <div v-if="objectInfo.type_property == 2 && objectInfo.sell_type == 2">


        <div v-if="objectInfo.object_type == 1"><span class="breadcrumbs_link" @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 1, objectInfo.type_property)">Офисы</span><span>/</span></div>
        <div v-if="objectInfo.object_type == 2"><span class="breadcrumbs_link" @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 2, objectInfo.type_property)">Коворкинг</span><span>/</span></div>
        <div v-if="objectInfo.object_type == 3">
          <span class="breadcrumbs_link" @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 3, objectInfo.type_property)">Здание</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 4"><span
            class="breadcrumbs_link" @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 4, objectInfo.type_property)">Торговая площадь</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 5"><span class="breadcrumbs_link"
                                                      @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 5, objectInfo.type_property)">Помещение свободного назначения</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 6"><span class="breadcrumbs_link"
                                                      @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 6, objectInfo.type_property)">Производство</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 7"><span class="breadcrumbs_link"
                                                      @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 7, objectInfo.type_property)">Часть Склад</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 8"><span class="breadcrumbs_link"
                                                      @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 8, objectInfo.type_property)">Гараж</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 9"><span class="breadcrumbs_link"
                                                      @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 9, objectInfo.type_property)">Коммерческая земля</span><span>/</span>
        </div>


      </div>

      <div v-if="objectInfo.type_property == 1 && objectInfo.sell_type == 3">


        <div v-if="objectInfo.object_type == 1"><span class="breadcrumbs_link" @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 1, objectInfo.type_property)">Квартиры</span><span>/</span></div>
        <div v-if="objectInfo.object_type == 3"><span class="breadcrumbs_link" @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 3, objectInfo.type_property)">Комнаты</span><span>/</span></div>
        <div v-if="objectInfo.object_type == 4">
          <span class="breadcrumbs_link" @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 4, objectInfo.type_property)">Доля в квартире</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 5"><span @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 5, objectInfo.type_property)"
            class="breadcrumbs_link">Дома/Дачи</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 6"><span class="breadcrumbs_link"
                                                      @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 5, objectInfo.type_property)">Коттеджи</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 7"><span class="breadcrumbs_link"
                                                      @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 5, objectInfo.type_property)">Таунхаусы</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 8"><span class="breadcrumbs_link"
                                                      @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 5, objectInfo.type_property)">Часть дома</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 9"><span class="breadcrumbs_link"
                                                      @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 5, objectInfo.type_property)">Участки</span><span>/</span>
        </div>


      </div>


      <div v-if="objectInfo.type_property == 2 && objectInfo.sell_type == 3">


        <div v-if="objectInfo.object_type == 1"><span class="breadcrumbs_link" @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 1, objectInfo.type_property)">Офисы</span><span>/</span></div>
        <div v-if="objectInfo.object_type == 2"><span class="breadcrumbs_link" @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 2, objectInfo.type_property)">Здания</span><span>/</span></div>
        <div v-if="objectInfo.object_type == 3" @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 3, objectInfo.type_property)">
          <span class="breadcrumbs_link">Торговая площадь</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 4"><span @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 4, objectInfo.type_property)"
            class="breadcrumbs_link">Помещение свободного назначения</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 5"><span class="breadcrumbs_link"
                                                      @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 5, objectInfo.type_property)">Производство</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 6"><span class="breadcrumbs_link"
                                                      @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 6, objectInfo.type_property)">Склады</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 7"><span class="breadcrumbs_link"
                                                      @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 7, objectInfo.type_property)">Гаражи</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 8"><span class="breadcrumbs_link"
                                                      @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 8, objectInfo.type_property)">Бизнесы</span><span>/</span>
        </div>
        <div v-if="objectInfo.object_type == 9"><span class="breadcrumbs_link"
                                                      @click="setDopOptionsFiltersWithTypeObject(objectInfo.sell_type, 9, objectInfo.type_property)">Коммерческая земля</span><span>/</span>
        </div>


      </div>


      <div><span class="breadcrumbs_link">Объявление № {{ objectInfo.id }}</span><span></span></div>
    </div>
    <div v-else>
      <div v-for="(crumb, index) in this.breadcrumbList" @click="$router.push(`${crumb.link}`)">
        <span class="breadcrumbs_link">{{ crumb.name }}</span><span>/</span>
      </div>
    </div>


  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: 'BreadCrumbs',
  props: ["objectInfo"],
  components: {},
  data() {
    return {}
  },
  methods: {
    async setOnlyOneDopParam(param, value, filter_type_property) {
      let mutation = 'set_filter_' + param;
      this.resetAllDopFilters()
          .then(res => {
            this[mutation](value);
            this.set_filter_type_property(filter_type_property); // Тип недвижимости недвижимость
          })
          .then(res => {
            this.$router.push(`/`);
          })
          .then(res => {
            this.$emit('updateCatalog');
          })
    },
    async resetAllDopFilters() {
      this.set_filter_sell_type(null);
      this.set_filter_time_limit(null);
      this.set_filter_object_type(null);
      this.set_filter_sell_selling_type(null);
      this.set_filter_type_property(null);
      return true;
    },
    async setDopOptionsFilters(filter_sell_type, filter_time_limit, filter_type_property) {
      this.resetAllDopFilters()
          .then(res => {
            this.set_filter_sell_type(filter_sell_type);
            this.set_filter_time_limit(filter_time_limit);
            this.set_filter_type_property(filter_type_property);
          })
          .then(res => {
            this.$router.push(`/`);
          })
    },
    async setDopOptionsFiltersWithoutSellType(filter_object_type, filter_sell_selling_type, filter_type_property) {
      this.resetAllDopFilters()
          .then(res => {
            this.set_filter_object_type(filter_object_type);
            this.set_filter_sell_selling_type(filter_sell_selling_type);
            this.set_filter_type_property(filter_type_property);
          })
          .then(res => {
            this.$router.push(`/`);
          })
    },

    async setDopOptionsFiltersWithTypeObject(filter_sell_type, filter_object_type, filter_type_property) {
      this.resetAllDopFilters()
          .then(res => {
            this.set_filter_sell_type(filter_sell_type);
            this.set_filter_object_type(filter_object_type);
            this.set_filter_type_property(filter_type_property);
          })
          .then(res => {
            this.$router.push(`/`);
          })
    },

    setDopOptionsFiltersWithTypeObjectAndSellingType(filter_sell_type, filter_object_type, filter_sell_selling_type, filter_type_property) {
      this.resetAllDopFilters()
          .then(res => {
            this.set_filter_sell_type(filter_sell_type);
            this.set_filter_object_type(filter_object_type);
            this.set_filter_sell_selling_type(filter_sell_selling_type);
            this.set_filter_type_property(filter_type_property);
          })
          .then(res => {
            this.$router.push(`/`);
          })
    },
    ...mapMutations({
      set_filter_time_limit: 'set_filter_time_limit',
      set_filter_sell_type: 'set_filter_sell_type',
      set_filter_object_type: 'set_filter_object_type',
      set_filter_sell_selling_type: 'set_filter_sell_selling_type',
      set_filter_type_property: 'set_filter_type_property',
    }),
  },
  computed: {
    ...mapState({
      breadcrumbList: state => state.breadcrumbList,
    }),
  },
  mounted() {
  },
  watch: {}
}
</script>

<style>
.breadcrumbs {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 20px;

}

.breadcrumbs div {
  display: flex;
  flex-wrap: wrap;
}

.breadcrumbs_link {
  color: #3B9ED4;
  cursor: pointer;
}

.breadcrumbs span:nth-of-type(2) {
  margin: 0 5px;
}
</style>
