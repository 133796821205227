<template>
  <button class="main_btn">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'MainButton',
}
</script>

<style>
  .main_btn {
    background: #3B9ED4;
    color: white;
    border-color: transparent;
    display: inline-block;
    cursor: pointer;
    padding: 0.5em;
    border-radius: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    transition: all .3s;
    width: 100%;
    max-width: 300px;
    font-size: 16px;
  }
</style>
