<template>
  <label className="check">
    <input class="check__input" type="checkbox" v-model="this.statusChecked[value]">
    <span class="check-custom"></span>
    {{ label }}
  </label>

</template>

<script>
export default {
  name: 'MainLabelCheckBox',
  props: {
    statusChecked: {type: [Object]},
    value: {type: [Number]},
    label: {type: String}
  },
}
</script>

<style>
</style>
