<template>
  <div class="filters">
    <swiper-container init="false" navigation="true" :space-between="20">
      <swiper-slide v-for="(elem, index) in filters" class="filters_elem" @click="handlerGlobalFilter(index + 1)" :class="{active: index + 1 === this.global_filters}">
        <img src="https://a0.muscache.com/pictures/ed8b9e47-609b-44c2-9768-33e6a22eccb2.jpg" alt="">
        <p>{{ elem.typ }}</p>
      </swiper-slide>
    </swiper-container>
    <div class="filters_btn_modal">
      <button @click="this.$emit('changeStatusFiltersModal', true)">
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" style="display: block; height: 14px; width: 14px; fill: currentcolor;"><path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path></svg>
        Фильтры
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import {register} from 'swiper/element/bundle';
register();
import { mapMutations, mapState, mapActions } from "vuex";
export default {
  emits: ["changeStatusFiltersModal"],
  components: {},
  props: {

  },
  data() {
    return {
      selectedFilter: '',
      filters: [],
    }
  },
  methods: {
    handlerGlobalFilter(value){
      if(value === this.global_filters){
        this.setGlobalFilters(0);
      }else{
        this.setGlobalFilters(value);
      }

      this.getLoadObjects({filters: this.filters})
          .then(res => {
            this.$emit('activateFilters', true)
          })

    },
    ...mapActions({
      getLoadObjects: 'getLoadObjects',
    }),
    ...mapMutations({
      setGlobalFilters: 'setGlobalFilters',
    }),
    async getFilters() {
      try {
        const response = await axios.get(this.url_backend + '?getFilters')

        this.filters = response.data;
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    setSwiper() {
      const swiperEl = document.querySelector('.filters swiper-container');

      const swiperParams = {
        slidesPerView: 1,
        breakpoints: {
          1: {
            slidesPerView: 2,
          },
          400: {
            slidesPerView: 4,
          },
          600: {
            slidesPerView: 6,
          },
          1024: {
            slidesPerView: 8,
          },
        },
        on: {
          init() {
          },
        },
        injectStyles: [
          `
            .swiper-button-next:after, .swiper-button-prev:after {
                color: black;
                font-size: 12px;
                padding: 6px 8px 6px 8px;
                border-radius: 80px;
                font-weight: bold;
                border: 1px solid #bebebe;
                background-color: white;
            }
            .swiper-button-disabled {
              display: none;
            }
          `,
        ],
      };
      Object.assign(swiperEl, swiperParams);
      swiperEl.initialize();
    },
  },
  computed: {
    ...mapState({
      global_filters: state => state.filters.global_filters,
      url_backend: state => state.url_backend,
    }),
  },
  mounted() {
    this.getFilters()
        .then(res => {
          if (!res) throw 'Ошибка при получении фильтров';
          this.setSwiper();
        })
        .catch(err => {
          console.log(err);
        })

  },
  watch: {
  }
}
</script>

<style>
.filters {
  display: flex;
  align-items: center;
  position: sticky;
  background: white;
  z-index: 4;
  box-shadow: 0px 2px 11px -8px;
  padding: 0 80px;
  top: 0px;
}
.filters_elem {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  flex-direction: column;
  transition: all .3s;
  border-bottom: 2px solid transparent;
  color: #757575;
  font-weight: 700;
}

.filters_elem img {
  width: 30px;
  filter: contrast(0.2);
  transition: all .3s;
}

.filters_elem:hover img {
  filter: none;
}
.filters_elem:hover {
  color: black;
  border-bottom: 2px solid rgb(221,221,221);
}

.filters_slider {
  min-height: 300px;
}

.swiper-initialized.swiper-horizontal {
  width: 90%;
}

.swiper-button-next, .swiper-button-prev {

}

.filters .swiper-button-next:after, .filters .swiper-button-prev:after {
  color: black;
}

.filters button {
  border: 1px solid #DDDDDD;
  padding: 15px 15px 15px 15px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  width: 110px;
  background: white;
}


@media (max-width: 750px) {
  .filters {
    top: 56px;
  }
}

@media (max-width: 805px) {
  .filters {
    padding: 0;
  }
}

.filters_elem.active {
  border-bottom: 2px solid rgb(0 0 0);
  color: black;
}
.filters_elem.active img{
  filter: none;
}

.swiper-slide-active {
  display: flex!important;
}
</style>
