<template>

  <MainMenu v-on:changeStatusModalEntry="changeStatusModalEntry"
            v-on:updateCatalog="updateCatalog"
            ref="main_menu"
  ></MainMenu>
<!--  <CatalogFilters v-on:changeStatusFiltersModal="changeStatusFiltersModal"-->
<!--                  v-on:activateFilters="activateFilters"></CatalogFilters>-->

  <div class="alert" v-if="this.globalAlerts.length">
    <transition-group name="slide-fade">
      <div v-for="alert in this.globalAlerts" :key="alert.id" :class="`${alert.type}`" @click="this.deleteGlobalAlert(alert.id)">
        <span>{{ alert.text }}</span>
        <span v-if="alert.objectID"><button @click.stop="removeObject(alert.objectID, alert.id)">Отменить</button></span>
      </div>
    </transition-group>
  </div>

  <transition name="fade">
    <div v-if="statusBackgroundModal" class="background_modal">
    </div>
  </transition>

  <router-view v-slot="{ Component }">
    <Transition name="fade-app">
      <component ref="active_page" :is="Component" v-on:changeStatusModalEntry="changeStatusModalEntry"
                 v-on:changeStatusFiltersModal="changeStatusFiltersModal" v-on:checkAuthorizationAndLoadInfo="checkEntry" v-on:openModalSelectCity="openModalSelectCity" v-on:openModalSelectDistrict="openModalSelectDistrict" v-if="!this.loading_authorization"></component>
    </Transition>
  </router-view>


  <transition name="slideBot">
    <ModalEntry ref="modal_entry" v-show="statusModalEntry"
                v-on:changeStatusModalEntry="changeStatusModalEntry"></ModalEntry>
  </transition>

</template>

<script>
import {mapMutations, mapState, mapActions} from "vuex";
import MainMenu from "@/components/MainMenu.vue";
import ModalEntry from "@/components/ModalEntry.vue";
import CatalogFilters from "@/components/CatalogFilters.vue";
import CatalogObjects from "@/components/CatalogObjects";
import axios from "axios";

export default {
  components: {
    MainMenu,
    ModalEntry,
    CatalogFilters,
  },
  data() {
    return {
      statusBackgroundModal: false,
      statusModalEntry: false,

    }
  },
  methods: {
    async removeObject(objectID, alertIDDelete) {
      try {
        const response = await axios.post(
          this.url_backend + '?removeObject',
          {
            params: {
              objectID: objectID,
              userID: this.userInfo.id,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )

        if(response.data == 'success'){
          this.deleteGlobalAlert(alertIDDelete);

          let alertID = Math.floor(Math.random() * 1000);
          let text_alert = 'Объект отправлен в архив';
          this.setGlobalAlerts({type: 'success', text: text_alert, id: alertID});
          setTimeout(() => {
            this.deleteGlobalAlert(alertID);
          }, "10000");

        }


      } catch (e) {
        console.log(e);
        return false;
      }
    },
    async changeStatusFiltersModal(bool){
      await this.$refs.main_menu.changeStatusFiltersModal(bool)
    },
    async activateFilters() {
      await this.$router.push(`/`);
      await this.updateCatalog();
      await this.$refs.main_menu.changeStatusFiltersModal(false);
    },
    updateCatalog(click_logo = false) {
      console.log(click_logo)
      if (this.$route.name === 'Catalog') {
        this.$refs.active_page.updateCatalog(click_logo);
      }
    },
    checkEntry(entry_key) {
      this.$refs.modal_entry.entry(entry_key)
          .then(res => {
            this.setLoadingAuthorization(false);
          })
          .then(res => {
            this.set_was_changed(false);
          })
    },
    ...mapMutations({
      setUserFavorites: 'setUserFavorites',
      setUserComparison: 'setUserComparison',
      setLoadingAuthorization: 'setLoadingAuthorization',
      setBreadcrumbList: 'setBreadcrumbList',
      set_was_changed: 'set_was_changed',
      set_settings: 'set_settings',
      deleteGlobalAlert: 'deleteGlobalAlert',
      setGlobalAlerts: 'setGlobalAlerts',
    }),
    openModalSelectCity() {
      this.$refs.main_menu.modalSelectCity();
    },
    openModalSelectDistrict() {
      this.$refs.main_menu.modalSelectDistrict();
    },
    changeStatusModalEntry(bool) {
      this.statusModalEntry = bool;
      this.statusBackgroundModal = bool;
    },
  },
  computed: {
    ...mapState({
      loading_authorization: state => state.loading_authorization,
      userInfo: state => state.userInfo,
      userComparison: state => state.userComparison,
      globalAlerts: state => state.globalAlerts,
      url_backend: state => state.url_backend,
    }),
  },
  watch: {
    '$route'() {
      this.setBreadcrumbList(this.$route.meta.breadcrumb);
    }
  },
  mounted() {
    if (localStorage.getItem("comparison")) {

      console.log(JSON.parse(localStorage.getItem("comparison")))

      this.setUserFavorites(JSON.parse(localStorage.getItem("favorites")))
    }
    if (localStorage.getItem("comparison")) {

      console.log(JSON.parse(localStorage.getItem("comparison")))

      this.setUserComparison(JSON.parse(localStorage.getItem("comparison")))
    }
    if (localStorage.entry_key) {
      this.checkEntry(localStorage.entry_key);
    } else {
      this.setLoadingAuthorization(false);
    }

    if (localStorage.getItem("language")) {
      this.set_settings({param: 'language', value: localStorage.getItem("language")});
      this.set_settings({param: 'language_img', value: localStorage.getItem("language_img")});
      this.set_settings({param: 'language_url', value: localStorage.getItem("language_url")});
      //this.$router.push('?locale=' + localStorage.getItem("language_url"));
      //this.$router.replace({ ...this.$router.currentRoute, query: this.filters });
    }

  },
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
    margin: 0;
}

#app {
  font-family: "Roboto", sans-serif;
  color: #4A556D;
}

button {
  cursor: pointer;
}

.check {
  position: relative;
  padding-left: 24px;
}

.check-custom {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid #4A556D;
  border-radius: 5px;
}

.check__input {
  position: absolute;
  height: 100%;
  margin: -1px;
  border: none;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.check__input:checked + .check-custom {
  border: 2px solid #1b99d5;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.84561 1.33021C7.90291 1.2538 7.94461 1.16686 7.96831 1.07435C7.99201 0.981836 7.99726 0.885559 7.98376 0.791017C7.97025 0.696474 7.93825 0.605518 7.88959 0.523341C7.84093 0.441164 7.77656 0.369375 7.70016 0.312074C7.62375 0.254772 7.5368 0.21308 7.44429 0.189379C7.35177 0.165677 7.25549 0.160429 7.16094 0.173935C7.06639 0.187441 6.97543 0.219436 6.89325 0.268094C6.81107 0.316752 6.73928 0.38112 6.68197 0.457521L2.82161 5.60491L1.23252 4.01589C1.09535 3.88342 0.911642 3.81012 0.720954 3.81178C0.530265 3.81344 0.347856 3.88992 0.213014 4.02475C0.0781712 4.15959 0.00168453 4.34199 2.74927e-05 4.53267C-0.00162954 4.72335 0.0716759 4.90705 0.204155 5.04421L2.38597 7.22592C2.45965 7.29959 2.54829 7.35656 2.6459 7.393C2.74351 7.42943 2.8478 7.44446 2.95173 7.43709C3.05565 7.42971 3.15678 7.40009 3.24826 7.35023C3.33975 7.30037 3.41945 7.23144 3.48197 7.14811L7.84561 1.33021Z' fill='white'/%3e%3c/svg%3e ");
  background-color: #1b99d5;
  background-repeat: no-repeat;
  background-position: center;
}

h1 {
  font-size: 26px;
}

h2 {
  font-size: 20px;
  font-weight: bold;
}

h3 {
  font-size: 16px;
  font-weight: bold;
}

input {
  background-color: #fff;
}

input {
  border-radius: 5px;
}

.dot {
  margin: 0 5px;
}

.small_text {
  font-size: 14px;
  color: #717171;
}

.text_underline {
  text-decoration: underline;
}

.fade-app-enter-active {
  transition: all 0.5s ease;
}

.fade-app-enter-from,
.fade-app-leave-to {
  opacity: 0;
}

.fade-app-leave-active {
  position: absolute;
  transition: none;
}

.slideBot-enter-active,
.slideBot-leave-active {
  transition: all .5s ease;
}

.slideBot-enter-from,
.slideBot-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

.background_modal {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(34 34 34 / 60%);
  z-index: 10;
}

.fade-enter-active,
.fade-leave-active {
  transition: all .5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

svg {
  cursor: pointer;
}

picture {
  display: flex;
  align-items: center;
  justify-content: center;
}


.slideTop-enter-from { /* Элемент появляется */
  transform: translateY(-100%);
  opacity: 0;
}

.slideTop-enter-active { /* Элемент появляется активен */
  transition: all .5s ease;
}

.slideTop-enter-to { /* Элемент Появился */
  transform: translateY(0px);
  opacity: 1;
}

.slideTop-leave-from { /* Элемент Начинает исчезать */
  transition: all 0.5s ease;
  opacity: 1;
}

.slideTop-leave-active { /* Элемент исчезает активен */
  position: absolute;
  transition: all 0.5s ease;
}

.slideTop-leave-to { /* Элемент исчезает */
  transform: translateY(-100%);
  opacity: 0;
}

.alert {
  position: fixed;
  right: 10px;
  bottom: 60px;
  width: 230px;
  padding: 10px;
  z-index: 999;
  text-align: start;
}

.alert div {
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #f9f9f9;
}

.alert button {
  background: #3B9ED4;
  color: white;
  border-color: transparent;
  display: inline-block;
  cursor: pointer;
  padding: 0.5em;
  border-radius: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: all .3s;
  width: 100%;
  max-width: 300px;
  font-size: 16px;
}

/*.alert .success {
  background: rgba(26, 255, 0, 0.4);
}

.alert .error {
  background: rgba(255, 0, 0, 0.4);
}*/

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.object_main_content_block_facilities_columns img{
  width: 24px;
}

.page_padding_bot {
  padding-bottom: 50px!important;
}
</style>
