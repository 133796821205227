<template>
    <div class="map-div" style="display: flex;">
      <div id="map" style="width: 100%; height: 100%;">
        <yandex-map
            zoom="8"
            style="width: 100%; max-width: 100%; height: 98vh;"
            :coords="coords"
            :settings="map_settings"
        >
          <ymap-marker
              v-for="(object, index) in objects"
              marker-type="placemark"
              :balloon-template="mapBalloon(object.objectInfo, index)"
              :coords="getArrayCoordinates(object.objectInfo.coordinates)"
              :marker-id="index"
              :key="index"
          ></ymap-marker>
        </yandex-map>
      </div>
  </div>
</template>

<script>

import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import {mapActions, mapMutations, mapState} from "vuex";
import axios from 'axios'

export default {
  components: {
    yandexMap,
    ymapMarker
  },
  props:[
      'objects'
  ],
  data() {
    return {
      map_settings: {
        apiKey: 'bb0edaf1-1c50-4c19-b42e-1dbc6906d337',
        lang: 'ru_RU',
        version: '2.1',
      },


      coords: [41.2995, 69.24007],
      options: {
        layout: "default#image",
        imageSize: [30, 40],
        contentOffset: [0, 0]
      },
      selectedSurfaces: this.$selectedSurfaces,
      layout:
          "<div>{{ properties.balloonContentHeader }}</div><div>{{ properties.balloonContentBody }}</div><div>{{ properties.balloonContentFooter }}</div>",
    }
  },
  methods: {
    checkMetro(metro) {
      if (metro) {
        return "<div class='baloon-time'>" + metro + " мин</div>"
      } else {
        return "";
      }
    },
    getCurrencyCourse(object_price, currencyID, selected_currency_from_USD) {
      let object_currency_USD;
      for (let i in this.list_of_currency) {
        if (this.list_of_currency[i].id == currencyID) {
          object_currency_USD = Number(this.list_of_currency[i].from_USD) * Number(object_price);
        }
      }
      return (object_currency_USD * Number(selected_currency_from_USD)).toFixed(2);
    },
    parseObjPhotos(obj) {
      if (obj === '{}') {
        return 'no_photo.jpg';
      } else {
        let responde = JSON.parse(obj);
        return this.takeIndexPhotoOne(responde);
      }
    },
    takeIndexPhotoOne(array) {
      let keysName = Object.keys(array);
      return array[keysName[0]];
    },
    getArrayCoordinates(coords){
      let res = [];
      let string_coords = coords.split(' ');
      res.push(Number(string_coords[1]));
      res.push(Number(string_coords[0]));
      return res;
    },
    selectedBillboard(billboard) {
      let data = {
        SURFACEID: billboard.surface_id,
        NETWORKID: billboard.networkid
      };
      return data;
    },
    makeSurfaceSelected: function(surface_id) {
      this.surfaces.forEach(surface => {
        if (surface.surface_id === surface_id) {
          surface.selected = true;
        }
      });
    },
    mapBalloon(object, index) {
      return `<div class="baloon">
        <div class="baloon-top">
          <div class="baloon-image">
            <picture>
              <source srcset="${this.url_backend_img}${object.user_id}/${object.photos[0].fname}-preview.webp" type="image/webp">
              <img src="${this.url_backend_img}${object.user_id}/${object.photos[0].fname}-preview.png" alt="">
            </picture>
            <div class="baloon-top-top">
              <div>` + object.city + ", " + object.street + ", " + + object.home_num +`</div>
              <i class="fa fa-heart-o" aria-hidden="true"></i>
            </div>
          </div>
          <div class="baloon-top-bottom">
            <div class="baloon-price">` + this.getCurrencyCourse(object.price, object.currency, this.settings.currency_from_usd) + " " + this.settings.currency_symbol + `</div>
            <div class="baloon-S" style="display: none"> м²</div>
          </div>
        </div>
        <div class="baloon-middle">
          <dvi class="baloon-location" style="display: none">` + object.metro_limit + `</dvi>
          <i class="fa fa-blind" aria-hidden="true"></i>
          До метро:
          ` + this.checkMetro(object.metro_limit) + `
        </div>
        <div class="more-info" onclick="window.open('object/` + object.id + `')">
          Подробнее о квартире
        </div>
      </div>`;
    },
  },
  computed: {
    ...mapState({
      url_backend: state => state.url_backend,
      url_backend_img: state => state.url_backend_img,
      settings: state => state.settings,
      list_of_currency: state => state.list_of_currency,
    }),
  },
  mounted() {
  },
  watch: {
  }
}
</script>

<style>

.filter-block {
  min-height: 50px;
  width: auto;

  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
}

.filter-podblock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.fifty-cent {
  width: max-content;
}

.filter-panel {
  margin: 10px;
  display: inline-block;
  color: #4A556D;
}

.filter-panel-panel {
  display: inline-block;
  margin-left: 20px;
  padding: 5px;

  cursor: pointer;
}

.filter-panel-panel i, .filter-panel-panel div {
  display: inline-block;
}

.search-form {
  width: auto;
  max-width: 600px;

  display: flex;
  justify-content: right;
  flex-grow: 2;
  align-items: center;
}

.open-filter {
  width: 30px;
  height: 30px;

  vertical-align: middle;
  text-align: center;

  display: flex;
  align-items: center;

  cursor: pointer;
}

.open-filter i {
  margin: auto;
}

.search-block {
  width: 100%;

  display: flex;
  align-items: center;
}

.search-block form {
  width: 100%;
  position: relative;
  display: flex;
}

.search-block input[type="text"] {
  width: 100%;
  margin: 0 10px;
  height: 40px;
  padding: 0 10px;
  padding-left: 30px;

  border: 0px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #a1a1a1;

  font-family: Roboto;
  font-size: 18px;

  background: url('@/assets/search.png') no-repeat scroll 7px 10px, #F6F6F6;

  outline: none;
}

.btn-search, .more-info, .UpdateMap, .view-map, .open-in-new-project {
  padding: 10px;

  border: none;
  background-color: #1B99D5;
  border-radius: 6px;
  color: white;

  cursor: pointer;
}

.more-info {
  background: #3B9ED4;
  color: white;
  border-color: transparent;
  display: inline-block;
  cursor: pointer;
  padding: 0.5em;
  border-radius: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: all .3s;
  width: 100%;
  max-width: 300px;
  font-size: 14px;
  text-align: center;
}

.btn-search:hover, .more-info:hover, .UpdateMap:hover, .view-map:hover, .open-in-new-project:hover {
  background-color: #156e99;
}

.baloon {
  width: 200px;
  height: 250px;
  padding: 10px;
  padding-top: 15px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  background-color: #f1f2f2;
  border-radius: 5px;
  color: #4A556D;
}

.baloon-top {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.baloon-top-top {
  width: calc(200px - 10px);

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.baloon-top-top div {
  background-color: #ffffffab;
  padding: 5px;
  border-radius: 5px;
  max-width: 150px;
}

.baloon-image {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.baloon-image img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  max-width: 100px;
}

.baloon-top-bottom, .baloon-middle {
  width: 100%;
  display: flex;
  justify-content: center;
}

.baloon-price {
  font-weight: bold;
}

.baloon-location, .baloon-time {
  font-size: 14px;
}

.map-div {
  width: 100%;
  height: 100%;
  min-height: 400px;
}

.edit-map {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.left-box {
  width: 50%;

  display: flex;
  justify-content: flex-end;
}

.right-box {
  width: 50%;

  display: flex;
}

.map-arrows, .map-size, .map-instruments {
  height: 30px;
  padding: 5px;
  margin-right: 20px;

  display: flex;
  justify-content: space-around;
  align-items: center;

  color: #4A556D;
  background-color: white;
  box-shadow: 0px 0px 5px #a1a1a1;
  border-radius: 5px;

  font-size: 20px;
}

.arrow, .map-size div, .map-instruments div {
  margin: 5px;

  cursor: pointer;
}

.filter {
  margin: 20px auto;
  width: 100%;
  max-width: 800px;

  font-family: Roboto;
  color: #4A556D;

  overflow: hidden;

  justify-content: center;
}

.filter form {
  padding: 10px;

  border: 0px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #a1a1a1;

  max-width: 800px;
  max-height: 550px;

  overflow-y: scroll;
}

.wrap {
  flex-wrap: wrap;
}

.filter-box {
  position: relative;
  margin-bottom: 30px;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 690px) {
  .filter-box {
    flex-direction: column;
  }
}

.filter-title {
  margin-right: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.filter-inp-block {
  margin: 0 15px 0 5px;

  display: flex;
  justify-content: flex-start;

  box-shadow: 0px 0px 5px #a1a1a1;
  border-radius: 5px;

  overflow: hidden;
}

.filter-radio-block {
  margin: 0 20px;
}

.filter-input {
  width: 100%;
  height: 30px;

  border: none;

  font-family: Roboto;
  font-size: 16px;

  outline: none;
}

.icon-filter {
  height: 32px;
  padding-right: 5px;

  display: flex;
  align-items: center;

  background-color: white;
  border: none;
}

.filter-select-block {
  width: 100%;
}

.select2-container, .select2-container--default {
  width: 100% !important;
}

.filter-radio-block input {
  display: none;
}

.filter-radio-block label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding: 10px;
  margin-right: 0;
  line-height: 18px;
  user-select: none;
  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
  margin: 5px auto;
}

.filter-radio-block input[type=radio]:checked + label {
  background-color: #1B99D5;
  border: none;
  color: white;
}

.filter-check-block input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.filter-check-block input + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  margin: 5px auto;
}

.filter-check-block input + label::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.filter-check-block input:checked + label::before {
  border-color: #0b76ef;
  background-color: #1B99D5;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.ymaps-2-1-79-balloon__content, .ymaps-2-1-79-balloon__layout {
  background: transparent !important;
}

.ymaps-2-1-79-balloon {
  box-shadow: none !important;
}

.ymaps-2-1-79-balloon__close {
  top: 10px;
  right: 40px;
}

.object-lists {
  padding: 10px;

  width: 300px;

  background: #f1f2f2;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  overflow-y: auto;
}

@media screen and (max-width: 800px) {
  .object-lists {
    display: none;
  }
}

.list-top {
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 5px;
  background: white;
  color: #4A556D;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 18px;
}

.list-left {
  display: flex;
}

.list-simbol {
  height: 15px;
  width: 10px;

  border-bottom: 5px solid #4A556D;
  border-left: 5px solid #4A556D;
}

.list-title {
  height: auto;
  margin: 0 10px;

  font-size: 13px;
}

.open-list {
  color: #4A556D;

  font-size: 40px;
  transform: rotate(90deg);
  transition: .5s;

  cursor: pointer;
}

.open-list.active {
  transform: rotate(0deg);
  transition: .5s;
}

.objects-ul {
  padding-left: 10px;
}

.objects {
  display: none;
}

.object {
  display: flex;
  flex-direction: column;
  align-items: center;

  list-style-type: none;

  position: relative;
}

.object-image img {
  width: 100%;
  height: auto;

  border-radius: 5px;
}

.object-list {
  margin-bottom: 5px;
}

.other-obj-data {
  position: absolute;
  padding: 3px;

  background: #ffffffd1;
  border-radius: 3px;
  margin: 3px;

  cursor: pointer;
}

.other-obj-data-bottom {
  display: flex;
  justify-content: space-between;
}

.view-map, .open-in-new-project {
  width: 200px;
  margin: 5px 0;
}

.repair_lvl {
  cursor: pointer;
}

.repair_lvl .active {
  color: #ffd230;
}

.selectize-dropdown .selected {
  background-color: #1b99d5 !important;
}

.selectize-control.multi .selectize-input [data-value] {
  background-color: #1b99d5 !important;
}

.Favourites_btn {
  font-family: Roboto;
  color: #4A556D;
  border: none;
  background-color: #e3e6e8;
  font-size: 16px;
  cursor: pointer;
}

.selected {
  display: inline-block;
}

.selected > div {
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
}

.selected > div:hover {
  color: #FB6589;
}

.select_input {
  display: inline-block;
  border: none;
  font-family: Roboto;
  outline: none;
  font-size: 16px;
  height: 30px;
  box-shadow: 0px 0px 5px #a1a1a1;
  border-radius: 5px;
  width: calc(100% - 10px);
  padding: 5px;
  margin-right: -40px;
  background-color: white;
  line-height: 31px;
}

.btn_openlist_select {
  display: inline-block;
  cursor: pointer;
  background-color: white;
  height: 38px;
  line-height: 28px;
  width: 5%;
  min-width: 30px;
  text-align: center;
  border-radius: 5px;
  font-size: 30px;
  font-weight: 900;
}

.options_div {
  display: block;
  background-color: white;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  overflow-y: auto;
  max-height: 120px;
  width: 100%;
}

.option_select.active {
  background-color: #1B99D5;
  color: white;
}

.option_select {
  cursor: pointer;
  padding: 5px;
}

.option_select:hover:not(.active) {
  background-color: #7ed5ff;
}

.ymap-container {
  height: calc(100vh - 155px)!important;
  max-height: 100%!important;
}

@media screen and (max-width: 800px) {
  .ymap-container {
    height: calc(100vh - 176px)!important;
    max-height: 100%!important;
  }
}
</style>
