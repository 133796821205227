<template>
  <div @click="this.$emit('changeStatusFiltersModal', false)" class="filters_modal">
    <div @click.stop="" class="filters_modal_content">
      <div class="filters_modal_content_header">
        <h4>Фильтры</h4>
        <svg @click="this.$emit('changeStatusFiltersModal', false)" viewBox="0 0 32 32"
             xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
             focusable="false"
             style="display: block; fill: none; height: 24px; width: 24px; stroke: currentcolor; stroke-width: 3; overflow: visible;">
          <path d="m6 6 20 20"></path>
          <path d="m26 6-20 20"></path>
        </svg>
      </div>

      <div class="filters_modal_content_filters">
        <div class="filters_modal_content_filters_row">
          <p>Тип сделки:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="radio-3" type="radio" v-model="filters.sell_type" value="3">
              <label for="radio-3">Продажа</label>
            </div>
            <div class="radio_btn">
              <input id="radio-2" type="radio" v-model="filters.sell_type" value="2">
              <label for="radio-2">Аренда</label>
            </div>
          </div>
        </div>


        <div class="filters_modal_content_filters_row">
          <p>Тип недвижимости:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="radio-type-1" type="radio" v-model="filters.type_property" value="1">
              <label for="radio-type-1">Жилая</label>
            </div>
            <div class="radio_btn">
              <input id="radio-type-2" type="radio" v-model="filters.type_property" value="2">
              <label for="radio-type-2">Коммерческая</label>
            </div>
          </div>
        </div>


        <div class="filters_modal_content_filters_row"
             v-if="filters.sell_type == 2 && filters.type_property == 1">
          <p>Тип объекта:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="radio-3-1" @click="resetParamMainBlock(1)" type="radio"
                     v-model="this.filters_main_block[1]" value="true">
              <label for="radio-3-1">Квартира</label>
            </div>

            <div class="radio_btn">
              <input id="radio-3-11" @click="resetParamMainBlock(11)" type="radio"
                     v-model="this.filters_main_block[11]" value="true">
              <label for="radio-3-11">Апартаменты</label>
            </div>

            <div class="radio_btn">
              <input id="radio-3-2" @click="resetParamMainBlock(2)" type="radio"
                     v-model="this.filters_main_block[2]" value="true">
              <label for="radio-3-2">Комната</label>
            </div>
            <div class="radio_btn">
              <input id="radio-3-3" @click="resetParamMainBlock(3)" type="radio"
                     v-model="this.filters_main_block[3]" value="true">
              <label for="radio-3-3">Койко-место</label>
            </div>
            <div class="radio_btn">
              <input id="radio-3-4" @click="resetParamMainBlock(4)" type="radio"
                     v-model="this.filters_main_block[4]" value="true">
              <label for="radio-3-4">Дом/Дача</label>
            </div>
            <div class="radio_btn">
              <input id="radio-3-5" @click="resetParamMainBlock(5)" type="radio"
                     v-model="this.filters_main_block[5]" value="true">
              <label for="radio-3-5">Коттедж</label>
            </div>
            <div class="radio_btn">
              <input id="radio-3-6" @click="resetParamMainBlock(6)" type="radio"
                     v-model="this.filters_main_block[6]" value="true">
              <label for="radio-3-6">Таунхаус</label>
            </div>
            <div class="radio_btn">
              <input id="radio-3-7" @click="resetParamMainBlock(7)" type="radio"
                     v-model="this.filters_main_block[7]" value="true">
              <label for="radio-3-7">Часть дома</label>
            </div>
            <div class="radio_btn">
              <input id="radio-3-9" @click="resetParamMainBlock(9)" type="radio"
                     v-model="this.filters_main_block[9]" value="true">
              <label for="radio-3-9">Земельный участок</label>
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row"
             v-if="filters.sell_type == 2 && filters.type_property == 2">
          <p>Тип объекта:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="radio-comm-3-1" @click="resetParamMainBlock(1)" type="radio"
                     v-model="this.filters_main_block[1]" value="true">
              <label for="radio-comm-3-1">Офис</label>
            </div>
            <div class="radio_btn">
              <input id="radio-comm-3-2" @click="resetParamMainBlock(2)" type="radio"
                     v-model="this.filters_main_block[2]" value="true">
              <label for="radio-comm-3-2">Здание</label>
            </div>
            <div class="radio_btn">
              <input id="radio-comm-3-3" @click="resetParamMainBlock(3)" type="radio"
                     v-model="this.filters_main_block[3]" value="true">
              <label for="radio-comm-3-3">Торговая площадь</label>
            </div>
            <div class="radio_btn">
              <input id="radio-comm-3-4" @click="resetParamMainBlock(4)" type="radio"
                     v-model="this.filters_main_block[4]" value="true">
              <label for="radio-comm-3-4">Помещение свободного назначения</label>
            </div>
            <div class="radio_btn">
              <input id="radio-comm-3-5" @click="resetParamMainBlock(5)" type="radio"
                     v-model="this.filters_main_block[5]" value="true">
              <label for="radio-comm-3-5">Производство</label>
            </div>
            <div class="radio_btn">
              <input id="radio-comm-3-6" @click="resetParamMainBlock(6)" type="radio"
                     v-model="this.filters_main_block[6]" value="true">
              <label for="radio-comm-3-6">Склад</label>
            </div>
            <div class="radio_btn">
              <input id="radio-comm-3-7" @click="resetParamMainBlock(7)" type="radio"
                     v-model="this.filters_main_block[7]" value="true">
              <label for="radio-comm-3-7">Гараж</label>
            </div>
            <div class="radio_btn">
              <input id="radio-comm-3-8" @click="resetParamMainBlock(8)" type="radio"
                     v-model="this.filters_main_block[8]" value="true">
              <label for="radio-comm-3-8">Коворкинг</label>
            </div>
            <div class="radio_btn">
              <input id="radio-comm-3-9" @click="resetParamMainBlock(9)" type="radio"
                     v-model="this.filters_main_block[9]" value="true">
              <label for="radio-comm-3-9">Коммерческая земля</label>
            </div>
            <div class="radio_btn">
              <input id="radio-comm-3-11" @click="resetParamMainBlock(11)" type="radio"
                     v-model="this.filters_main_block[11]" value="true">
              <label for="radio-comm-3-11">Апартаменты</label>
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row"
             v-if="filters.sell_type == 3 && filters.type_property == 1">
          <p>Тип объекта:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="radio-3-1" @click="resetParamMainBlock(1)" type="radio"
                     v-model="this.filters_main_block[1]" value="true">
              <label for="radio-3-1">Квартира</label>
            </div>
            <div class="radio_btn">
              <input id="radio-3-11" @click="resetParamMainBlock(11)" type="radio"
                     v-model="this.filters_main_block[11]" value="true">
              <label for="radio-3-11">Апартаменты</label>
            </div>
            <div class="radio_btn">
              <input id="radio-3-3" @click="resetParamMainBlock(3)" type="radio"
                     v-model="this.filters_main_block[3]" value="true">
              <label for="radio-3-3">Комната</label>
            </div>
            <div class="radio_btn">
              <input id="radio-3-4" @click="resetParamMainBlock(4)" type="radio"
                     v-model="this.filters_main_block[4]" value="true">
              <label for="radio-3-4">Доля в квартире</label>
            </div>
            <div class="radio_btn">
              <input id="radio-3-6" @click="resetParamMainBlock(6)" type="radio"
                     v-model="this.filters_main_block[6]" value="true">
              <label for="radio-3-6">Коттедж</label>
            </div>
            <div class="radio_btn">
              <input id="radio-3-7" @click="resetParamMainBlock(7)" type="radio"
                     v-model="this.filters_main_block[7]" value="true">
              <label for="radio-3-7">Таунхаус</label>
            </div>
            <div class="radio_btn">
              <input id="radio-3-5" @click="resetParamMainBlock(5)" type="radio"
                     v-model="this.filters_main_block[5]" value="true">
              <label for="radio-3-5">Дом/Дача</label>
            </div>
            <div class="radio_btn">
              <input id="radio-3-8" @click="resetParamMainBlock(8)" type="radio"
                     v-model="this.filters_main_block[8]" value="true">
              <label for="radio-3-8">Часть дома</label>
            </div>
            <div class="radio_btn">
              <input id="radio-3-9" @click="resetParamMainBlock(9)" type="radio"
                     v-model="this.filters_main_block[9]" value="true">
              <label for="radio-3-9">Земельный участок</label>
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row"
             v-if="filters.sell_type == 3 && filters.type_property == 2">
          <p>Тип объекта:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="radio-comm-3-1" @click="resetParamMainBlock(1)" type="radio"
                     v-model="this.filters_main_block[1]" value="true">
              <label for="radio-comm-3-1">Офис</label>
            </div>
            <div class="radio_btn">
              <input id="radio-comm-3-2" @click="resetParamMainBlock(2)" type="radio"
                     v-model="this.filters_main_block[2]" value="true">
              <label for="radio-comm-3-2">Здание</label>
            </div>
            <div class="radio_btn">
              <input id="radio-comm-3-3" @click="resetParamMainBlock(3)" type="radio"
                     v-model="this.filters_main_block[3]" value="true">
              <label for="radio-comm-3-3">Торговая площадь</label>
            </div>
            <div class="radio_btn">
              <input id="radio-comm-3-4" @click="resetParamMainBlock(4)" type="radio"
                     v-model="this.filters_main_block[4]" value="true">
              <label for="radio-comm-3-4">Помещение свободного назначения</label>
            </div>
            <div class="radio_btn">
              <input id="radio-comm-3-5" @click="resetParamMainBlock(5)" type="radio"
                     v-model="this.filters_main_block[5]" value="true">
              <label for="radio-comm-3-5">Производство</label>
            </div>
            <div class="radio_btn">
              <input id="radio-comm-3-6" @click="resetParamMainBlock(6)" type="radio"
                     v-model="this.filters_main_block[6]" value="true">
              <label for="radio-comm-3-6">Склад</label>
            </div>
            <div class="radio_btn">
              <input id="radio-comm-3-7" @click="resetParamMainBlock(7)" type="radio"
                     v-model="this.filters_main_block[7]" value="true">
              <label for="radio-comm-3-7">Гараж</label>
            </div>
            <div class="radio_btn">
              <input id="radio-comm-3-9" @click="resetParamMainBlock(9)" type="radio"
                     v-model="this.filters_main_block[9]" value="true">
              <label for="radio-comm-3-9">Коммерческая земля</label>
            </div>
            <div class="radio_btn">
              <input id="radio-comm-3-8" @click="resetParamMainBlock(8)" type="radio"
                     v-model="this.filters_main_block[8]" value="true">
              <label for="radio-comm-3-8">Бизнес</label>
            </div>
            <div class="radio_btn">
              <input id="radio-comm-3-11" @click="resetParamMainBlock(11)" type="radio"
                     v-model="this.filters_main_block[11]" value="true">
              <label for="radio-comm-3-11">Апартаменты</label>
            </div>
          </div>
        </div>


        <div class="filters_modal_content_filters_row" v-if="filters.sell_type == 2">
          <p>Срок сдачи:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="time_limit-4" type="radio" v-model="filters.time_limit" value="1">
              <label for="time_limit-4">Посуточно</label>
            </div>
            <div class="radio_btn">
              <input id="time_limit-5" type="radio" v-model="filters.time_limit" value="2">
              <label for="time_limit-5">Длительный</label>
            </div>
            <div class="radio_btn">
              <input id="time_limit-6" type="radio" v-model="filters.time_limit" value="0">
              <label for="time_limit-6">Не важно</label>
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row">
          <p>Тип здания:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="radio-3-3-1" type="radio" v-model="filters.sell_selling_type" value="1">
              <label for="radio-3-3-1">Новостройка</label>
            </div>
            <div class="radio_btn">
              <input id="radio-3-3-2" type="radio" v-model="filters.sell_selling_type" value="2">
              <label for="radio-3-3-2">Вторичка</label>
            </div>
            <div class="radio_btn">
              <input id="radio-3-3-33" type="radio" v-model="filters.sell_selling_type" value="0">
              <label for="radio-3-3-33">Не важно</label>
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row">
          <div>
            <p>Город:</p>
          </div>
          <div class="dop_translate">
            <div class="filters_modal_content_filters_row_inputs_elem"
                 @click="this.set_modal_list_city(true); this.set_status_modal_filters_region(true)">
              <input class="dop_translate_disabled" v-model="this.selected_city" type="text"
                     placeholder="Город">
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row" v-if="this.selected_city && this.district_only_city.length">
          <div>
            <p>Район:</p>
          </div>
          <div class="dop_translate">
            <div class="filters_modal_content_filters_row_inputs_elem"
                 @click="this.set_modal_list_district(true); this.set_status_modal_filters_region(true)">
              <input class="dop_translate_disabled"
                     :value="`Выбрано районов: ${this.filter_selected_district.length}`" type="text"
                     placeholder="Район">
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row" v-if="this.selected_city && this.metro_only_city.length">
          <div>
            <p>Метро:</p>
          </div>
          <div class="dop_translate">
            <div class="filters_modal_content_filters_row_inputs_elem"
                 @click="this.set_modal_list_metro(true); this.set_status_modal_filters_region(true)">
              <input class="dop_translate_disabled"
                     :value="`Выбрано метро: ${this.filter_selected_metro.length}`" type="text"
                     placeholder="Метро">
            </div>
          </div>
        </div>


        <div class="filters_modal_content_filters_row">
          <div>
            <p>Цена, USD:</p>
            <p v-if="filters.price_from > filters.price_to && filters.price_to" class="error_input">
              Значение "от" должно
              быть меньше чем "до"</p>
          </div>
          <div class="filters_modal_content_filters_row_inputs">
            <div class="filters_modal_content_filters_row_inputs_elem">
              <input type="number" v-model="filters.price_from" placeholder="от">
            </div>
            <div class="filters_modal_content_filters_row_inputs_elem">
              <input type="number" v-model="filters.price_to" placeholder="до">
            </div>
          </div>
        </div>

        <!--<div class="filters_modal_content_filters_row">
          <p>Проверенный продавец:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="radio-4" type="radio" v-model="filters.true_seller" value="1">
              <label for="radio-4">Да</label>
            </div>
            <div class="radio_btn">
              <input id="radio-5" type="radio" v-model="filters.true_seller" value="2">
              <label for="radio-5">Нет</label>
            </div>
            <div class="radio_btn">
              <input id="radio-6" type="radio" v-model="filters.true_seller" value="0">
              <label for="radio-6">Не важно</label>
            </div>
          </div>
        </div>-->

        <div class="filters_modal_content_filters_row">
          <div>
            <p>Год постройки/сдачи:</p>
            <p v-if="filters.build_year_from > filters.build_year_to && filters.build_year_to"
               class="error_input">
              Значение "от" должно быть меньше чем "до"</p>
          </div>
          <div class="filters_modal_content_filters_row_inputs">
            <div class="filters_modal_content_filters_row_inputs_elem">
              <input type="number" v-model="filters.build_year_from" placeholder="от">
            </div>
            <div class="filters_modal_content_filters_row_inputs_elem">
              <input type="number" v-model="filters.build_year_to" placeholder="до">
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row">
          <div>
            <p>Количество комнат:</p>
            <p v-if="filters.rooms_from > filters.rooms_to && filters.rooms_to" class="error_input">
              Значение
              "от" должно
              быть меньше чем "до"</p>
          </div>
          <!--          <div class="filters_modal_content_filters_row_inputs_elem">-->
          <!--            <input type="number" @keydown="checkKeyDown($event)" v-model="filters.rooms_from"-->
          <!--                   :maxlength="3" placeholder="">-->
          <!--          </div>-->
          <div class="radio_btn">
            <input id="rooms-1" type="radio" @click="resetParamRooms(1)" v-model="this.filter_count_rooms[1]" value="true">
            <label for="rooms-1">1</label>
          </div>
          <div class="radio_btn">
            <input id="rooms-2" type="radio" @click="resetParamRooms(2)" v-model="this.filter_count_rooms[2]" value="true">
            <label for="rooms-2">2</label>
          </div>
          <div class="radio_btn">
            <input id="rooms-3" type="radio" @click="resetParamRooms(3)" v-model="this.filter_count_rooms[3]" value="true">
            <label for="rooms-3">3</label>
          </div>
          <div class="radio_btn">
            <input id="rooms-4" type="radio" @click="resetParamRooms(4)" v-model="this.filter_count_rooms[4]" value="true">
            <label for="rooms-4">4</label>
          </div>
          <div class="radio_btn">
            <input id="rooms-5" type="radio" @click="resetParamRooms(5)" v-model="this.filter_count_rooms[5]" value="true">
            <label for="rooms-5">5</label>
          </div>
          <div class="radio_btn">
            <input id="rooms-6" type="radio" @click="resetParamRooms(6)" v-model="this.filter_count_rooms[6]" value="true">
            <label for="rooms-6">6+</label>
          </div>
          <div class="radio_btn">
            <input id="rooms-7" type="radio" @click="resetParamRooms(7)" v-model="this.filter_count_rooms[7]" value="true">
            <label for="rooms-7">Студия</label>
          </div>
          <div class="radio_btn">
            <input id="rooms-8" type="radio" @click="resetParamRooms(8)" v-model="this.filter_count_rooms[8]" value="true">
            <label for="rooms-8">Свободная планировка</label>
          </div>
          <!--          <div class="radio_btn">-->
          <!--            <input id="rooms-7" type="radio" v-model="params.studiya" value="1">-->
          <!--            <label for="rooms-7">Студия</label>-->
          <!--          </div>-->
        </div>

        <!--        <div class="filters_modal_content_filters_row">-->
        <!--          <p>Рейтинг дома не ниже:</p>-->
        <!--          <div class="filters_modal_content_filters_row_btns rating">-->

        <!--            <div class="radio_btn">-->
        <!--              <input id="rating-1" type="radio" v-model="filters.rating_from" value="1">-->
        <!--              <label for="rating-1">A+</label>-->
        <!--            </div>-->
        <!--            <div class="radio_btn">-->
        <!--              <input id="rating-2" type="radio" v-model="filters.rating_from" value="2">-->
        <!--              <label for="rating-2">A</label>-->
        <!--            </div>-->
        <!--            <div class="radio_btn">-->
        <!--              <input id="rating-3" type="radio" v-model="filters.rating_from" value="3">-->
        <!--              <label for="rating-3">A-</label>-->
        <!--            </div>-->
        <!--            <div class="radio_btn">-->
        <!--              <input id="rating-4" type="radio" v-model="filters.rating_from" value="4">-->
        <!--              <label for="rating-4">B+-</label>-->
        <!--            </div>-->
        <!--            <div class="radio_btn">-->
        <!--              <input id="rating-5" type="radio" v-model="filters.rating_from" value="5">-->
        <!--              <label for="rating-5">B-</label>-->
        <!--            </div>-->
        <!--            <div class="radio_btn">-->
        <!--              <input id="rating-6" type="radio" v-model="filters.rating_from" value="6">-->
        <!--              <label for="rating-6">B-</label>-->
        <!--            </div>-->
        <!--            <div class="radio_btn">-->
        <!--              <input id="rating-7" type="radio" v-model="filters.rating_from" value="7">-->
        <!--              <label for="rating-7">C+</label>-->
        <!--            </div>-->
        <!--            <div class="radio_btn">-->
        <!--              <input id="rating-8" type="radio" v-model="filters.rating_from" value="8">-->
        <!--              <label for="rating-8">C-</label>-->
        <!--            </div>-->
        <!--            <div class="radio_btn">-->
        <!--              <input id="rating-9" type="radio" v-model="filters.rating_from" value="9">-->
        <!--              <label for="rating-9">C-</label>-->
        <!--            </div>-->


        <!--            <div class="radio_btn">-->
        <!--              <input id="rating-10" type="radio" v-model="filters.rating_from" value="10">-->
        <!--              <label for="rating-10">D+</label>-->
        <!--            </div>-->
        <!--            <div class="radio_btn">-->
        <!--              <input id="rating-11" type="radio" v-model="filters.rating_from" value="11">-->
        <!--              <label for="rating-11">D-</label>-->
        <!--            </div>-->
        <!--            <div class="radio_btn">-->
        <!--              <input id="rating-12" type="radio" v-model="filters.rating_from" value="12">-->
        <!--              <label for="rating-12">D-</label>-->
        <!--            </div>-->

        <!--            <div class="radio_btn">-->
        <!--              <input id="rating-13" type="radio" v-model="filters.rating_from" value="13">-->
        <!--              <label for="rating-13">E+</label>-->
        <!--            </div>-->
        <!--            <div class="radio_btn">-->
        <!--              <input id="rating-14" type="radio" v-model="filters.rating_from" value="14">-->
        <!--              <label for="rating-14">E-</label>-->
        <!--            </div>-->
        <!--            <div class="radio_btn">-->
        <!--              <input id="rating-15" type="radio" v-model="filters.rating_from" value="15">-->
        <!--              <label for="rating-15">E-</label>-->
        <!--            </div>-->

        <!--            <div class="radio_btn">-->
        <!--              <input id="rating-16" type="radio" v-model="filters.rating_from" value="16">-->
        <!--              <label for="rating-16">FFF</label>-->
        <!--            </div>-->
        <!--            <div class="radio_btn">-->
        <!--              <input id="rating-17" type="radio" v-model="filters.rating_from" value="0">-->
        <!--              <label for="rating-17">Любой</label>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--<div class="filters_modal_content_filters_row">
          <p>Объект проверен:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="checked-1" type="radio" v-model="filters.checked" value="1">
              <label for="checked-1">Да</label>
            </div>
            <div class="radio_btn">
              <input id="checked-2" type="radio" v-model="filters.checked" value="2">
              <label for="checked-2">Нет</label>
            </div>
            <div class="radio_btn">
              <input id="checked-3" type="radio" v-model="filters.checked" value="0">
              <label for="checked-3">Не важно</label>
            </div>
          </div>
        </div>-->

        <div class="filters_modal_content_filters_row">
          <p>Тип сделки:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="owner-1" type="radio" v-model="filters.from_owner" value="1">
              <label for="owner-1">От собственника</label>
            </div>
            <div class="radio_btn">
              <input id="owner-2" type="radio" v-model="filters.from_owner" value="0">
              <label for="owner-2">Не важно</label>
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row">
          <p>До метро:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="metro-1" type="radio" v-model="filters.metro" value="1">
              <label for="metro-1">Пешком</label>
            </div>
            <div class="radio_btn">
              <input id="metro-2" type="radio" v-model="filters.metro" value="2">
              <label for="metro-2">Транспортом</label>
            </div>
            <div class="radio_btn">
              <input id="metro-3" type="radio" v-model="filters.metro" value="0">
              <label for="metro-3">Не важно</label>
            </div>
          </div>
        </div>
        <div class="filters_modal_content_filters_row" v-if="filters.metro != 0">
          <p class="simple_p">Время до метро, не больше, мин:</p>
          <div class="filters_modal_content_filters_row_inputs">
            <div class="filters_modal_content_filters_row_inputs_elem">
              <input type="number" v-model="filters.metro_limit" placeholder="">
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row">
          <div>
            <p>Площадь, м²:</p>
            <p v-if="filters.area.general_from > filters.area.general_to && filters.area.general_to"
               class="error_input">Значение "от" должно быть меньше чем "до"</p>
            <p v-if="filters.area.living_from > filters.area.living_to && filters.area.living_to"
               class="error_input">
              Значение "от" должно быть меньше чем "до"</p>
            <p v-if="filters.area.kitchen_from > filters.area.kitchen_to && filters.area.kitchen_to"
               class="error_input">Значение "от" должно быть меньше чем "до"</p>
          </div>
          <div class="filters_modal_content_filters_row_area">
            <div style="opacity: 0; pointer-events: none"
                 class="filters_modal_content_filters_row_inputs area">
              <p>Для вида</p>
              <div class="filters_modal_content_filters_row_inputs_elem">
                <input type="number" placeholder="от">
              </div>
              <div class="filters_modal_content_filters_row_inputs_elem">
                <input type="number" placeholder="от">
              </div>
            </div>

            <div class="filters_modal_content_filters_row_inputs area">
              <p>Общая</p>
              <div class="filters_modal_content_filters_row_inputs_elem">
                <input type="number" v-model="filters.area.general_from" placeholder="от">
              </div>
              <div class="filters_modal_content_filters_row_inputs_elem">
                <input type="number" v-model="filters.area.general_to" placeholder="до">
              </div>
            </div>

            <div class="filters_modal_content_filters_row_inputs area" v-if="filters.type_property == 1">
              <p>Жилая</p>
              <div class="filters_modal_content_filters_row_inputs_elem">
                <input type="number" v-model="filters.area.living_from" placeholder="от">
              </div>
              <div class="filters_modal_content_filters_row_inputs_elem">
                <input type="number" v-model="filters.area.living_to" placeholder="до">
              </div>
            </div>

            <div class="filters_modal_content_filters_row_inputs area" v-if="filters.type_property == 1">
              <p>Кухня</p>
              <div class="filters_modal_content_filters_row_inputs_elem">
                <input type="number" v-model="filters.area.kitchen_from" placeholder="от">
              </div>
              <div class="filters_modal_content_filters_row_inputs_elem">
                <input type="number" v-model="filters.area.kitchen_to" placeholder="до">
              </div>
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row">
          <p>Планировка:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="planning-1" @click="changeBoolParam('planning', 1)" type="radio"
                     v-model="filters.planning[1]" value="true">
              <label for="planning-1">Изолированная</label>
            </div>
            <div class="radio_btn">
              <input id="planning-2" @click="changeBoolParam('planning', 2)" type="radio"
                     v-model="filters.planning[2]" value="true">
              <label for="planning-2">Смежная</label>
            </div>
            <div class="radio_btn">
              <input id="planning-3" @click="changeBoolParam('planning', 3)" type="radio"
                     v-model="filters.planning[3]" value="true">
              <label for="planning-3">Свободная</label>
            </div>
            <!--            <div class="radio_btn">-->
            <!--              <input id="planning-4" type="radio" v-model="filters.has_planning" value="1">-->
            <!--              <label for="planning-4" @click.prevent="resetValue('has_planning', 1)">- Схема-->
            <!--                планировки</label>-->
            <!--            </div>-->
          </div>
        </div>


        <div class="filters_modal_content_filters_row">
          <p>Высота потолков:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="height_from-1" type="radio" v-model="filters.height_from" value="25">
              <label for="height_from-1">От 2,5 м</label>
            </div>
            <div class="radio_btn">
              <input id="height_from-2" type="radio" v-model="filters.height_from" value="27">
              <label for="height_from-2">От 2,7 м</label>
            </div>
            <div class="radio_btn">
              <input id="height_from-3" type="radio" v-model="filters.height_from" value="30">
              <label for="height_from-3">От 3 м</label>
            </div>
            <div class="radio_btn">
              <input id="height_from-4" type="radio" v-model="filters.height_from" value="35">
              <label for="height_from-4">От 3,5 м</label>
            </div>
            <div class="radio_btn">
              <input id="height_from-5" type="radio" v-model="filters.height_from" value="40">
              <label for="height_from-5">От 4 м</label>
            </div>
            <div class="radio_btn">
              <input id="planning-6" type="radio" v-model="filters.height_from" value="0">
              <label for="planning-6">Не важно</label>
            </div>
          </div>
        </div>


        <div class="filters_modal_content_filters_row">
          <div>
            <p>Количество стояков:</p>
            <p v-if="filters.risers_from > filters.risers_to && filters.risers_to" class="error_input">
              Значение "от"
              должно
              быть меньше чем "до"</p>
          </div>
          <div class="filters_modal_content_filters_row_inputs">
            <div class="filters_modal_content_filters_row_inputs_elem">
              <input type="number" v-model="filters.risers_from" placeholder="от">
            </div>
            <div class="filters_modal_content_filters_row_inputs_elem">
              <input type="number" v-model="filters.risers_to" placeholder="до">
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row">
          <div>
            <p>Количество санузлов:</p>
            <p v-if="filters.bathrooms_from > filters.bathrooms_to && filters.bathrooms_to"
               class="error_input">Значение
              "от" должно
              быть меньше чем "до"</p>
          </div>
          <div class="filters_modal_content_filters_row_inputs">
            <div class="filters_modal_content_filters_row_inputs_elem">
              <input type="number" v-model="filters.bathrooms_from" placeholder="от">
            </div>
            <div class="filters_modal_content_filters_row_inputs_elem">
              <input type="number" v-model="filters.bathrooms_to" placeholder="до">
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row" v-for="(elem, index) in countBathrooms">
          <div>
            <p class="simple_p">Санузел {{ index + 1 }}</p>
          </div>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input :id="`countBathrooms-first-${index + 1}`" type="radio"
                     v-model="filters.bathrooms_types[index]"
                     value="1">
              <label :for="`countBathrooms-first-${index + 1}`">Раздельный</label>
            </div>
            <div class="radio_btn">
              <input :id="`countBathrooms-second-${index + 1}`" type="radio"
                     v-model="filters.bathrooms_types[index]"
                     value="2">
              <label :for="`countBathrooms-second-${index + 1}`">Совмещённый</label>
            </div>
            <div class="radio_btn">
              <input :id="`countBathrooms-third-${index + 1}`" type="radio"
                     v-model="filters.bathrooms_types[index]"
                     checked value="0">
              <label :for="`countBathrooms-third-${index + 1}`">Не важно</label>
            </div>
          </div>
        </div>

        <!--<div class="filters_modal_content_filters_row">
          <p>Балкон/лоджия:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="balcony-1" type="radio" v-model="filters.balcony" value="1">
              <label for="balcony-1">Балкон</label>
            </div>
            <div class="radio_btn">
              <input id="balcony-2" type="radio" v-model="filters.balcony" value="2">
              <label for="balcony-2">Лоджия</label>
            </div>
            <div class="radio_btn">
              <input id="balcony-3" type="radio" v-model="filters.balcony" value="0">
              <label for="balcony-3">Не важно</label>
            </div>
          </div>
        </div>-->

        <!--<div class="filters_modal_content_filters_row">
          <p>Кухонная плита:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="oven_type-1" type="radio" v-model="filters.oven_type" value="1">
              <label for="oven_type-1">Газовая</label>
            </div>
            <div class="radio_btn">
              <input id="oven_type-2" type="radio" v-model="filters.oven_type" value="2">
              <label for="oven_type-2">Электрическая</label>
            </div>
            <div class="radio_btn">
              <input id="oven_type-3" type="radio" v-model="filters.oven_type" value="0">
              <label for="oven_type-3">Не важно</label>
            </div>
          </div>
        </div>-->

        <div class="filters_modal_content_filters_row">
          <p>Ремонт:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="renovation-1" type="radio" v-model="filters.renovation" value="1">
              <label for="renovation-1">Косметический</label>
            </div>
            <div class="radio_btn">
              <input id="renovation-2" type="radio" v-model="filters.renovation" value="2">
              <label for="renovation-2">Евроремонт</label>
            </div>

            <div class="radio_btn">
              <input id="renovation-3" type="radio" v-model="filters.renovation" value="3">
              <label for="renovation-3">Дизайнерский</label>
            </div>
            <div class="radio_btn">
              <input id="renovation-4" type="radio" v-model="filters.renovation" value="4">
              <label for="renovation-4">Без ремонта</label>
            </div>

            <div class="radio_btn">
              <input id="renovation-5" type="radio" v-model="filters.renovation" value="0">
              <label for="renovation-5">Не важно</label>
            </div>
          </div>
        </div>

        <!--<div class="filters_modal_content_filters_row">
          <p>Кондиционер:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="air_condition-1" type="radio" v-model="filters.air_condition" value="1">
              <label for="air_condition-1">Есть</label>
            </div>
            <div class="radio_btn">
              <input id="air_condition-2" type="radio" v-model="filters.air_condition" value="2">
              <label for="air_condition-2">Нет</label>
            </div>
            <div class="radio_btn">
              <input id="air_condition-3" type="radio" v-model="filters.air_condition" value="0">
              <label for="air_condition-3">Не важно</label>
            </div>
          </div>
        </div>-->

        <div class="filters_modal_content_filters_row">
          <div>
            <p>Этаж:</p>
            <p v-if="filters.floor_from > filters.floor_to && filters.floor_to" class="error_input">
              Значение "от" должно
              быть меньше чем "до"</p>
          </div>
          <div>
            <div class="filters_modal_content_filters_row_inputs">
              <div class="filters_modal_content_filters_row_inputs_elem">
                <input type="number" v-model="filters.floor_from" placeholder="от">
              </div>
              <div class="filters_modal_content_filters_row_inputs_elem">
                <input type="number" v-model="filters.floor_to" placeholder="до">
              </div>
            </div>

            <div class="filters_modal_content_filters_row_btns">
              <div class="radio_btn">
                <input id="floor_only-1" type="radio" v-model="filters.floor_only.not_first"
                       value="not_first">
                <label for="floor_only-1"
                       @click.prevent="resetValue('floor_only', 'not_first', 'not_first')">- Не
                  первый</label>
              </div>
              <div class="radio_btn">
                <input id="floor_only-2" type="radio" v-model="filters.floor_only.not_last"
                       value="not_last">
                <label for="floor_only-2"
                       @click.prevent="resetValue('floor_only', 'not_last', 'not_last')">- Не
                  последний</label>
              </div>
              <div class="radio_btn">
                <input id="floor_only-3" type="radio" v-model="filters.floor_only.last" value="last">
                <label for="floor_only-3" @click.prevent="resetValue('floor_only', 'last', 'last')">-
                  Только
                  последний</label>
              </div>
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row">
          <div>
            <p>Этажей в доме:</p>
            <p v-if="filters.floors_from > filters.floors_to && filters.floors_to" class="error_input">
              Значение "от"
              должно
              быть меньше чем "до"</p>
          </div>
          <div>

            <div class="filters_modal_content_filters_row_inputs">
              <div class="filters_modal_content_filters_row_inputs_elem">
                <input type="number" v-model="filters.floors_from" placeholder="от">
              </div>
              <div class="filters_modal_content_filters_row_inputs_elem">
                <input type="number" v-model="filters.floors_to" placeholder="до">
              </div>
            </div>

            <div class="filters_modal_content_filters_row_btns">
              <div class="radio_btn">
                <input id="floors-1" type="radio" v-model="filters.penthouse" value="1">
                <label for="floors-1">Пентхаус</label>
              </div>
              <div class="radio_btn">
                <input id="floors-2" type="radio"
                       :checked="filters.penthouse === 0 && !filters.floors_to && !filters.floors_from">
                <label for="floors-2" @click.prevent="resetFloors()">Не важно</label>
              </div>
            </div>
          </div>
        </div>


        <div class="filters_modal_content_filters_row">
          <p>Вид из окна:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="views-1" type="radio" v-model="filters.views" value="1">
              <label for="views-1">Во двор</label>
            </div>
            <div class="radio_btn">
              <input id="views-2" type="radio" v-model="filters.views" value="2">
              <label for="views-2">На улицу</label>
            </div>
            <div class="radio_btn">
              <input id="views-3" type="radio" v-model="filters.views" value="0">
              <label for="views-3">Не важно</label>
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row">
          <p>Тип дома:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="home_type-1" type="radio" v-model="filters.home_type" value="1">
              <label for="home_type-1">Деревянный</label>
            </div>
            <div class="radio_btn">
              <input id="home_type-2" type="radio" v-model="filters.home_type" value="2">
              <label for="home_type-2">Кирпичный</label>
            </div>
            <div class="radio_btn">
              <input id="home_type-3" type="radio" v-model="filters.home_type" value="3">
              <label for="home_type-3">Блочный</label>
            </div>
            <div class="radio_btn">
              <input id="home_type-4" type="radio" v-model="filters.home_type" value="4">
              <label for="home_type-4">Панельный</label>
            </div>
            <div class="radio_btn">
              <input id="home_type-5" type="radio" v-model="filters.home_type" value="5">
              <label for="home_type-5">Монолитный</label>
            </div>
            <div class="radio_btn">
              <input id="home_type-6" type="radio" v-model="filters.home_type" value="6">
              <label for="home_type-6">Кирпично-монолитный</label>
            </div>
            <div class="radio_btn">
              <input id="home_type-8" type="radio" v-model="filters.home_type" value="0">
              <label for="home_type-8">Не важно</label>
            </div>
          </div>
        </div>
        <div class="filters_modal_content_filters_row">
          <p>Под снос:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="demolition-1" type="radio" v-model="filters.demolition" value="1">
              <label for="demolition-1">Не показывать</label>
            </div>
            <div class="radio_btn">
              <input id="demolition-2" type="radio" v-model="filters.demolition" value="2">
              <label for="demolition-2">Только под снос</label>
            </div>
            <div class="radio_btn">
              <input id="demolition-3" type="radio" v-model="filters.demolition" value="0">
              <label for="demolition-3">Не важно</label>
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row">
          <p>Лифт:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="lift-1" type="radio" v-model="filters.lift" value="1">
              <label for="lift-1">Есть любой</label>
            </div>
            <div class="radio_btn">
              <input id="lift-2" type="radio" v-model="filters.lift" value="2">
              <label for="lift-2">Есть грузовой</label>
            </div>
            <div class="radio_btn">
              <input id="lift-3" type="radio" v-model="filters.lift" value="0">
              <label for="lift-3">Не важно</label>
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row">
          <p>Парковка:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="parking-1" type="radio" v-model="filters.parking" value="1">
              <label for="parking-1">Наземная</label>
            </div>
            <div class="radio_btn">
              <input id="parking-2" type="radio" v-model="filters.parking" value="2">
              <label for="parking-2">Многоуровневая</label>
            </div>
            <div class="radio_btn">
              <input id="parking-3" type="radio" v-model="filters.parking" value="3">
              <label for="parking-3">Подземная</label>
            </div>
            <div class="radio_btn">
              <input id="parking-4" type="radio" v-model="filters.parking" value="4">
              <label for="parking-4">На крыше</label>
            </div>

            <div class="radio_btn">
              <input id="parking-5" type="radio" v-model="filters.parking" value="0">
              <label for="parking-5">Не важно</label>
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row" v-if="filters.sell_type == 3">
          <p>Тип продажи:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <input id="selling_type-11" type="radio" v-model="filters.selling_type" value="1">
              <label for="selling_type-11">Свободная</label>
            </div>
            <div class="radio_btn">
              <input id="selling_type-22" type="radio" v-model="filters.selling_type" value="2">
              <label for="selling_type-22">Альтернативная</label>
            </div>

            <div class="radio_btn" v-if="filters.type_property == 1">
              <input id="selling_type-33" type="radio" v-model="filters.commercial_use" value="1">
              <label for="selling_type-33" @click.prevent="resetValue('commercial_use', 1)">Подходит для
                коммерческого использования</label>
            </div>

            <div class="radio_btn" v-if="filters.type_property == 1">
              <input id="can_credit-1" type="radio" v-model="filters.can_credit" value="1">
              <label for="can_credit-1" @click.prevent="resetValue('can_credit', 1)">Подходит под
                ипотеку</label>
            </div>

            <div class="radio_btn">
              <input id="can_credit-2" type="radio" v-model="filters.can_installment" value="1">
              <label for="can_credit-2" @click.prevent="resetValue('can_installment', 1)">Возможна
                рассрочка</label>
            </div>
          </div>
        </div>

        <!--        <div class="filters_modal_content_filters_row">-->
        <!--          <p>Инфраструктура:</p>-->
        <!--          <div class="filters_modal_content_filters_row_btns">-->
        <!--            <div class="radio_btn">-->
        <!--              <input id="infrastructure-1" type="checkbox" v-model="filters.infrastructure" value="1">-->
        <!--              <label for="infrastructure-1">- Школа</label>-->
        <!--            </div>-->
        <!--            <div class="radio_btn">-->
        <!--              <input id="infrastructure-2" type="checkbox" v-model="filters.infrastructure" value="2">-->
        <!--              <label for="infrastructure-2">- Детский сад</label>-->
        <!--            </div>-->
        <!--            <div class="radio_btn">-->
        <!--              <input id="infrastructure-3" type="checkbox" v-model="filters.infrastructure" value="3">-->
        <!--              <label for="infrastructure-3">- Больница</label>-->
        <!--            </div>-->
        <!--            <div class="radio_btn">-->
        <!--              <input id="infrastructure-4" type="checkbox" v-model="filters.infrastructure" value="4">-->
        <!--              <label for="infrastructure-4">- Магазин</label>-->
        <!--            </div>-->
        <!--            <div class="radio_btn">-->
        <!--              <input id="infrastructure-5" type="checkbox" v-model="filters.infrastructure" value="5">-->
        <!--              <label for="infrastructure-5">- Аптека</label>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="filters_modal_content_filters_row" v-if="conveniences_type">
          <p>Удобства:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn" v-for="(e, index) in conveniences_type">
              <input :id="`conveniences-${index + 1}`" type="checkbox" v-model="filters.conveniences"
                     :value="`${index + 1}`">
              <label :for="`conveniences-${index + 1}`">{{ e }}</label>
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row">
          <p>Окружение:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn" v-for="(e, index) in this.arrayEnvironment">
              <input :id="`environment-${index + 1}`" type="checkbox" v-model="filters.environment"
                     :value="`${index + 1}`">
              <label :for="`environment-${index + 1}`">{{ e }}</label>
            </div>
          </div>
        </div>

        <div class="filters_modal_content_filters_row">
          <p>Дата публикации:</p>
          <div class="filters_modal_content_filters_row_btns">
            <div class="radio_btn">
              <Datepicker v-model="filters.published" locale="ru" :enable-time-picker="false"/>
            </div>
            <div class="radio_btn">
              <input id="photo-1" type="radio" v-model="filters.photo" value="1">
              <label for="photo-1" @click.prevent="resetValue('photo', 1)">- Фото</label>
            </div>
            <div class="radio_btn">
              <input id="video-1" type="radio" v-model="filters.video" value="1">
              <label for="video-1" @click.prevent="resetValue('video', 1)">- Видео</label>
            </div>
          </div>
        </div>

        <div class="filters_modal_footer_btns">
          <p class="text_underline" @click="reset()">Очистить всё</p>
          <button @click="applyFilters()"
                  :class="{disable_btn: (filters.price_from > filters.price_to && filters.price_to) || (filters.build_year_from > filters.build_year_to && filters.build_year_to) || (filters.rooms_from > filters.rooms_to && filters.rooms_to) || (filters.area.general_from > filters.area.general_to && filters.area.general_to) || (filters.area.living_from > filters.area.living_to && filters.area.living_to) || (filters.area.kitchen_from > filters.area.kitchen_to && filters.area.kitchen_to) || (filters.risers_from > filters.risers_to && filters.risers_to) || (filters.bathrooms_from > filters.bathrooms_to && filters.bathrooms_to) || (filters.floor_from > filters.floor_to && filters.floor_to) || (filters.floors_from > filters.floors_to && filters.floors_to)}">
            Показать варианты
          </button>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {mapState, mapActions, mapMutations, mapGetters} from "vuex";

export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      todo: [],

      select_first_filter: false,
      select_type_selling: false,

      filter_type_property_selected: 1,
      filter_type_object_selected: 1,
    }
  },
  mounted() {
  },
  methods: {
    resetParamRooms(type) {
      if (this.filter_count_rooms[type]) {
        this.filter_count_rooms[type] = false;
      }
    },
    resetParamMainBlock(type) {
      if (this.filters_main_block[type]) {
        this.filters_main_block[type] = false;
      }
    },
    changeBoolParam(param, count) {
      if (this.filters[param][count]) {
        this.filters[param][count] = false;
      } else {
        this.filters[param][count] = true;
      }
    },
    addMainBlockFilter(value) {
      this.resetAllDopFilters()
        .then(res => {
          this.filters_main_block[value] = true;
        })
        .then(res => {
          this.$router.push(`/`);
        })
        .then(res => {
          this.$emit('updateCatalog');
        })
    },
    selectSellType(value) {
      if (this.filters.sell_type == value) {
        this.filters.sell_type = 0;
      } else {
        this.filters.sell_type = value;
      }
    },
    activateFilters() {
      this.$router.push(`/`);
      this.$emit('updateCatalog');
      this.statusModalFilters = false;
    },
    changeStatusRoom(rooms) {
      if (this.filter_count_rooms[rooms]) {
        this.unset_filter_count_rooms(rooms);
      } else {
        this.set_filter_count_rooms(rooms);
      }
    },
    setFilterObjectType(type) {
      if (this.filter_type_object_selected != type) {
        this.clear_filters_main_block();
      }

      this.filter_type_object_selected = type;
    },
    setFilterTypeProperty(type, type_property = null) {
      if (this.filter_type_property != type) {
        this.clear_filters_main_block();
      }
      this.set_filter_type_property(type);


      if (type != this.filter_type_property_selected) {
        this.filter_type_property_selected = type;
        this.clear_filters_main_block();
      } else {
        this.filter_type_property_selected = type;
      }

      if (type_property) {
        if (this.filters_main_block[type_property]) {
          this.unset_filters_main_block(type_property);
        } else {
          this.set_filters_main_block(type_property);
        }
      }


    },
    changeBool(param, value = null) {
      if (value === null) {
        if (this[param]) {
          this[param] = false;
        } else {
          if (param === 'modal_settings') {
            this.modal_type_one = null;
            this.active_tab_setting = 'language';
          }
          this[param] = true;
        }
      } else {
        if (this[param] == value) {
          this[param] = false;
        } else {
          this[param] = value;
        }
      }
    },
    ...mapMutations({
      set_modal_list_district: 'set_modal_list_district',
      set_modal_list_metro: 'set_modal_list_metro',
      set_modal_list_city: 'set_modal_list_city',
      set_status_modal_filters_region: 'set_status_modal_filters_region',
      clear_filter_selected_district: 'clear_filter_selected_district',
      clear_filter_selected_metro: 'clear_filter_selected_metro',
      set_filter_type_property: 'set_filter_type_property',
      set_filter_time_limit: 'set_filter_time_limit',
      set_filter_sell_type: 'set_filter_sell_type',
      set_filter_object_type: 'set_filter_object_type',
      set_filter_sell_selling_type: 'set_filter_sell_selling_type',
      set_filters_main_block: 'set_filters_main_block',
      unset_filters_main_block: 'unset_filters_main_block',
      clear_filters_main_block: 'clear_filters_main_block',
      set_filter_count_rooms: 'set_filter_count_rooms',
      unset_filter_count_rooms: 'unset_filter_count_rooms',

    }),
    applyFilters() {
      this.getLoadObjects({filters: this.filters})
        .then(res => {
          this.$emit('activateFilters', true)
        })
        .catch(err => {
          console.log("Ошибка: " + err)
        })
    },
    ...mapActions({
      getLoadObjects: 'getLoadObjects',
    }),
    reset() {
      this.clear_filter_selected_district();
      this.clear_filter_selected_metro();
      this.filters.sell_type = 0;
      this.filters.time_limit = 0;
      this.filters.price_from = '';
      this.filters.price_to = '';
      this.filters.true_seller = 0;
      this.filters.repare_quality = 0;

      this.filters.build_year_from = '';
      this.filters.build_year_to = '';
      this.filters.rooms_from = '';
      this.filters.rooms_to = '';

      this.filters.rating_from = 0;
      this.filters.checked = 0;
      this.filters.from_owner = 0;
      this.filters.metro = 0;
      this.filters.metro_limit = 0;

      this.filters.area.general_from = '';
      this.filters.area.general_to = '';
      this.filters.area.living_from = '';
      this.filters.area.living_to = '';
      this.filters.area.kitchen_from = '';
      this.filters.area.kitchen_to = '';
      this.filters.bathrooms_to = '';
      this.filters.bathrooms_from = '';

      this.filters.planning = 0;
      this.filters.has_planning = 0;
      this.filters.height_from = 0;

      this.filters.risers_from = '';
      this.filters.risers_to = '';

      this.filters.balcony = 0;
      this.filters.oven_type = 0;
      this.filters.renovation = 0;
      this.filters.air_condition = 0;

      this.filters.floor_from = '';
      this.filters.floor_to = '';

      this.filters.floor_only.not_first = 0;
      this.filters.floor_only.not_last = 0;
      this.filters.floor_only.last = 0;

      this.filters.floors_from = '';
      this.filters.floors_to = '';

      this.filters.penthouse = 0;
      this.filters.views = 0;
      this.filters.home_type = 0;
      this.filters.demolition = 0;
      this.filters.lift = 0;
      this.filters.parking = 0;
      this.filters.selling_type = 0;
      this.filters.can_credit = 0;
      this.filters.published = 0;
      this.filters.photo = 0;
      this.filters.video = 0;

      this.filters.infrastructure = [];
      this.filters.environment = [];
      this.filters.bathrooms_types = [];

      this.applyFilters();
    },
    resetFloors() {
      this.filters.floors_from = '';
      this.filters.floors_to = '';
      this.filters.penthouse = 0;
    },
    resetValue(filter, value, deep = false) {
      if (deep) {
        if (this.filters[filter][deep] === value) {
          this.filters[filter][deep] = 0;
        } else {
          this.filters[filter][deep] = value;
        }
      } else {
        if (this.filters[filter] === value) {
          this.filters[filter] = 0;
        } else {
          this.filters[filter] = value;
        }
      }
    },
    resetStars(lvl) {
      if (lvl === this.filters.repare_quality) {
        this.filters.repare_quality = 0;
      } else {
        this.filters.repare_quality = lvl;
      }
    },
  },
  computed: {
    ...mapGetters([
      'metro_only_city',
      'district_only_city',
    ]),
    conveniences_type() {
      if (this.filters.type_property == 1) {
        return this.arrayConveniences;
      } else if (this.filters.type_property == 2 && this.filters.object_type == 1) { // Офис
        return this.arrayConveniencesOffice;
      } else if (this.filters.type_property == 2 && this.filters.sell_type == 2 && this.filters.object_type == 8) { // Коворкинг
        return this.arrayConveniencesKoworking;
      } else if (this.filters.type_property == 2 && this.filters.object_type == 2) { // Здания
        return this.arrayConveniencesBuilding;
      } else if (this.filters.type_property == 2 && this.filters.object_type == 3) { // Торговой площади
        return this.arrayConveniencesMarketPlace;
      } else if (this.filters.type_property == 2 && this.filters.object_type == 4) { // Свободного назначения
        return this.arrayConveniencesFreePlace;
      } else if (this.filters.type_property == 2 && this.filters.object_type == 5) { // Производства
        return this.arrayConveniencesWorking;
      } else if (this.filters.type_property == 2 && this.filters.object_type == 6) { // Склад
        return this.arrayConveniencesStorage;
      } else if (this.filters.type_property == 2 && this.filters.object_type == 7) { // Гараж
        return this.arrayConveniencesGarage;
      } else if (this.filters.type_property == 2 && this.filters.object_type == 8) { // Бизнес
        return this.arrayConveniencesBusiness;
      } else {
        return null;
      }
    },
    ...mapState({
      filters: state => state.filters,
      defaultFilters: state => state.defaultFilters,
      selected_city: state => state.selected_city,
      selected_country: state => state.selected_country,
      selected_district: state => state.selected_district,
      filter_selected_district: state => state.filter_selected_district,
      filter_selected_metro: state => state.filter_selected_metro,
      filter_type_property: state => state.filter_type_property,
      filters_main_block: state => state.filters_main_block,
      filter_count_rooms: state => state.filter_count_rooms,
      arrayConveniencesOffice: state => state.arrayConveniencesOffice,
      arrayConveniencesKoworking: state => state.arrayConveniencesKoworking,
      arrayConveniencesBuilding: state => state.arrayConveniencesBuilding,
      arrayConveniencesMarketPlace: state => state.arrayConveniencesMarketPlace,
      arrayConveniencesFreePlace: state => state.arrayConveniencesFreePlace,
      arrayConveniencesWorking: state => state.arrayConveniencesWorking,
      arrayConveniencesStorage: state => state.arrayConveniencesStorage,
      arrayConveniencesGarage: state => state.arrayConveniencesGarage,
      arrayConveniencesBusiness: state => state.arrayConveniencesBusiness,
      arrayConveniences: state => state.arrayConveniences,
      arrayEnvironment: state => state.arrayEnvironment,

    }),
    countBathrooms() {
      // if (this.filters.bathrooms_to !== this.filters.bathrooms_from) {
      //   if (this.filters.bathrooms_from || this.filters.bathrooms_to) {
      //     if (this.filters.bathrooms_from < this.filters.bathrooms_to) {
      //       return this.filters.bathrooms_from;
      //     }else if(this.filters.bathrooms_from && !this.filters.bathrooms_to){
      //       return this.filters.bathrooms_from;
      //     }
      //   }
      // }
      return this.filters.bathrooms_to;
    },
  },
  watch: {
    'filters.price_to'(newVal, oldVal) {
      if (newVal < 0) {
        this.filters.price_to = oldVal;
      }
    },
    'filters.price_from'(newVal, oldVal) {
      if (newVal < 0) {
        this.filters.price_from = oldVal;
      }
    },
    'filters.build_year_from'(newVal, oldVal) {
      if (newVal < 0) {
        this.filters.build_year_from = oldVal;
      }
    },
    'filters.build_year_to'(newVal, oldVal) {
      if (newVal < 0) {
        this.filters.build_year_to = oldVal;
      }
    },
    'filters.rooms_from'(newVal, oldVal) {
      if (newVal < 0) {
        this.filters.rooms_from = oldVal;
      }
    },
    'filters.rooms_to'(newVal, oldVal) {
      if (newVal < 0) {
        this.filters.rooms_to = oldVal;
      }
    },

    'filters.risers_from'(newVal, oldVal) {
      if (newVal < 0) {
        this.filters.risers_from = oldVal;
      }
    },
    'filters.risers_to'(newVal, oldVal) {
      if (newVal < 0) {
        this.filters.risers_to = oldVal;
      }
    },

    'filters.bathrooms_from'(newVal, oldVal) {
      if (newVal < 0) {
        this.filters.bathrooms_from = oldVal;
      }
    },
    'filters.bathrooms_to'(newVal, oldVal) {
      if (newVal < 0) {
        this.filters.bathrooms_to = oldVal;
      }
    },

    'filters.floor_from'(newVal, oldVal) {
      if (newVal < 0) {
        this.filters.floor_from = oldVal;
      }
    },
    'filters.floor_to'(newVal, oldVal) {
      if (newVal < 0) {
        this.filters.floor_to = oldVal;
      }
    },

    'filters.floors_from'(newVal, oldVal) {
      if (newVal < 0) {
        this.filters.floors_from = oldVal;
      }
    },
    'filters.floors_to'(newVal, oldVal) {
      if (newVal < 0) {
        this.filters.floors_to = oldVal;
      }
    },

    'filters.metro_limit'(newVal, oldVal) {
      if (newVal < 0) {
        this.filters.metro_limit = oldVal;
      }
    },

  },
}
</script>

<style>
.filters_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4A556D;
}

.filters_modal_content {
  z-index: 14;
  background-color: white;
  width: 90%;
  max-width: 800px;
  height: 80%;
  max-height: 800px;
  border-radius: 10px;
  position: relative;
}

.filters_modal_content_header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.filters_modal_content_header svg {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.filters_modal_content_header svg:hover {
  transform: rotate(360deg);
  transition: 0.3s;
}

.filters_modal .filters_modal_content_filters_select {
  display: flex;
  padding: 0 10px 0 20px;
}

.filters_modal .catalog_main-block_filters_second-row_select_wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 1024px) {
  .filters_modal .filters_modal_content_filters_select.catalog_main-block_filters_second-row_select {
    display: none;
  }
}

.radio_btn {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.radio_btn input[type=radio], .radio_btn input[type=checkbox] {
  display: none;
}

.radio_btn label {
  display: inline-block;
  cursor: pointer;
  padding: 0px 15px;
  line-height: 34px;
  border-radius: 6px;
  user-select: none;
  border: 1px solid #e0e0e0;
  transition: all .3s;
}

.radio_btn input[type=radio]:checked + label {
  background: #3B9ED4;
  color: white;
  box-shadow: 0px 4px 15px rgb(59 158 212);
  border-color: transparent;
}

.radio_btn input[type=checkbox]:checked + label {
  background: #3B9ED4;
  color: white;
  box-shadow: 0px 4px 15px rgb(59 158 212);
  border-color: transparent;
}

.radio_btn label:hover {
  color: #666;
}

.radio_btn input[type=radio]:disabled + label {
  background: #efefef;
  color: #666;
}

.filters_modal_content_filters_row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 20px 0 20px;
  margin-bottom: 10px;
}

.filters_modal_content_filters_row p {
  font-weight: bold;
}

.filters_modal_content_filters_row_inputs_elem input {
  color: black;
  font-size: 15px;
  font-weight: bold;
  padding: 10px;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  margin-left: 10px;
  box-shadow: 0px 4px 15px rgb(220 220 220);
  width: 95%;
  min-width: 250px;
}

.filters_modal_content_filters_row_inputs {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
}

@media (min-width: 870px) {
  .filters_modal_content_filters_row_inputs {
    flex-wrap: nowrap;
  }
}

.filters_modal_content_filters_row_star {
  background-image: url("@/assets/img/star.png");
  width: 30px;
  display: block;
  height: 30px;
  background-size: 100%;
  background-repeat: no-repeat;
  transition: all .3s;
}

.filters_modal_content_filters_row_star.fill {
  background-image: url("@/assets/img/star_fill.png");
  transition: all .3s;
}

.filters_modal_content_filters_row_btns_stars input {
  display: none;
}

.filters_modal_content_filters_row_btns.stars {
  display: flex;
}

.filters_modal_content_filters_row_btns.rating {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.filters_modal_content_filters {
  overflow: auto;
  height: calc(100% - 58px);
}

.filters_modal_content_filters_row_inputs.area {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  /* margin-right: 10px; */
}

.filters_modal_content_filters_row_inputs.area .filters_modal_content_filters_row_inputs_elem:nth-of-type(1) input {
  border-radius: 6px;
}

.filters_modal_content_filters_row_inputs.area .filters_modal_content_filters_row_inputs_elem:nth-of-type(2) input {
  border-radius: 0 5px 5px 0;
}

.filters_modal_content_filters_row_inputs.area .filters_modal_content_filters_row_inputs_elem:nth-of-type(1) {
  display: flex;
}

@media (min-width: 870px) {
  .filters_modal_content_filters_row_inputs.area .filters_modal_content_filters_row_inputs_elem:nth-of-type(1) {
    justify-content: flex-end;
  }
}

.filters_modal_content_filters_row_inputs.area .filters_modal_content_filters_row_inputs_elem:nth-of-type(2) {
  display: flex;
  justify-content: flex-start;
}

.filters_modal_content_filters_row_inputs.area input {
  max-width: 70px;
  margin: 0;
}

.filters_modal_content_filters_row_inputs.area p {
  font-weight: initial;
  margin-right: 10px;
}

.filters_modal_content_filters_row_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 550px;
}

.filters_modal_content_filters_row_btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 5px;
}

.filters_modal_footer_btns {
  position: sticky;
  bottom: 0;
  height: 40px;
  background-color: white;
  border-top: 1px solid #DDDDDD;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.filters_modal_footer_btns button {
  background: #3B9ED4;
  color: white;
  border-color: transparent;
  display: inline-block;
  cursor: pointer;
  padding: 0.5em;
  border-radius: 10px;
  user-select: none;
  transition: all .3s;
  width: 100%;
  max-width: 300px;
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;
}

@media (max-width: 870px) {
  .filters_modal_content_filters_row_area div:nth-of-type(1) {
    display: none;
  }

  .filters_modal_content_filters_row {
    align-items: stretch;
    flex-direction: column;
  }

  .filters_modal_content_filters_row p {
    margin-bottom: 10px;
  }

  .filters_modal_content_filters_row_btns.stars {
    justify-content: space-between;
  }

  .radio_btn {
    margin-right: 5px;
  }
}


@media (max-width: 750px) {
  .filters_modal {
    align-items: flex-end;
  }

  .filters_modal_content {
    width: 100%;
    height: 100%;
    max-height: none;
  }

  .filters_modal_content_filters_row_inputs.area p {
    margin-bottom: 0;
  }

  .filters_modal_content_filters_row_inputs.area input {
    max-width: none;
  }

  .filters_modal_content_filters_row_area, .filters_modal_content_filters_row_inputs.area {
    width: 100%;
  }

  .filters_modal_content_filters_row_inputs.area .filters_modal_content_filters_row_inputs_elem {
    width: 100%;
  }

}


@media (max-width: 500px) {
  .filters_modal_content_filters_row_star {
    width: 20px;
    height: 20px;
  }

  .filters_modal_content_filters_row_btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 5px;
  }

  .filters_modal_content_filters_row_btns.rating {
    justify-content: flex-start;
  }

  .filters_modal_footer_btns {
    flex-direction: column;
    height: 100px;
    padding: 0;
    align-items: center;
    justify-content: space-evenly;
  }
}


@media (max-width: 400px) {
  .filters_modal_content_filters_row_star {
    width: 20px;
    height: 20px;
  }
}

.error_input {
  color: red;
  font-size: 13px;
}


.filters_modal_footer_btns button.disable_btn {
  color: black;
  pointer-events: none;
  background-color: #DDDDDD;
  box-shadow: 0px 4px 15px rgb(194 194 194);
}

.filters_modal_content_filters_row .simple_p {
  font-weight: unset;
}

.filters_modal_footer_btns p {
  cursor: pointer;
}

.dop_translate_disabled {
  pointer-events: none;
  border: none !important;
}

.dop_translate_disabled::placeholder {

}

</style>
