<template>
    <input :type="type" :value="modelValue" :placeholder="placeholder" @input="updateInput">
</template>

<script>
export default {
  name: 'MainInputText',
  props: {
    modelValue: [Number, String],
    placeholder: String,
    type: {
      type: String,
      default: 'text',
    },
    only_string: {
      type: Boolean,
      default: false,
    },
    max_length: {
      default: 10,
      type: Number
    },
  },
  methods: {
    updateInput(event){
      this.$emit('update:modelValue', event.target.value);
    },
  }
}
</script>

<style scoped>
  input {
    color: black;
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    margin-left: 10px;
    box-shadow: 0px 4px 15px rgb(220 220 220);
    width: 95%;
  }
</style>
