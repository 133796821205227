<template>
  <div @click="this.$emit('changeStatusModalEntry', false)" class="modal_entry">

    <div @click.stop="" class="modal_entry_content">
      <div v-if="!this.authorization">
        <div class="modal_entry_content_header">
          <svg class="modal_entry_close_btn" @click="this.$emit('changeStatusModalEntry', false)" viewBox="0 0 32 32"
               xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
               focusable="false"
               style="display: block; fill: none; height: 24px; width: 24px; stroke: currentcolor; stroke-width: 3; overflow: visible;">
            <path d="m6 6 20 20"></path>
            <path d="m26 6-20 20"></path>
          </svg>
          <h4>Вход</h4>
        </div>
        <div class="modal_entry_content_body">
          <label>
            Логин или email
            <input type="text" v-model="login" @keydown.enter="entry()">
          </label>
          <label>
            Пароль
            <input type="password" v-model="password" @keydown.enter="entry()">
          </label>
          <button @click="entry()" @keydown.enter="entry()">Вход</button>
          <a class="modal_entry_content_body_link" href="/site/forgotpassword/">Забыли пароль?</a>
          <a class="modal_entry_content_body_link" href="/site/signup/">Регистрация</a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {mapState, mapActions, mapMutations} from "vuex";
import axios from 'axios'

export default {
  components: {},
  data() {
    return {
      defaultValues: {},

      login: '',
      password: '',

    }
  },
  mounted() {

  },
  methods: {
    ...mapMutations({
      setUserInfo: 'setUserInfo',
      setAuthorization: 'setAuthorization',
      setUserFavorites: 'setUserFavorites',
      setUserComparison: 'setUserComparison',
    }),
    exit() {
      this.setAuthorization(false);
      this.setUserInfo('');
      this.setUserFavorites('');
      this.setUserComparison('');
      localStorage.removeItem('entry_key');
    },
    async entry(entry_key = null) {
      let favorite = JSON.parse(localStorage.getItem("favorites"));
      let comparison = JSON.parse(localStorage.getItem("comparison"));
      try {
        const response = await axios.post(
            this.url_backend + '?checkEntry',
            {
              params: {
                login: this.login,
                password: this.password,
                entry_key: entry_key,
                favorites: favorite,
                comparison: comparison,
              },
              headers: {
                'Content-Type': 'application/json',
              },
            },
        )
        if (response.data.status === 'success') {
          this.setAuthorization(true);
          this.setUserInfo(response.data.userInfo);

          if(response.data.userInfo.favorites !== '[]'){
            this.setUserFavorites(JSON.parse(response.data.userInfo.favorites));
          }
          if(response.data.userInfo.comparison !== '[]'){
            this.setUserComparison(JSON.parse(response.data.userInfo.comparison));
          }

          localStorage.removeItem('favorites');
          localStorage.removeItem('comparison');
          if(response.data.userInfo.update_key){
            localStorage.setItem("entry_key", response.data.userInfo.entry_key);
          }
          this.$emit('changeStatusModalEntry', false)
        }
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    ...mapActions({
      // getLoadObjects: 'getLoadObjects',
    }),
  },
  computed: {
    ...mapState({
      authorization: state => state.authorization,
      userInfo: state => state.userInfo,
      url_backend: state => state.url_backend,
    }),
  },
  watch: {},
}
</script>

<style>
.modal_entry {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 14;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_entry_content {
  z-index: 14;
  background-color: white;
  width: 100%;
  max-width: 360px;
  height: 100%;
  max-height: 350px;
  border-radius: 10px;
  position: relative;
}

.modal_entry_content_header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.modal_entry_content_header svg {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.modal_entry_content_header svg:hover {
  transform: rotate(360deg);
  transition: 0.3s;
}

.modal_entry_content_body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  padding-top: 0;
}

.modal_entry_content_body input {
  color: black;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  box-shadow: 0px 4px 15px rgb(220 220 220);
  width: 100%;
  padding: 10px;
}

.modal_entry_content_body label {
  width: 100%;
  margin-top: 15px;
}

.modal_entry_content button {
  margin-top: 30px;
  background: #3B9ED4;
  color: white;
  box-shadow: 0px 4px 15px rgb(59 158 212);
  border-color: transparent;
  display: inline-block;
  cursor: pointer;
  padding: 0px 15px;
  line-height: 34px;
  border-radius: 6px;
  user-select: none;
  transition: all .3s;
  width: 100%;
  font-weight: bold;
  margin-bottom: 10px;
}

.modal_entry_content_body_link {
  text-decoration: none;
  color: #3B9ED4;
  margin-bottom: 5px;
}

.modal_entry_content_body_link:hover {
  opacity: 0.7;
}
</style>
