import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store/index.js"

const routes = [
  {
    path: '/',
    name: 'Catalog',
    component: () => import('@/pages/PageCatalog.vue'),
    meta: {
      breadcrumb: [
        {name: 'Каталог', link: '/'},
      ]
    },
  },
  {
    path: '/object/:id',
    name: 'Object',
    component: () => import('@/pages/PageObject.vue'),
    meta: {
      breadcrumb: [
        {name: 'Недвижимость', link: '/'},
      ]
    },
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: () => import('@/pages/PageFavorites.vue'),
    meta: {
      breadcrumb: [
        {name: 'Избранное', link: '/favorites'},
      ]
    },
  },
  {
    path: '/comparison',
    name: 'Comparison',
    component: () => import('@/pages/PageComparison.vue'),
    meta: {
      breadcrumb: [
        {name: 'Сравнение', link: '/comparison'},
      ]
    },
  },
  {
    path: '/create',
    name: 'Create',
    component: () => import('@/pages/PageCreate.vue'),
    meta: {
      breadcrumb: [
        {name: 'Создать', link: '/create'},
      ]
    },
  },
  {
    path: '/zhk',
    name: 'ZHK',
    component: () => import('@/pages/PageZHK.vue'),
    meta: {
      breadcrumb: [
        {name: 'Жилые Комплексы', link: '/zhk'},
      ]
    },
  },
  {
    path: '/tc',
    name: 'TC',
    component: () => import('@/pages/PageTC.vue'),
    meta: {
      breadcrumb: [
        {name: 'Торговые Центры', link: '/tc'},
      ]
    },
  },
  {
    path: '/storage',
    name: 'PageStorage',
    component: () => import('@/pages/PageStorage.vue'),
    meta: {
      breadcrumb: [
        {name: 'Склады', link: '/storage'},
      ]
    },
  },
  {
    path: '/cottages',
    name: 'PageCottages',
    component: () => import('@/pages/PageCottages.vue'),
    meta: {
      breadcrumb: [
        {name: 'Коттеджные поселки', link: '/cottages'},
      ]
    },
  },
  {
    path: '/bc',
    name: 'BC',
    component: () => import('@/pages/PageBC.vue'),
    meta: {
      breadcrumb: [
        {name: 'Бизнес Центры', link: '/bc'},
      ]
    },
  },
  {
    path: '/translators',
    name: 'Translators',
    component: () => import('@/pages/PageTranslators.vue'),
    meta: {
      breadcrumb: [
        {name: 'Переводчики', link: '/translators'},
      ]
    },
  },
  {
    path: '/guides',
    name: 'Guides',
    component: () => import('@/pages/PageGuides.vue'),
    meta: {
      breadcrumb: [
        {name: 'Гиды', link: '/guides'},
      ]
    },
  },
  {
    path: '/moder',
    name: 'Moder',
    component: () => import('@/pages/PageModer.vue'),
    meta: {
      breadcrumb: [
        {name: 'Модерация', link: '/moder'},
      ]
    },
  },
  {
    path: '/developers',
    name: 'Developers',
    component: () => import('@/pages/PageDevelopers.vue'),
    meta: {
      breadcrumb: [
        {name: 'Девелоперы', link: '/developers'},
      ]
    },
  },
  {
    path: '/agents',
    name: 'Agents',
    component: () => import('@/pages/PageAgents.vue'),
    meta: {
      breadcrumb: [
        {name: 'Агенты', link: '/agents'},
      ]
    },
  },
  {
    path: '/agency',
    name: 'Agency',
    component: () => import('@/pages/PageAgency.vue'),
    meta: {
      breadcrumb: [
        {name: 'Агентства', link: '/agency'},
      ]
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/pages/PageAccount.vue'),
    meta: {
      breadcrumb: [
        {name: 'Аккаунт', link: '/account'},
      ]
    },
  },
  {
    path: '/services/mortgage',
    name: 'Mortgage',
    component: () => import('@/pages/Services/PageMortgage.vue'),
    meta: {
      breadcrumb: [
        {name: 'Сервисы', link: '/services'},
        {name: 'Ипотека', link: '/services/mortgage'},
      ]
    },
  },
  {
    path: '/services/estimation',
    name: 'Estimation',
    component: () => import('@/pages/Services/PageEstimation.vue'),
    meta: {
      breadcrumb: [
        {name: 'Сервисы', link: '/services'},
        {name: 'Оценка', link: '/services/estimation'},
      ]
    },
  },
  {
    path: '/services/select-realtor',
    name: 'SelectRealtor',
    component: () => import('@/pages/Services/PageSelectRealtor.vue'),
    meta: {
      breadcrumb: [
        {name: 'Сервисы', link: '/services'},
        {name: 'Подбор Риелтора', link: '/services/select-realtor'},
      ]
    },
  },
  {
    path: '/services/calculator',
    name: 'Calculator',
    component: () => import('@/pages/Services/PageCalculator.vue'),
    meta: {
      breadcrumb: [
        {name: 'Сервисы', link: '/services'},
        {name: 'Калькулятор', link: '/services/calculator'},
      ]
    },
  },
  {
    path: '/services/save-or-buy',
    name: 'SaveOrBuy',
    component: () => import('@/pages/Services/PageSaveOrBuy.vue'),
    meta: {
      breadcrumb: [
        {name: 'Сервисы', link: '/services'},
        {name: 'Копить или Купить', link: '/services/save-or-buy'},
      ]
    },
  },
  {
    path: '/services/stocks',
    name: 'Stocks',
    component: () => import('@/pages/Services/PageStocks.vue'),
    meta: {
      breadcrumb: [
        {name: 'Сервисы', link: '/services'},
        {name: 'Акции', link: '/services/stocks'},
      ]
    },
  },
  {
    path: '/services/exchange',
    name: 'Exchange',
    component: () => import('@/pages/Services/PageExchange.vue'),
    meta: {
      breadcrumb: [
        {name: 'Сервисы', link: '/services'},
        {name: 'Обмен квартиры', link: '/services/exchange'},
      ]
    },
  },
  {
    path: '/services/state-support',
    name: 'StateSupport',
    component: () => import('@/pages/Services/PageStateSupport.vue'),
    meta: {
      breadcrumb: [
        {name: 'Сервисы', link: '/services'},
        {name: 'Господдержка', link: '/services/state-support'},
      ]
    },
  },
  {
    path: '/services/analytics',
    name: 'Analytics',
    component: () => import('@/pages/Services/PageAnalytics.vue'),
    meta: {
      breadcrumb: [
        {name: 'Сервисы', link: '/services'},
        {name: 'Аналитика', link: '/services/analytics'},
      ]
    },
  },
  {
    path: '/services/check',
    name: 'Check',
    component: () => import('@/pages/Services/PageCheck.vue'),
    meta: {
      breadcrumb: [
        {name: 'Сервисы', link: '/services'},
        {name: 'Проверка недвижимости', link: '/services/check'},
      ]
    },
  },
  {
    path: '/services/selection',
    name: 'PageSelection',
    component: () => import('@/pages/Services/PageSelection.vue'),
    meta: {
      breadcrumb: [
        {name: 'Сервисы', link: '/services'},
        {name: 'Подобрать новостройку', link: '/services/selection'},
      ]
    },
  },
  {
    path: '/edit/:id',
    name: 'Edit',
    component: () => import('@/pages/PageEdit.vue'),
    meta: {
      breadcrumb: [
        {name: 'Редактирование', link: '/'},
      ]
    },
  },
  {
    path: '/zhk/:id',
    name: 'ObjectZHK',
    component: () => import('@/pages/Objects/PageObjectZHK.vue'),
    meta: {
      breadcrumb: [
        {name: 'Жилые Комплексы', link: '/zhk'},
      ]
    },
  },
  {
    path: '/tc/:id',
    name: 'ObjectTC',
    component: () => import('@/pages/Objects/PageObjectTC.vue'),
    meta: {
      breadcrumb: [
        {name: 'Торговые Центры', link: '/tc'},
      ]
    },
  },
  {
    path: '/storage/:id',
    name: 'PageObjectStorage',
    component: () => import('@/pages/Objects/PageObjectStorage.vue'),
    meta: {
      breadcrumb: [
        {name: 'Склады', link: '/storage'},
      ]
    },
  },
  {
    path: '/cottages/:id',
    name: 'PageObjectCottages',
    component: () => import('@/pages/Objects/PageObjectCottages.vue'),
    meta: {
      breadcrumb: [
        {name: 'Коттеджные поселки', link: '/cottages'},
      ]
    },
  },
  {
    path: '/bc/:id',
    name: 'ObjectBC',
    component: () => import('@/pages/Objects/PageObjectBC.vue'),
    meta: {
      breadcrumb: [
        {name: 'Бизнес Центры', link: '/bc'},
      ]
    },
  },
  {
    path: '/agency/:id',
    name: 'ObjectAgency',
    component: () => import('@/pages/Objects/PageObjectAgency.vue'),
    meta: {
      breadcrumb: [
        {name: 'Агентства', link: '/agency'},
      ]
    },
  },
  {
    path: '/agents/:id',
    name: 'ObjectAgents',
    component: () => import('@/pages/Objects/PageObjectAgents.vue'),
    meta: {
      breadcrumb: [
        {name: 'Агенты', link: '/agents'},
      ]
    },
  },
  {
    path: '/developers/:id',
    name: 'ObjectDevelopers',
    component: () => import('@/pages/Objects/PageObjectDevelopers.vue'),
    meta: {
      breadcrumb: [
        {name: 'Девелоперы', link: '/developers'},
      ]
    },
  },
  {
    path: '/guides/:id',
    name: 'ObjectGuides',
    component: () => import('@/pages/Objects/PageObjectGuides.vue'),
    meta: {
      breadcrumb: [
        {name: 'Гиды', link: '/guides'},
      ]
    },
  },
  {
    path: '/translators/:id',
    name: 'ObjectTranslators',
    component: () => import('@/pages/Objects/PageObjectTranslators.vue'),
    meta: {
      breadcrumb: [
        {name: 'Переводчики', link: '/translators'},
      ]
    },
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('@/pages/PageServices.vue'),
    meta: {
      breadcrumb: [
        {name: 'Сервисы', link: '/services'},
      ]
    },
  },
  {
    path: '/payment',
    name: 'PagePayment',
    component: () => import('@/pages/PagePayment.vue'),
    meta: {
      breadcrumb: [
        {name: 'Оплата', link: '/payment'},
      ]
    },
  },
  {
    path: '/about-us',
    name: 'PageAboutUs',
    component: () => import('@/pages/PageAboutUs.vue'),
    meta: {
      breadcrumb: [
        {name: 'О нас', link: '/about-us'},
      ]
    },
  },
  {
    path: '/create/zhk',
    name: 'PageCreateZHK',
    component: () => import('@/pages/Create/PageCreateZHK.vue'),
    meta: {
      breadcrumb: [
        {name: 'Создание Жилого Комплекса', link: '/create/zhk'},
      ]
    },
  },
  {
    path: '/view-object/:id',
    name: 'PageModerView',
    component: () => import('@/pages/PageModerView.vue'),
    meta: {
      breadcrumb: [
        {name: 'Просмотр объявления', link: '/view-object/:id'},
      ]
    },
  },
  {
    path: '/view-object/zhk/:id',
    name: 'PageModerViewZHK',
    component: () => import('@/pages/ModerView/PageModerViewZHK.vue'),
    meta: {
      breadcrumb: [
        {name: 'Просмотр объявления', link: '/view-object/zhk/:id'},
      ]
    },
  },
  {
    path: '/tariffs',
    name: 'PageTariffs',
    component: () => import('@/pages/PageTariffs.vue'),
    meta: {
      breadcrumb: [
        {name: 'Тарифы', link: '/tariffs'},
      ]
    },
  },
  {
    path: '/help',
    name: 'PageHelp',
    component: () => import('@/pages/PageHelp.vue'),
    meta: {
      breadcrumb: [
        {name: 'Помощь', link: '/help'},
      ]
    },
  },

  {
    path: '/services/purchase',
    name: 'PagePurchase',
    component: () => import('@/pages/Services/PagePurchase.vue'),
    meta: {
      breadcrumb: [
        {name: 'Сервисы', link: '/services'},
        {name: 'Удалённая покупка', link: '/services/purchase'},
      ]
    },
  },
  {
    path: '/services/select-designer',
    name: 'PageSelectDesigner',
    component: () => import('@/pages/Services/PageSelectDesigner.vue'),
    meta: {
      breadcrumb: [
        {name: 'Сервисы', link: '/services'},
        {name: 'Подбор дизайнера', link: '/services/select-designer'},
      ]
    },
  },
  // {
  //   path: '/registration',
  //   name: 'PageRegistration',
  //   component: () => import('@/pages/PageRegistration.vue'),
  //   meta: {
  //     breadcrumb: [
  //       {name: 'Регистрация', link: '/registration'},
  //     ]
  //   },
  // },
  {
    path: '/login',
    name: 'PageLogin',
    component: () => import('@/pages/PageLogin.vue'),
    meta: {
      breadcrumb: [
        {name: 'Вход', link: '/login'},
      ]
    },
  },
  // {
  //   path: '/forgot-password',
  //   name: 'PageForgotPassword',
  //   component: () => import('@/pages/PageForgotPassword.vue'),
  //   meta: {
  //     breadcrumb: [
  //       {name: 'Восстановление пароля', link: '/forgot-password'},
  //     ]
  //   },
  // },

  {
    path: '/reset-password',
    name: 'PageResetPassword',
    component: () => import('@/pages/PageResetPassword.vue'),
    meta: {
      breadcrumb: [
        {name: 'Сброс пароля', link: '/reset-password'},
      ]
    },
  },

  {
    path: '/accept-email',
    name: 'PageAcceptEmail',
    component: () => import('@/pages/PageAcceptEmail.vue'),
    meta: {
      breadcrumb: [
        {name: 'Подтверждение Почты', link: '/accept-email'},
      ]
    },
  },


  {
    path: '/create/bc',
    name: 'PageCreateBC',
    component: () => import('@/pages/Create/PageCreateBC.vue'),
    meta: {
      breadcrumb: [
        {name: 'Создание Бизнес Центра', link: '/create/bc'},
      ]
    },
  },
  {
    path: '/view-object/bc/:id',
    name: 'PageModerViewBC',
    component: () => import('@/pages/ModerView/PageModerViewBC.vue'),
    meta: {
      breadcrumb: [
        {name: 'Просмотр объявления', link: '/view-object/bc/:id'},
      ]
    },
  },

  {
    path: '/create/tc',
    name: 'PageCreateTC',
    component: () => import('@/pages/Create/PageCreateTC.vue'),
    meta: {
      breadcrumb: [
        {name: 'Создание Торгового центра', link: '/create/tc'},
      ]
    },
  },
  {
    path: '/view-object/tc/:id',
    name: 'PageModerViewTC',
    component: () => import('@/pages/ModerView/PageModerViewTC.vue'),
    meta: {
      breadcrumb: [
        {name: 'Просмотр объявления', link: '/view-object/tc/:id'},
      ]
    },
  },

  {
    path: '/create/storage',
    name: 'PageCreateStorage',
    component: () => import('@/pages/Create/PageCreateStorage.vue'),
    meta: {
      breadcrumb: [
        {name: 'Создание Торгового центра', link: '/create/storage'},
      ]
    },
  },
  {
    path: '/view-object/storage/:id',
    name: 'PageModerViewStorage',
    component: () => import('@/pages/ModerView/PageModerViewStorage.vue'),
    meta: {
      breadcrumb: [
        {name: 'Просмотр объявления', link: '/view-object/storage/:id'},
      ]
    },
  },
  {
    path: '/create/cottages',
    name: 'PageCreateCottages',
    component: () => import('@/pages/Create/PageCreateCottages.vue'),
    meta: {
      breadcrumb: [
        {name: 'Создание Торгового центра', link: '/create/cottages'},
      ]
    },
  },
  {
    path: '/view-object/Cottages/:id',
    name: 'PageModerViewCottages',
    component: () => import('@/pages/ModerView/PageModerViewCottages.vue'),
    meta: {
      breadcrumb: [
        {name: 'Просмотр объявления', link: '/view-object/cottages/:id'},
      ]
    },
  },
  {
    path: '/create/developers',
    name: 'PageCreateDevelopers',
    component: () => import('@/pages/Create/PageCreateDevelopers.vue'),
    meta: {
      breadcrumb: [
        {name: 'Создание Девелопера', link: '/create/developers'},
      ]
    },
  },
  {
    path: '/view-object/developers/:id',
    name: 'PageModerViewDevelopers',
    component: () => import('@/pages/ModerView/PageModerViewDevelopers.vue'),
    meta: {
      breadcrumb: [
        {name: 'Просмотр объявления', link: '/view-object/developers/:id'},
      ]
    },
  },
  {
    path: '/create/agency',
    name: 'PageCreateAgency',
    component: () => import('@/pages/Create/PageCreateAgency.vue'),
    meta: {
      breadcrumb: [
        {name: 'Создание агентства', link: '/create/agency'},
      ]
    },
  },
  {
    path: '/view-object/agency/:id',
    name: 'PageModerViewAgency',
    component: () => import('@/pages/ModerView/PageModerViewAgency.vue'),
    meta: {
      breadcrumb: [
        {name: 'Просмотр объявления', link: '/view-object/agency/:id'},
      ]
    },
  },


  {
    path: '/create/agents',
    name: 'PageCreateAgents',
    component: () => import('@/pages/Create/PageCreateAgents.vue'),
    meta: {
      breadcrumb: [
        {name: 'Создание агента', link: '/create/agents'},
      ]
    },
  },
  {
    path: '/view-object/agents/:id',
    name: 'PageModerViewAgents',
    component: () => import('@/pages/ModerView/PageModerViewAgents.vue'),
    meta: {
      breadcrumb: [
        {name: 'Просмотр объявления', link: '/view-object/agents/:id'},
      ]
    },
  },
  {
    path: '/create/translators',
    name: 'PageCreateTranslators',
    component: () => import('@/pages/Create/PageCreateTranslators.vue'),
    meta: {
      breadcrumb: [
        {name: 'Создание переводчика', link: '/create/translators'},
      ]
    },
  },
  {
    path: '/view-object/translators/:id',
    name: 'PageModerViewTranslators',
    component: () => import('@/pages/ModerView/PageModerViewTranslators.vue'),
    meta: {
      breadcrumb: [
        {name: 'Просмотр объявления', link: '/view-object/translators/:id'},
      ]
    },
  },
  {
    path: '/create/guides',
    name: 'PageCreateGuides',
    component: () => import('@/pages/Create/PageCreateGuides.vue'),
    meta: {
      breadcrumb: [
        {name: 'Создание гида', link: '/create/guides'},
      ]
    },
  },
  {
    path: '/view-object/guides/:id',
    name: 'PageModerViewGuides',
    component: () => import('@/pages/ModerView/PageModerViewGuides.vue'),
    meta: {
      breadcrumb: [
        {name: 'Просмотр объявления', link: '/view-object/guides/:id'},
      ]
    },
  },

  {
    path: '/edit/zhk/:id',
    name: 'PageEditZHK',
    component: () => import('@/pages/Edit/PageEditZHK.vue'),
    meta: {
      breadcrumb: [
        {name: 'Редактирование', link: '/edit/zhk/:id'},
      ]
    },
  },

  {
    path: '/edit/translators/:id',
    name: 'PageEditTranslators',
    component: () => import('@/pages/Edit/PageEditTranslators.vue'),
    meta: {
      breadcrumb: [
        {name: 'Редактирование', link: '/edit/translators/:id'},
      ]
    },
  },

  {
    path: '/edit/tc/:id',
    name: 'PageEditTC',
    component: () => import('@/pages/Edit/PageEditTC.vue'),
    meta: {
      breadcrumb: [
        {name: 'Редактирование', link: '/edit/tc/:id'},
      ]
    },
  },
  {
    path: '/edit/storage/:id',
    name: 'PageEditStorage',
    component: () => import('@/pages/Edit/PageEditStorage.vue'),
    meta: {
      breadcrumb: [
        {name: 'Редактирование', link: '/edit/storage/:id'},
      ]
    },
  },
  {
    path: '/edit/guides/:id',
    name: 'PageEditGuides',
    component: () => import('@/pages/Edit/PageEditGuides.vue'),
    meta: {
      breadcrumb: [
        {name: 'Редактирование', link: '/edit/guides/:id'},
      ]
    },

  },
  {
    path: '/edit/developers/:id',
    name: 'PageEditDevelopers',
    component: () => import('@/pages/Edit/PageEditDevelopers.vue'),
    meta: {
      breadcrumb: [
        {name: 'Редактирование', link: '/edit/developers/:id'},
      ]
    },

  },
  {
    path: '/edit/cottages/:id',
    name: 'PageEditCottages',
    component: () => import('@/pages/Edit/PageEditCottages.vue'),
    meta: {
      breadcrumb: [
        {name: 'Редактирование', link: '/edit/cottages/:id'},
      ]
    },
  },
  {
    path: '/edit/bc/:id',
    name: 'PageEditBC',
    component: () => import('@/pages/Edit/PageEditBC.vue'),
    meta: {
      breadcrumb: [
        {name: 'Редактирование', link: '/edit/bc/:id'},
      ]
    },
  },

  {
    path: '/edit/agents/:id',
    name: 'PageEditAgents',
    component: () => import('@/pages/Edit/PageEditAgents.vue'),
    meta: {
      breadcrumb: [
        {name: 'Редактирование', link: '/edit/agents/:id'},
      ]
    },
  },
  {
    path: '/edit/agency/:id',
    name: 'PageEditAgency',
    component: () => import('@/pages/Edit/PageEditAgency.vue'),
    meta: {
      breadcrumb: [
        {name: 'Редактирование', link: '/edit/agency/:id'},
      ]
    },
  },

  {
    path: '/404',
    name: 'PageError',
    component: () => import('@/pages/PageError.vue'),
  },
  {path: '/:catchAll(.*)', redirect: '/404'},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {left: 0, top: 0}
  }
})

router.beforeEach((to, from, next) => {
  if (from.name == "Account") {
    if (store._state.data.was_changed) {
      store.commit('set_next_redirect', to.path);
      store.commit('set_modal_accept', true);
      next(false);
      return false;
    }
  }

  if (to.name == "PageLogin") {
    if (store._state.data.userInfo) {
      next({
        path: '/'
      })
    } else {
      next();
    }
  }

  if (to.name == "Account" || to.name == "Create" || to.name == "Edit") {
    next();
  } else if (to.name == "Moder" || to.name == 'PageModerView') {
    if (!store._state.data.userInfo) {
      next({
        path: '/'
      })
    } else if (store._state.data.userInfo.admin === 0) {
      next({
        path: '/'
      })
    } else {
      next();
    }
  } else {
    next();
  }


})

export default router
